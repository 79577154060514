import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Reorder } from "framer-motion";

import _ from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";

import TextInput from "../components/inputs/TextInput";

import CardSearch from "../components/featurecards/CardSearch";
import CardImage from "../components/featurecards/CardImage";

import Downloader from "../components/widgets/Downloader";
import Wrapper from "../components/layout/Wrapper";

import FeatureCardsPreview from "./viewers/FeatureCardsPreview";
import FeatureCardsViewer from "./viewers/FeatureCardsViewer";

//HOOKS
import useFeatureCards from "../hooks/useFeatureCards";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function arraymove(arr, fromIndex, toIndex) {
   var element = arr[fromIndex];
   arr.splice(fromIndex, 1);
   arr.splice(toIndex, 0, element);

   return arr;
}

export default function FeatureCards() {
   let navigate = useNavigate();

   const [cardParent, setCardParent] = useState();

   // USE HOOK
   const [
      entries,
      allCards,
      activeData,
      show,
      {
         viewerLoad,
         viewerNextPrev,
         viewerShowHide,
         getEntries,
         addEntry,
         deleteEntry,
         updateEntry,
         addCard,
         deleteCard,
         subscribeViewer,
      },
   ] = useFeatureCards();

   useEffect(() => {
      subscribeViewer();
      getEntries();
   }, []);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   const handleAddCard = (entryId, card) => {
      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      if (entryRow.cards.length < 5) {
         if (entryRow.cards.length == 4) {
            setCardParent();
         }

         let cards = [
            ...entryRow.cards,
            {
               id: card.ID,
               CardName: card.CardName,
               FileName: card.FileName,
               featured: false,
               order: entryRow.cards.length + 1,
            },
         ];

         updateEntry(entryId, { cards: cards });
      } else {
         setCardParent();
      }
   };

   const handleUpdateCard = (entryId, cardId, cards, updates) => {
      // console.log(entryId, cardId);

      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      const updatedCards = cards.map((card) =>
         card.id === cardId ? { ...card, ...updates } : card
      );

      updateEntry(entryId, { cards: updatedCards });
   };

   const handleCardReorder = (entryId, cardId, direction) => {
      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      const cardIndex = _.findIndex(entryRow.cards, function (o) {
         return o.id === cardId;
      });

      let cards = entryRow.cards;

      const newCardOrder = arraymove(
         cards,
         cardIndex,
         direction == "prev"
            ? Math.max(0, cardIndex - 1)
            : Math.min(cards.length, cardIndex + 1)
      );

      console.log(newCardOrder);

      // newCardOrder.map((card, i) => {
      //    console.log(card);
      //    // card.order = i;
      //    return card;
      // });

      console.log(newCardOrder);

      updateEntry(entryId, { cards: cards });
   };

   const handleDeleteCard = (entryId, cardId, index) => {
      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      let cards = entryRow.cards;
      cards.splice(index, 1);

      updateEntry(entryId, { cards: cards });
   };

   const handleLoadSlide = (e) => {
      viewerLoad(e.target.id);
   };

   const handleNextPrev = (e) => {
      viewerNextPrev(e.target.value, activeData ? activeData.id : 0);
   };

   const handleShowHide = (e) => {
      viewerShowHide(e.target.value);
   };

   return (
      <Wrapper>
         <Stack spacing={3} alignItems='flex-start'>
            <Stack
               direction='row'
               alignItems='center'
               justifyContent='space-between'
               sx={{ width: "100%" }}
            >
               <Typography variant='h3' component='div'>
                  Feature Cards
               </Typography>

               <Downloader
                  enabled={activeData ? true : false}
                  downloadName={
                     activeData &&
                     activeData.cards &&
                     activeData.cards.length > 0
                        ? "FeatureCards_" + Date.now()
                        : ""
                  }
               >
                  <FeatureCardsPreview
                     show={show}
                     activeData={activeData}
                     preview={true}
                  />
               </Downloader>
            </Stack>

            <Stack direction='row' spacing={2} sx={{ mb: 2 }}>
               <Button
                  variant='contained'
                  color='warning'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"toggle"}
                  onClick={handleShowHide}
                  size='small'
               >
                  TOGGLE
               </Button>
               <Button
                  variant='contained'
                  color='success'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"show"}
                  onClick={handleShowHide}
                  size='small'
               >
                  SHOW
               </Button>
               <Button
                  variant='contained'
                  color='error'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"hide"}
                  onClick={handleShowHide}
                  size='small'
               >
                  HIDE
               </Button>
               <Button
                  variant='outlined'
                  color='warning'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"prev"}
                  onClick={handleNextPrev}
                  size='small'
               >
                  {"<"} PREV
               </Button>
               <Button
                  variant='outlined'
                  color='warning'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"next"}
                  onClick={handleNextPrev}
                  size='small'
               >
                  NEXT {">"}
               </Button>
            </Stack>

            <Box
               item
               width='50%'
               ref={columnWidth}
               sx={{
                  height: 1080 * iframeScale,
                  position: "relative",
                  mb: 4,
               }}
            >
               <Box
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                  }}
                  className='transparentBG'
               >
                  <FeatureCardsViewer />
               </Box>
            </Box>

            <Button variant='outlined' color='warning' onClick={addEntry}>
               + Add Group
            </Button>

            <Stack sx={{ width: "100%" }}>
               {entries &&
                  entries.map((entry, i) => (
                     <Stack
                        key={"featuredCardGroup-" + entry.id}
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                           p: 2,
                           borderTop: "1px solid #ccc",
                           minHeight: 200,
                           width: "100%",
                           backgroundColor:
                              i % 2
                                 ? "rgba(255,255,255,.2)"
                                 : "rgba(255,255,255,.1)",
                           px: 2,
                        }}
                     >
                        <Typography variant='h4' component='div' sx={{ mx: 4 }}>
                           {entry.id}
                        </Typography>
                        <Stack
                           alignItems='flex-start'
                           sx={{ width: "100%" }}
                           spacing={1}
                        >
                           <TextInput
                              color='rgba(255,255,255,.7)'
                              value={entry.title ? entry.title : ""}
                              callback={(value) =>
                                 updateEntry(entry.id, { title: value })
                              }
                           />

                           <Stack
                              alignItems='center'
                              justifyContent='flex-start'
                              direction='row'
                              sx={{ width: "100%" }}
                              spacing={2}
                           >
                              {entry.cards &&
                                 entry.cards.length > 0 &&
                                 entry.cards.map((card, i) => (
                                    <>
                                       {card && (
                                          <Stack
                                             alignItems='center'
                                             sx={{ position: "relative" }}
                                          >
                                             {card.rotate > 0 && (
                                                <Button
                                                   color='secondary'
                                                   variant='outlined'
                                                   size='small'
                                                   onClick={() =>
                                                      handleUpdateCard(
                                                         entry.id,
                                                         card.id,
                                                         entry.cards,
                                                         { rotate: 0 }
                                                      )
                                                   }
                                                >
                                                   Rotate
                                                </Button>
                                             )}
                                             {(!card.rotate ||
                                                card.rotate == 0) && (
                                                <Button
                                                   color='secondary'
                                                   variant='outlined'
                                                   size='small'
                                                   onClick={() =>
                                                      handleUpdateCard(
                                                         entry.id,
                                                         card.id,
                                                         entry.cards,
                                                         { rotate: 90 }
                                                      )
                                                   }
                                                >
                                                   Rotate
                                                </Button>
                                             )}
                                             <Button
                                                variant='contained'
                                                color='error'
                                                size='small'
                                                sx={{
                                                   position: "absolute",
                                                   zIndex: 100,
                                                   top: "30%",
                                                   transform:
                                                      "translateY(0%) scale(0.8)",
                                                }}
                                                onClick={() =>
                                                   handleDeleteCard(
                                                      entry.id,
                                                      card.id,
                                                      i
                                                   )
                                                }
                                             >
                                                X
                                             </Button>
                                             <CardImage
                                                key={"card" + i + "-" + card.id}
                                                name={card.CardName}
                                                borderRadius={0}
                                                showName={false}
                                                fileName={card.FileName}
                                                width={100}
                                                rotate={card.rotate}
                                             />
                                             <Stack direction='row' spacing={1}>
                                                <Button
                                                   variant='outlined'
                                                   size='small'
                                                   onClick={() =>
                                                      handleCardReorder(
                                                         entry.id,
                                                         card.id,
                                                         "prev"
                                                      )
                                                   }
                                                >
                                                   {"<"}
                                                </Button>
                                                <Button
                                                   variant='outlined'
                                                   size='small'
                                                   onClick={() =>
                                                      handleCardReorder(
                                                         entry.id,
                                                         card.id,
                                                         "next"
                                                      )
                                                   }
                                                >
                                                   {">"}
                                                </Button>
                                             </Stack>
                                          </Stack>
                                       )}
                                    </>
                                 ))}
                              <Stack sx={{ color: "#999", ml: 4 }}>
                                 <Button
                                    variant={
                                       entry.cards.length < 5
                                          ? "contained"
                                          : "disabled"
                                    }
                                    color='success'
                                    onClick={() => setCardParent(entry.id)}
                                    sx={{ width: 200 }}
                                 >
                                    + ADD CARD
                                 </Button>
                                 {entry.cards.length >= 5 && (
                                    <i>Maximum 5 Cards</i>
                                 )}
                              </Stack>
                              {/* </Stack> */}
                           </Stack>
                        </Stack>

                        <Button
                           variant='outlined'
                           color='success'
                           sx={{ ml: 4 }}
                           onClick={() => viewerLoad(entry.id)}
                        >
                           LOAD SLIDE
                        </Button>

                        <Button
                           variant='outlined'
                           color='error'
                           sx={{ ml: 4 }}
                           onClick={() => deleteEntry(entry.id)}
                        >
                           <DeleteIcon />
                        </Button>
                     </Stack>
                  ))}
            </Stack>
         </Stack>

         <CardSearch
            allCards={allCards}
            open={cardParent}
            close={() => setCardParent()}
            callback={handleAddCard}
         />
      </Wrapper>
   );
}
