import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useCardHandler() {
   const socket = useContext(SocketContext);

   const [allCards, setAllCards] = useState();
   const [notification, setNotification] = useState();

   socket.on("message", (message) => {
      setNotification(message);
   });

   const handlers = useMemo(
      () => ({
         getAllCards: (criteria) => {
            socket.emit("getAllCardNames", (response) => {
               setAllCards(response);
            });
         },
      }),
      [socket]
   );

   return [allCards, notification, handlers];
}
