import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useLowerThirds() {
   const socket = useContext(SocketContext);

   const [entries, setEntries] = useState([]);
   const [allCustomNames, setAllCustomNames] = useState(null);
   const [allPlayerNames, setAllPlayerNames] = useState(null);

   const [activeData, setActiveData] = useState();
   const [show, setShow] = useState(true);

   useEffect(() => {
      handlers.getEntries();
      handlers.getAllPlayerNames();
      handlers.getAllCustomNames();
   }, []);

   const handlers = useMemo(
      () => ({
         //CONTROLS
         viewerLoad: (viewerOutput, id) => {
            socket.emit("lowerThirdsViewerLoad", viewerOutput, id);
         },

         //ENTRIES
         getEntries: () => {
            socket.emit("getLowerThirdsEntries", (response) => {
               setEntries(response);
            });
         },
         get: (id) => {
            socket.emit("getLowerThirdsEntry", id, (response) => {
               setActiveData(response);
            });
         },
         addEntry: () => {
            socket.emit("addLowerThirdsEntry", (response) => {
               setEntries(response);
            });
         },
         deleteEntry: (entryId) => {
            socket.emit("deleteLowerThirdsEntry", entryId, (response) => {
               setEntries(response);
            });
         },
         updateEntry: (entryId, update) => {
            socket.emit(
               "updateLowerThirdsEntry",
               entryId,
               update,
               (response) => {
                  setEntries(response);
               }
            );
         },
         //ALL CARDS
         getAllCustomNames: () => {
            socket.emit("getLowerThirdCustomNames", (response) => {
               setAllCustomNames(response);
            });
         },
         getAllPlayerNames: () => {
            socket.emit("getLowerThirdPlayerNames", (response) => {
               setAllPlayerNames(response);
            });
         },

         //CUSTOM NAMES
         addName: () => {
            socket.emit("addLowerThirdsName", (response) => {
               setAllCustomNames(response);
            });
         },
         deleteName: (id) => {
            socket.emit("deleteLowerThirdsName", id, (response) => {
               setAllCustomNames(response);
            });
         },
         updateName: (id, update) => {
            socket.emit("updateLowerThirdsName", id, update, (response) => {
               setAllCustomNames(response);
            });
         },

         //SUBSCRIBE
         subscribeViewer: (viewerOutput) => {
            socket.emit("subscribeLowerThirds", viewerOutput, (response) => {
               console.log(response);
            });

            socket.on("lowerThirdsViewerLoad", (output, id) => {
               if (viewerOutput == output) {
                  handlers.get(id);
               }
            });

            socket.on("lowerThirdsViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setShow(true);
                     break;
                  case "hide":
                     setShow((prevState) => {
                        return false;
                     });
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [entries, allCustomNames, allPlayerNames, activeData, show, handlers];
}
