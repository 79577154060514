import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PlayerBulletPoints({ data }) {
   const theme = useTheme();

   return (
      <>
         {data && (
            <List
               sx={{
                  listStyleType: "disc",
                  fontFamily: "GothamNarrow-Book",
                  fontSize: 36,
                  lineHeight: 1.1,
                  color: "#fff",
                  textAlign: "center",
                  pl: 3,
                  width: "100%",
               }}
            >
               {data.DataFields &&
                  data.DataFields.bulletpoints.map((row, i) => (
                     <>
                        {row.title && data.PlayerData && (
                           <ListItem sx={{ display: "list-item" }}>
                              {data.PlayerData[row.title]}
                           </ListItem>
                        )}
                     </>
                  ))}
            </List>
         )}
      </>
   );
}
