import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import LoaderIcon from "../widgets/LoaderIcon";
import LoadingContentWrapper from "../widgets/LoadingContentWrapper";

import { useTheme } from "@mui/material/styles";

export default function WrapperIpad({ children }) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            backgroundColor: "#111",
            width: "100%",
            height: "100vh",
            overflow: "hidden",
         }}
      >
         {children}
      </Box>
   );
}
