import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import UrlToCopy from "../components/widgets/UrlToCopy";

import Downloader from "../components/widgets/Downloader";

import PlayerSelect from "../components/bracket/PlayerSelect";
import MatchWins from "../components/bracket/MatchWins";

import LEDBracketViewer from "./viewers/LEDBracketViewer";

//HOOKS
import useLEDBracketHandler from "../hooks/useLEDBracketHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Top8({ parent = "" }) {
   let navigate = useNavigate();

   // USE HOOK
   const [
      bracket,
      visible,
      standings,
      runTimestamp,
      { showHide, getTop8, subscribeLEDBracketViewer },
   ] = useLEDBracketHandler();

   useEffect(() => {
      subscribeLEDBracketViewer();
   }, []);

   useEffect(() => {
      // console.log(bracket);
   }, [bracket]);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // HIDE SHOW
   const handleShowHide = (e) => {
      showHide(e.target.value);
   };

   return (
      <>
         <Grid container spacing={3}>
            <Grid
               item
               xs={parent != "producer" ? 8 : 12}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale + 100, position: "relative" }}
            >
               <Button
                  variant='contained'
                  color='warning'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"toggle"}
                  onClick={handleShowHide}
                  size='small'
               >
                  TOGGLE
               </Button>
               <Button
                  variant='contained'
                  color='success'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"show"}
                  onClick={handleShowHide}
                  size='small'
               >
                  SHOW
               </Button>
               <Button
                  variant='contained'
                  color='error'
                  sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                  value={"hide"}
                  onClick={handleShowHide}
                  size='small'
               >
                  HIDE
               </Button>

               <Box
                  sx={{
                     position: "relative",
                     width: 2160,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                     mt: 2,
                  }}
                  className='transparentBG'
               >
                  <LEDBracketViewer />
               </Box>
            </Grid>
         </Grid>

         <UrlToCopy title='Viewer URL' path='/viewer/ledbracket' />
      </>
   );
}
