import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useStandingsHandler() {
   const socket = useContext(SocketContext);

   const [standings, setStandings] = useState([]);
   const [cachedStandings, setCachedStandings] = useState([]);
   const [notification, setNotification] = useState();
   const [tournamentRounds, setTournamentRounds] = useState([]);

   //    socket.on("message", (message) => {
   //       setNotification(message);
   //    });

   const handlers = useMemo(
      () => ({
         getCachedStandings: () => {
            socket.emit("getCachedStandings", (response) => {
               setCachedStandings(response);
            });
         },
         getTournamentRounds: () => {
            socket.emit("getTournamentRounds", (response) => {
               setTournamentRounds(response);
            });
         },
         saveStandings: (RoundId) => {
            if (RoundId) {
               axios
                  .get(
                     SERVER_URL +
                        "/melee/api/insert/standing/list/round/" +
                        RoundId
                  )
                  .then((response) => {
                     alert(response.data);
                     handlers.getCachedStandings();
                  });
            }
         },
         getStandings: (standingsId) => {
            if (standingsId) {
               socket.emit("getStandings", standingsId, (response) => {
                  setStandings(response);
               });
            }
         },
         deleteStandings: (RoundId) => {
            socket.emit("deleteStandings", RoundId, (response) => {
               setCachedStandings(response);
            });
         },
         standingsViewerLoad: (standingsId, start, numRows) => {
            // console.log(standingsId, start, numRows);
            if (standingsId) {
               socket.emit("standingsViewerLoad", standingsId, start, numRows);
            }
         },
         refreshTournament: () => {
            axios
               .get(SERVER_URL + "/melee/refreshTournament")
               .then((response) => {
                  alert(response.data);
               });
         },
         subscribeStandingsViewer: () => {
            socket.emit("subscribeStandingsViewer", (response) => {
               console.log(response);
            });

            socket.on("standingsViewerLoad", (standings) => {
               setStandings(standings);
            });
         },
      }),
      []
   );

   return [
      standings,
      setStandings,
      cachedStandings,
      tournamentRounds,
      notification,
      handlers,
   ];
}
