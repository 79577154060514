import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import CheckActive from "./CheckActive";
import PlayerSelect from "./PlayerSelect";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const allowedDataFields = [
   "Age",
   "STARTED_IN",
   "TOP_FINISHES",
   "TwitterHandle",
   "PREFERRED_PRONOUNS",
   "COUNTRY",
   "BULLET_1",
   "BULLET_2",
];

export default function PlayerDataEditor({
   playerPosition,
   playerId,
   activeDataFields,
   data,
   handleDataFieldUpdate,
   handlePlayerChange,
   handleGetPlayerData,
}) {
   const [time, setTime] = useState(Date.now());

   const checkActive = (field) => {
      let active = null;
      activeDataFields.data.map((row, i) => {
         if (JSON.stringify(row.field) === JSON.stringify(field)) {
            active = i;
         }
      });
      return active;
   };

   const checkActiveDecklist = (playerPosition, field) => {
      let active = false;
      if (activeDataFields["PlayerDecklistP" + playerPosition] == field) {
         active = "active";
      }
      return active;
   };

   const activeNumber = (fieldVal) => {
      let index = _.findIndex(activeDataFields.data, function (o) {
         return o.field == fieldVal;
      });

      let number = index > -1 ? index + 1 : "";
      return number;
   };

   const timeSinceUpdate = (lastUpdate) => {
      console.log(Date.parse(lastUpdate));
      let date1 = new Date(lastUpdate);
      let date2 = new Date(time);
      let difference = date2.getTime() - date1.getTime();

      const secondsAgo = Math.round(difference / 1000);
      const minutesAgo = Math.round(difference / 60000);

      return secondsAgo >= 60
         ? minutesAgo + " minute" + (minutesAgo > 1 ? "s" : "") + " ago"
         : secondsAgo + " seconds ago";
   };

   useEffect(() => {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   return (
      <Stack
         sx={{ backgroundColor: "#fff", overflow: "hidden" }}
         alignItems='center'
      >
         <PlayerSelect
            playerPosition={playerPosition}
            selected={playerId}
            callback={(e) =>
               handlePlayerChange({
                  ["PlayerIdP" + playerPosition]: e.value,
               })
            }
         />

         {!data && (
            <Button
               variant='contained'
               color='error'
               id={playerId}
               onClick={handleGetPlayerData}
               sx={{ mb: 4 }}
            >
               LOAD PLAYER DATA
            </Button>
         )}

         {data && (
            <>
               <Button
                  variant='outlined'
                  color='secondary'
                  id={playerId}
                  onClick={handleGetPlayerData}
                  sx={{ mb: 1 }}
               >
                  REFRESH METADATA
               </Button>
               <i style={{ color: "#ccc" }}>
                  Refreshes Player Metadata (if changed on Melee.gg)
               </i>

               <Table sx={{ mt: 4 }}>
                  <TableHead sx={{ backgroundColor: "#333" }}>
                     <TableRow>
                        <TableCell
                           colSpan={2}
                           sx={{ color: "#fff", fontWeight: "bold" }}
                        >
                           DECKLISTS
                        </TableCell>
                        <TableCell align='right'>
                           <Button
                              id={"PlayerDecklistP" + playerPosition}
                              value={null}
                              onClick={handleDataFieldUpdate}
                              variant='outlined'
                              color='error'
                           >
                              HIDE DECKLIST
                           </Button>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {data.Decklists &&
                        data.Decklists.map((decklist, i) => (
                           <TableRow key={"decklist" + i}>
                              <TableCell
                                 sx={{
                                    width: 20,
                                    backgroundColor:
                                       checkActiveDecklist(
                                          playerPosition,
                                          decklist.ID
                                       ) === "active"
                                          ? "green"
                                          : "transparent",
                                 }}
                              >
                                 <CheckActive
                                    active={checkActiveDecklist(
                                       playerPosition,
                                       decklist.ID
                                    )}
                                 />
                              </TableCell>
                              <TableCell>{decklist.Format}</TableCell>
                              <TableCell align='right'>
                                 <Button
                                    id={"PlayerDecklistP" + playerPosition}
                                    value={decklist.ID}
                                    onClick={handleDataFieldUpdate}
                                 >
                                    SHOW
                                 </Button>
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
                  <TableHead sx={{ backgroundColor: "#333" }}>
                     <TableRow>
                        <TableCell
                           colSpan={3}
                           sx={{ color: "#fff", fontWeight: "bold" }}
                        >
                           DATA FIELDS
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     <TableRow
                        sx={{
                           backgroundColor:
                              _.findIndex(activeDataFields.data, function (o) {
                                 return o.field == "Record";
                              }) > -1
                                 ? "#b6ddb1"
                                 : "",
                        }}
                     >
                        <TableCell
                           sx={{
                              width: 20,
                              color: "#fff",
                              backgroundColor:
                                 _.findIndex(
                                    activeDataFields.data,
                                    function (o) {
                                       return o.field == "Record";
                                    }
                                 ) > -1
                                    ? "green"
                                    : "",
                           }}
                        >
                           {activeNumber("Record")}
                        </TableCell>
                        <TableCell>
                           <Box sx={{ width: "100%" }}>
                              <Stack direction='row'>
                                 <Box sx={{ width: "50%", overflow: "hidden" }}>
                                    Record
                                 </Box>
                                 <Box sx={{ width: "50%", overflow: "hidden" }}>
                                    {data.Record}
                                 </Box>
                              </Stack>
                           </Box>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                           <Button
                              id={0}
                              size='small'
                              value={"Record"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              1
                           </Button>
                           <Button
                              id={1}
                              size='small'
                              value={"Record"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              2
                           </Button>
                           <Button
                              id={2}
                              size='small'
                              value={"Record"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              3
                           </Button>
                        </TableCell>
                     </TableRow>
                     <TableRow
                        sx={{
                           backgroundColor:
                              _.findIndex(activeDataFields.data, function (o) {
                                 return o.field == "Rank";
                              }) > -1
                                 ? "#b6ddb1"
                                 : "",
                        }}
                     >
                        <TableCell
                           sx={{
                              width: 20,
                              color: "#fff",
                              backgroundColor:
                                 _.findIndex(
                                    activeDataFields.data,
                                    function (o) {
                                       return o.field == "Rank";
                                    }
                                 ) > -1
                                    ? "green"
                                    : "",
                           }}
                        >
                           {activeNumber("Rank")}
                        </TableCell>
                        <TableCell>
                           <Box sx={{ width: "100%" }}>
                              <Stack direction='row'>
                                 <Box sx={{ width: "50%", overflow: "hidden" }}>
                                    Rank
                                 </Box>
                                 <Box sx={{ width: "50%", overflow: "hidden" }}>
                                    {data.Rank}
                                 </Box>
                              </Stack>
                           </Box>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                           <Button
                              id={0}
                              size='small'
                              value={"Rank"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              1
                           </Button>
                           <Button
                              id={1}
                              size='small'
                              value={"Rank"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              2
                           </Button>
                           <Button
                              id={2}
                              size='small'
                              value={"Rank"}
                              variant='outlined'
                              onClick={handleDataFieldUpdate}
                           >
                              3
                           </Button>
                        </TableCell>
                     </TableRow>
                     {data &&
                        _.filter(Object.entries(data), function ([key, value]) {
                           if (allowedDataFields.includes(key)) {
                              return [key, value];
                           }
                        }).map((row, i) => (
                           <TableRow
                              key={"metadata" + i}
                              sx={{
                                 backgroundColor:
                                    _.findIndex(
                                       activeDataFields.data,
                                       function (o) {
                                          return o.field == row[0];
                                       }
                                    ) > -1
                                       ? "#b6ddb1"
                                       : "",
                              }}
                           >
                              <TableCell
                                 sx={{
                                    width: 20,
                                    color: "#fff",
                                    backgroundColor:
                                       _.findIndex(
                                          activeDataFields.data,
                                          function (o) {
                                             return o.field == row[0];
                                          }
                                       ) > -1
                                          ? "green"
                                          : "",
                                 }}
                              >
                                 {activeNumber(row[0])}
                              </TableCell>
                              <TableCell>
                                 <Box sx={{ width: "100%" }}>
                                    <Box
                                       sx={{
                                          width: "50%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                          float: "left",
                                       }}
                                    >
                                       {row[0]}
                                    </Box>
                                    <Box
                                       sx={{
                                          width: "50%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                       }}
                                    >
                                       {row[1] && row[1].length > 30
                                          ? row[1].substring(0, 30) + "..."
                                          : row[1]}
                                    </Box>
                                 </Box>
                              </TableCell>
                              <TableCell sx={{ whiteSpace: "nowrap" }}>
                                 <Button
                                    id={0}
                                    size='small'
                                    value={row[0]}
                                    variant='outlined'
                                    onClick={handleDataFieldUpdate}
                                 >
                                    1
                                 </Button>
                                 <Button
                                    id={1}
                                    size='small'
                                    value={row[0]}
                                    variant='outlined'
                                    onClick={handleDataFieldUpdate}
                                 >
                                    2
                                 </Button>
                                 <Button
                                    id={2}
                                    size='small'
                                    value={row[0]}
                                    variant='outlined'
                                    onClick={handleDataFieldUpdate}
                                 >
                                    3
                                 </Button>
                              </TableCell>
                           </TableRow>
                        ))}
                  </TableBody>
               </Table>
            </>
         )}
      </Stack>
   );
}
