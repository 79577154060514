import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

export default function ScorebarGameround({ children }) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "auto",
            height: "100%",
            zIndex: 1000,
            background: theme.palette.background.scorebartransparency,
            pl: 3,
         }}
      >
         <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={2}
            sx={{
               height: "100%",
               pr: 4,
               pt: 0.5,
               textTransform: "uppercase",
            }}
         >
            <Typography variant='fmRoundTitle' component='div'>
               {children}
            </Typography>
         </Stack>
      </Box>
   );
}
