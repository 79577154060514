import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";

import Wrapper from "../components/layout/Wrapper";
import PreviewData from "../components/databasemanagement/PreviewData";

// HOOKS
import useDatabaseManagement from "../hooks/useDatabaseManagement";
import useGlobals from "../hooks/useGlobals";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DatabaseManagement({ parent }) {
   const [
      log,
      previewData,
      notification,
      {
         preview,
         insert,
         upsert,
         overwrite,
         initNewTournament,
         importCardsJSON,
         importCardImages,
         importStandings,
         importDecklists,
         importPlayers,
         checkForMissingImages,
         checkPlayerFlags,
         refreshTournament,
      },
   ] = useDatabaseManagement();

   const [
      globals,
      { updateGlobal, updateFormats, updateDraftSets, getGlobals },
   ] = useGlobals();

   const [newTournamentID, setNewTournamentID] = useState("");

   const updateNewTournamentID = (e) => {
      if (!isNaN(parseInt(e.target.value))) {
         setNewTournamentID(parseInt(e.target.value));
      }
   };

   const updateDraftSetValue = (e) => {
      updateDraftSets(JSON.parse(e.target.value));
   };

   useEffect(() => {
      getGlobals();
   }, []);

   useEffect(() => {
      // console.log(log);
   }, [log]);

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Database Management
         </Typography>

         <Typography
            variant='h4'
            component='div'
            sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
         >
            GLOBALS
         </Typography>

         <Grid
            container
            alignItems='center'
            spacing={2}
            sx={{ color: "#fff", backgroundColor: "#555", pb: 2 }}
         >
            {globals &&
               globals.length > 0 &&
               globals.map((global, i) => (
                  <React.Fragment key={global + "-" + i}>
                     {global.id == "RoundByRoundTitles" && (
                        <Grid item xs={12}>
                           <Stack direction='row' spacing={2}>
                              <Button
                                 variant={
                                    global.json.includes("Draft")
                                       ? "contained"
                                       : "outlined"
                                 }
                                 color={
                                    global.json.includes("Draft")
                                       ? "error"
                                       : "primary"
                                 }
                                 onClick={() =>
                                    updateFormats(global.json, "Draft")
                                 }
                              >
                                 Draft
                              </Button>

                              <Button
                                 variant={
                                    global.json.includes("Standard")
                                       ? "contained"
                                       : "outlined"
                                 }
                                 color={
                                    global.json.includes("Standard")
                                       ? "error"
                                       : "primary"
                                 }
                                 onClick={() =>
                                    updateFormats(global.json, "Standard")
                                 }
                              >
                                 Standard
                              </Button>

                              <Button
                                 variant={
                                    global.json.includes("Pioneer")
                                       ? "contained"
                                       : "outlined"
                                 }
                                 color={
                                    global.json.includes("Pioneer")
                                       ? "error"
                                       : "primary"
                                 }
                                 onClick={() =>
                                    updateFormats(global.json, "Pioneer")
                                 }
                              >
                                 Pioneer
                              </Button>

                              <Button
                                 variant={
                                    global.json.includes("Modern")
                                       ? "contained"
                                       : "outlined"
                                 }
                                 color={
                                    global.json.includes("Modern")
                                       ? "error"
                                       : "primary"
                                 }
                                 onClick={() =>
                                    updateFormats(global.json, "Modern")
                                 }
                              >
                                 Modern
                              </Button>
                           </Stack>
                        </Grid>
                     )}
                     {global.id == "DraftSets" && (
                        <>
                           <Grid item xs={3}>
                              <b>{global.id}</b>
                           </Grid>
                           <Grid item xs={9}>
                              <Stack direction='row' spacing={2}>
                                 {global.json && (
                                    <TextField
                                       size='small'
                                       variant='outlined'
                                       defaultValue={JSON.stringify(
                                          global.json
                                       )}
                                       onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                             e.target.blur();
                                          }
                                       }}
                                       onBlur={updateDraftSetValue}
                                    />
                                 )}
                              </Stack>
                           </Grid>
                        </>
                     )}

                     {global.id != "RoundByRoundTitles" &&
                        global.id != "DraftSets" && (
                           <>
                              <Grid item xs={3}>
                                 <b>{global.id}</b>
                              </Grid>
                              <Grid item xs={9}>
                                 {global.data && (
                                    <TextField
                                       disabled
                                       size='small'
                                       variant='outlined'
                                       value={global.data}
                                    />
                                 )}

                                 {global.json && (
                                    <TextField
                                       disabled
                                       size='small'
                                       variant='outlined'
                                       value={JSON.stringify(global.json)}
                                    />
                                 )}
                              </Grid>
                           </>
                        )}
                  </React.Fragment>
               ))}
         </Grid>

         {parent != "producer" && (
            <>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{ textTransform: "uppercase", mt: 8, mb: 2 }}
               >
                  MTG MELEE
               </Typography>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  IMPORT MELEE DATA
               </Typography>
               <Grid
                  container
                  spacing={3}
                  sx={{ mb: 4, color: "#fff" }}
                  alignItems='center'
               >
                  {/* <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='success'
                        onClick={importStandings}
                        fullWidth
                     >
                        Current Standings
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Import/Update the Current Standings
                  </Grid> */}

                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='success'
                        onClick={() => importDecklists(false)}
                        fullWidth
                     >
                        Decklists
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Import/Update all Player Decklists
                  </Grid>

                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='success'
                        onClick={() => importPlayers(false)}
                        fullWidth
                     >
                        Players
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Import/Update all Players
                  </Grid>
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='success'
                        onClick={refreshTournament}
                        fullWidth
                     >
                        Refresh Tournament Round Data
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Updates Tournament data in Evoke—use for Round information
                     for Standings
                  </Grid>
               </Grid>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  VERIFY MELEE DATA
               </Typography>

               <Grid
                  container
                  spacing={3}
                  sx={{ mb: 4, color: "#fff" }}
                  alignItems='center'
               >
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='secondary'
                        onClick={checkForMissingImages}
                        fullWidth
                     >
                        Check for Missing Card Images
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Compares currently cached Melee decklist cards and card
                     library
                  </Grid>
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='secondary'
                        onClick={checkPlayerFlags}
                        fullWidth
                     >
                        Check for Player Country Flags
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Checks to see if player Metadata "COUNTRY" matches a flag
                     image
                  </Grid>
               </Grid>
            </>
         )}
         <Stack sx={{ color: "#fff", mt: 8 }} spacing={2}>
            {log &&
               log.length > 0 &&
               log.map((row, i) => (
                  <Grid container key={"log-" + i + "-" + row.timestamp}>
                     <Grid item xs={2}>
                        {row.message}
                        <br />
                        <i>
                           {new Date(row.timestamp).toLocaleTimeString("en-US")}
                        </i>
                     </Grid>
                     <Grid
                        item
                        xs={10}
                        sx={{
                           width: "100%",
                           maxHeight: 500,
                           overflow: "scroll",
                           border: "1px solid #ccc",
                        }}
                     >
                        <pre>{JSON.stringify(row.response.data, null, 4)}</pre>
                     </Grid>
                  </Grid>
               ))}
         </Stack>

         {parent == "admin" && (
            <>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{ textTransform: "uppercase", mt: 8, mb: 2 }}
               >
                  NO TOUCHY! BOSS MODE ONLY
               </Typography>
               <Grid
                  container
                  spacing={3}
                  sx={{ mb: 4, color: "#fff" }}
                  alignItems='center'
               >
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='warning'
                        onClick={() => importDecklists(true)}
                        fullWidth
                     >
                        Overwrite Decklists
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Deletes all existing decklists and re-adds current MTG
                     Melee decklists. Only use if you have test decklists from
                     an old test TournamentID, or if you experienced Metadata
                     errors that have been corrected (related to Decklist ID #'s
                     being overwritten).
                  </Grid>
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='warning'
                        onClick={() => importPlayers(true)}
                        fullWidth
                     >
                        Overwrite Tournament Players
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Deletes all existing players and re-adds current MTG Melee
                     Tournament Players. Only use if you have players from and
                     old TournamentID, or if you are seeing duplicate players,
                     or need to remove players that were registered, but have
                     since been removed from the tournament.
                  </Grid>
               </Grid>

               <Typography
                  variant='h4'
                  component='div'
                  sx={{ textTransform: "uppercase", mt: 8 }}
               >
                  RE-INITIALIZE
               </Typography>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  These controls are "breaking". They will irreversibly delete
                  content from the system, overwrite card and image data, and
                  change the tournament ID currently be accessed.
                  <br />
                  <b style={{ color: "#f00" }}>USE WITH EXTREME CAUTION</b>
               </Typography>
               <Grid
                  container
                  spacing={3}
                  sx={{ mb: 4, color: "#fff" }}
                  alignItems='center'
               >
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='error'
                        onClick={() =>
                           updateGlobal("TournamentID", newTournamentID)
                        }
                        fullWidth
                     >
                        Update Tournament ID
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     <TextField
                        value={newTournamentID}
                        size='small'
                        variant='outlined'
                        label='Enter Tournament ID'
                        onChange={updateNewTournamentID}
                     />
                     <br />
                     Allows you to change TournamentID #'s. Doing so does not
                     empty out old data from system, but does make Melee use the
                     TournamentID for all future sourced data.
                  </Grid>
                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='error'
                        onClick={() => initNewTournament()}
                        fullWidth
                     >
                        CLEAR ALL DATA / RE-INITIALIZE
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     Only use at start of show. Clears all data from the system.
                     Must run all MTG MELEE Preview routines above after
                     initialization to get players and decklsits back into the
                     system.
                  </Grid>

                  <Grid item xs={4} sx={{ mt: 2 }}>
                     <Button
                        variant='contained'
                        color='error'
                        onClick={importCardsJSON}
                        fullWidth
                     >
                        Import JSON Card Data
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     This imports a JSON card database. Should never be used.
                     (Location: /server/volume/imports/cards.json)
                  </Grid>

                  <Grid item xs={4}>
                     <Button
                        variant='contained'
                        color='error'
                        onClick={importCardImages}
                        fullWidth
                     >
                        Import Card Images
                     </Button>
                  </Grid>
                  <Grid item xs={8}>
                     This imports all the card images into the Database.
                     (Location: /server/volume/cardimages/jpg)
                  </Grid>
               </Grid>
            </>
         )}

         <PreviewData
            data={previewData}
            callback={(runUpdate) => upsert(runUpdate)}
         />

         <NotificationWidget notification={notification} />
      </Wrapper>
   );
}
