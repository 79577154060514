import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { Snackbar } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "90%",
   overflow: "scroll",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

const CopyToClipboardButton = ({ text, sx }) => {
   const [currentText, setCurrentText] = useState();

   const handleClose = () => {
      setCurrentText();
   };

   const handleClick = () => {
      setCurrentText(text);
   };

   return (
      <>
         <Button
            onClick={handleClick}
            variant='outlined'
            color='primary'
            startIcon={<CopyIcon />}
            sx={{ ...sx }}
         >
            COPY URL
         </Button>

         <Modal open={currentText ? true : false} onClose={handleClose}>
            <Box sx={style}>
               <Stack alignItems='center' spacing={4}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ color: "#000", whiteSpace: "nowrap" }}
                  >
                     URL to Copy
                  </Typography>
                  <TextField
                     value={"http://10.1.5.28" + currentText}
                     sx={{ width: 800 }}
                     onFocus={(event) => {
                        event.target.select();
                     }}
                  />

                  <Button
                     variant='outlined'
                     sx={{ whiteSpace: "nowrap" }}
                     onClick={handleClose}
                  >
                     CLOSE
                  </Button>
               </Stack>
            </Box>
         </Modal>
      </>
   );
};

export default CopyToClipboardButton;
