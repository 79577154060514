import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import LowerThirdsPreview from "./LowerThirdsPreview";

//HOOKS
import useLowerThirds from "../../hooks/useLowerThirds";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function StandingsViewer({ outputOverride }) {
   const { output } = useParams();

   const viewerOutput = output ? output : outputOverride;

   // USE HOOK
   const [
      entries,
      allCustomNames,
      allPlayerNames,
      activeData,
      show,
      { subscribeViewer },
   ] = useLowerThirds();

   useEffect(() => {
      subscribeViewer(viewerOutput);
   }, []);

   useEffect(() => {
      if (activeData) {
         // console.log(activeData);
      }
   }, [activeData]);

   return <LowerThirdsPreview activeData={activeData} show={show} />;
}
