import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Downloader from "../components/widgets/Downloader";

import Wrapper from "../components/layout/Wrapper";
import PlayerSelect from "../components/bracket/PlayerSelect";
import MatchWins from "../components/bracket/MatchWins";

import BracketViewer from "./viewers/Top8Viewer";

//HOOKS
import useBracketHandler from "../hooks/useBracketHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Top8({ parent = "" }) {
   let navigate = useNavigate();

   // USE HOOK
   const [
      bracket,
      visible,
      standings,
      runTimestamp,
      {
         updateBracket,
         getTop8,
         showHide,
         bracketViewerLoad,
         bracketViewerShowHide,
         subscribeBracketViewer,
      },
   ] = useBracketHandler();

   useEffect(() => {
      getTop8();
      subscribeBracketViewer();
   }, []);

   useEffect(() => {
      // console.log(bracket);
   }, [bracket]);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // HIDE SHOW
   const handleShowHide = (e) => {
      showHide(e.target.value);
   };

   const playerName = (PlayerId) => {
      let player = { PlayerNameLastFirst: "" };

      if (standings) {
         const playerStandings = _.find(standings, function (o) {
            return o.PlayerId === PlayerId;
         });

         if (playerStandings) {
            player = playerStandings;
         }
      }

      return player.PlayerNameLastFirst;
   };

   const updateSelection = (column, slot, playerId) => {
      let newState = { ...bracket };
      newState[column][slot] = {
         player: playerId,
         wins: 0,
         winner: false,
      };

      if (column === "qf") {
         if (slot % 2) {
            if (newState[column][slot - 1]) {
               newState[column][slot - 1].winner = false;
            }
         } else {
            if (newState[column][slot + 1]) {
               newState[column][slot + 1].winner = false;
            }
         }

         newState["sf"][Math.floor(slot / 2)] = null;
      }

      if (column === "sf") {
         if (slot % 2) {
            if (newState[column][slot - 1]) {
               newState[column][slot - 1].winner = false;
            }
         } else {
            if (newState[column][slot + 1]) {
               newState[column][slot + 1].winner = false;
            }
         }

         newState["f"][Math.floor(slot / 2)] = null;
      }

      updateBracket(newState);
   };

   const updateWins = (column, slot, wins) => {
      let newState = { ...bracket };
      newState[column][slot].wins = wins;
      updateBracket(newState);
   };

   const movingOn = (column, slot, playerId) => {
      let newState = { ...bracket };

      if (slot % 2) {
         if (newState[column][slot - 1]) {
            newState[column][slot - 1].winner = false;
         }
      } else {
         if (newState[column][slot + 1]) {
            newState[column][slot + 1].winner = false;
         }
      }

      if (column === "qf") {
         newState["sf"][Math.floor(slot / 2)] = {
            player: playerId,
            wins: 0,
            winner: false,
         };
      }

      if (column === "sf") {
         newState["f"][Math.floor(slot / 2)] = {
            player: playerId,
            wins: 0,
            winner: false,
         };
      }

      if (newState[column][slot] && newState[column][slot].winner === true) {
         newState[column][slot].winner = false;
         if (column == "qf") {
            newState["sf"][Math.floor(slot / 2)] = null;
            newState["f"][0] = null;
            newState["f"][1] = null;
         }
         if (column == "sf") {
            newState["f"][Math.floor(slot / 2)] = null;
         }
      } else {
         newState[column][slot].winner = true;
      }

      updateBracket(newState);
   };

   return (
      <Wrapper>
         <Stack direction='row' sx={{ width: "100%" }} alignItems='center'>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               Top 8
            </Typography>

            <Box>
               {bracket && (
                  <Downloader
                     enabled={true}
                     downloadName={"Top8Bracket_" + Date.now()}
                  >
                     <BracketViewer />
                  </Downloader>
               )}
            </Box>
         </Stack>
         <Grid container spacing={3} sx={{ mb: 8, maxWidth: 1525 }}>
            <Grid
               item
               xs={parent != "producer" ? 6 : 12}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale + 100, position: "relative" }}
            >
               {parent != "producer" && (
                  <>
                     <Typography
                        variant='h5'
                        component='div'
                        sx={{ textTransform: "uppercase", mb: 2 }}
                     >
                        Top 8 Output
                     </Typography>
                     <Button
                        variant='contained'
                        color='warning'
                        sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                        value={"toggle"}
                        onClick={handleShowHide}
                        size='small'
                     >
                        TOGGLE
                     </Button>
                     <Button
                        variant='contained'
                        color='success'
                        sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                        value={"show"}
                        onClick={handleShowHide}
                        size='small'
                     >
                        SHOW
                     </Button>
                     <Button
                        variant='contained'
                        color='error'
                        sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                        value={"hide"}
                        onClick={handleShowHide}
                        size='small'
                     >
                        HIDE
                     </Button>
                  </>
               )}

               <Box
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                     mt: 2,
                  }}
                  className='transparentBG'
               >
                  <BracketViewer />
               </Box>
            </Grid>
         </Grid>
         {/* 
         {standings.length == 0 && (
            <Typography variant='h5' sx={{ color: "#ccc" }}>
               Can't set Top 8 Bracket, no standings data currently in Evoke.
            </Typography>
         )} */}
         {parent != "producer" && bracket && standings && (
            <Box
               sx={{
                  position: "relative",
               }}
            >
               <Stack
                  spacing={2}
                  direction='row'
                  alignItems='center'
                  sx={{ height: "100%" }}
               >
                  {/* QUARTERFINALS */}
                  <Stack spacing={4} sx={{ width: "100%" }}>
                     <Typography variant='h6Viewer'>Quarterfinals</Typography>
                     <Stack spacing={0}>
                        {bracket.qf.map((row, i) => (
                           <React.Fragment key={"qf" + i}>
                              {i < 2 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                    }}
                                 >
                                    <PlayerSelect
                                       allPlayers={standings}
                                       selected={row && row.player}
                                       column='qf'
                                       slot={i}
                                       callback={updateSelection}
                                    />
                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='qf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5 }}
                                             onClick={() =>
                                                movingOn("qf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>

                     <Stack spacing={0}>
                        {bracket.qf.map((row, i) => (
                           <React.Fragment key={"qf" + i}>
                              {i >= 2 && i < 4 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                    }}
                                 >
                                    <PlayerSelect
                                       allPlayers={standings}
                                       selected={row && row.player}
                                       column='qf'
                                       slot={i}
                                       callback={updateSelection}
                                    />
                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='qf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5 }}
                                             onClick={() =>
                                                movingOn("qf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>

                     <Stack spacing={0}>
                        {bracket.qf.map((row, i) => (
                           <React.Fragment key={"qf" + i}>
                              {i >= 4 && i < 6 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                    }}
                                 >
                                    <PlayerSelect
                                       allPlayers={standings}
                                       selected={row && row.player}
                                       column='qf'
                                       slot={i}
                                       callback={updateSelection}
                                    />
                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='qf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5 }}
                                             onClick={() =>
                                                movingOn("qf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>

                     <Stack spacing={0}>
                        {bracket.qf.map((row, i) => (
                           <React.Fragment key={"qf" + i}>
                              {i >= 6 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                    }}
                                 >
                                    <PlayerSelect
                                       allPlayers={standings}
                                       selected={row && row.player}
                                       column='qf'
                                       slot={i}
                                       callback={updateSelection}
                                    />
                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='qf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5 }}
                                             onClick={() =>
                                                movingOn("qf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>
                  </Stack>
                  )}
                  {/* SEMIFINALS */}
                  <Stack
                     spacing={4}
                     sx={{ height: "100%", width: "100%" }}
                     alignItems='flex-start'
                  >
                     <Typography variant='h6Viewer'>Semifinals</Typography>
                     <Stack spacing={0} sx={{ height: 400, width: "100%" }}>
                        {bracket.sf.map((row, i) => (
                           <React.Fragment key={"sf" + i}>
                              {i < 2 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                       height: 80,
                                    }}
                                    alignItems='center'
                                 >
                                    <Box sx={{ width: "100%" }}>
                                       {row && playerName(row.player)}
                                    </Box>

                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='sf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5, lineHeight: 3 }}
                                             onClick={() =>
                                                movingOn("sf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>

                     <Stack spacing={0} sx={{ width: "100%" }}>
                        {bracket.sf.map((row, i) => (
                           <React.Fragment key={"sf" + i}>
                              {i >= 2 && (
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                       height: 80,
                                    }}
                                    alignItems='center'
                                 >
                                    <Box sx={{ width: "100%" }}>
                                       {row && playerName(row.player)}
                                    </Box>

                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='sf'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5, lineHeight: 3 }}
                                             onClick={() =>
                                                movingOn("sf", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              )}
                           </React.Fragment>
                        ))}
                     </Stack>
                  </Stack>
                  {/* FINALS */}
                  <Stack
                     spacing={4}
                     sx={{ width: "100%" }}
                     alignItems='flex-start'
                  >
                     <Typography variant='h6Viewer'>Finals</Typography>

                     <Stack
                        spacing={4}
                        alignItems='center'
                        sx={{ width: "100%" }}
                     >
                        <Stack spacing={0} sx={{ width: "100%" }}>
                           {bracket.f.map((row, i) => (
                              <React.Fragment key={"f" + i}>
                                 <Stack
                                    direction='row'
                                    spacing={2}
                                    sx={{
                                       backgroundColor: "#fff",
                                       p: 2,
                                       height: 80,
                                    }}
                                    alignItems='center'
                                 >
                                    <Box sx={{ width: "100%" }}>
                                       {row && playerName(row.player)}
                                    </Box>

                                    {row && row.player !== "0" && (
                                       <>
                                          <MatchWins
                                             wins={row && row.wins}
                                             column='f'
                                             slot={i}
                                             callback={updateWins}
                                          />
                                          <Button
                                             variant='contained'
                                             color={
                                                row.winner
                                                   ? "error"
                                                   : "secondary"
                                             }
                                             sx={{ px: 5, lineHeight: 3 }}
                                             onClick={() =>
                                                movingOn("f", i, row.player)
                                             }
                                          >
                                             Winner
                                          </Button>
                                       </>
                                    )}
                                 </Stack>
                              </React.Fragment>
                           ))}
                        </Stack>
                     </Stack>
                  </Stack>
               </Stack>
            </Box>
         )}
      </Wrapper>
   );
}
