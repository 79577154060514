import React, { useState, useContext, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function AllPhotoSelect({ label, playerId, photos, callback }) {
   const handlePhotoChange = (e) => {
      callback(e.target.value);
   };

   const [focus, setFocus] = useState(false);

   return (
      <>
         {playerId && photos && !focus && (
            <>
               <Button
                  variant='outlined'
                  sx={{ ml: 3 }}
                  onClick={() => setFocus(true)}
               >
                  SELECT PHOTO
               </Button>
            </>
         )}

         {photos && focus && (
            <>
               <FormControl fullWidth sx={{ width: "100%" }}>
                  <InputLabel id={"photos"}>Photos</InputLabel>
                  <Select
                     label={"photos"}
                     onChange={handlePhotoChange}
                     onBlur={() => setFocus(false)}
                     sx={{ backgroundColor: "#fff" }}
                  >
                     <MenuItem value=''>Select a Photo</MenuItem>
                     {photos.map((photo) => (
                        <MenuItem key={photo.fileName} value={photo.fileName}>
                           {photo.fileName}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </>
         )}
      </>
   );
}
