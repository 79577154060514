import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function BestOf({
   label,
   tableId,
   property,
   amounts,
   value,
   callback,
}) {
   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, getAllPlayers },
   ] = useFeatureMatchHandler();

   const setBestOf = (amount) => {
      callback(tableId, { [property]: amount }, true);
   };

   const decrementBestOf = () => {
      callback(tableId, { [property]: Math.max(value - 2, 1) }, true);
   };

   const incrementBestOf = () => {
      callback(tableId, { [property]: Math.min(value + 2, 9) }, true);
   };

   return (
      <Stack direction='row' spacing={2} alignItems='center' sx={{ pl: 8 }}>
         <Typography variant='h5' color='text.dark' component='div'>
            {label}
         </Typography>
         <Stack spacing={4} direction='row'>
            <Button variant='outlined' onClick={decrementBestOf}>
               -
            </Button>
            <Typography
               variant='h3'
               color='text.light'
               component='div'
               sx={{ mb: 2, width: "100%" }}
            >
               {value}
            </Typography>
            <Button variant='outlined' onClick={incrementBestOf}>
               +
            </Button>
         </Stack>
      </Stack>
   );
}
