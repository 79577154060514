import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import TextInput from "../inputs/TextInput";
import SelectLowerThirdName from "../inputs/SelectLowerThirdName";
import SelectInput from "../inputs/SelectInput";

export default function Keys({
   entries,
   addEntry,
   updateEntry,
   deleteEntry,
   viewerLoad,
   allCustomNames,
   allPlayerNames,
   actions,
}) {
   const [activeEdit, setActiveEdit] = useState();
   const handleEntryEditToggle = (entryId) => {
      setActiveEdit((prevState) => {
         if (prevState == entryId) {
            return null;
         }

         return entryId;
      });
   };

   function getCustomName(column, id) {
      const name = _.find(allCustomNames, (e) => {
         return id == e.id;
      });

      return name ? name[column] : "NO NAME SELECTED";
   }

   function getPlayerName(column, id) {
      const name = _.find(allPlayerNames, (e) => {
         return id == e.id;
      });

      return name ? name[column] : null;
   }

   return (
      <>
         <Stack
            direction='row'
            alignItems='center'
            spacing={2}
            sx={{
               width: "100%",
               mb: 2,
            }}
         >
            <Typography variant='h4'>KEYS</Typography>

            <Button onClick={addEntry} variant='contained'>
               + ADD KEY
            </Button>

            <Button
               onClick={actions.editNames}
               variant='contained'
               color='warning'
            >
               EDIT NAMES
            </Button>
         </Stack>

         <Stack sx={{ width: "100%" }}>
            {entries &&
               entries.map((entry, i) => (
                  <>
                     <Stack
                        key={"featuredCardGroup-" + entry.id}
                        direction='row'
                        alignItems='center'
                        justifyContent='space-between'
                        sx={{
                           color: "#fff",
                           px: 2,
                           py: 0,
                           borderTop: "1px solid #ccc",
                           width: "100%",
                           backgroundColor:
                              i % 2
                                 ? "rgba(255,255,255,.2)"
                                 : "rgba(255,255,255,.1)",
                        }}
                        spacing={4}
                     >
                        <Box sx={{ minWidth: 50 }}>
                           <Typography
                              variant='h4'
                              component='div'
                              sx={{ textAlign: "right" }}
                           >
                              {entry.id}
                           </Typography>
                        </Box>

                        {entry.layoutId && (
                           <>
                              <Box sx={{ minWidth: 200 }}>
                                 <Chip
                                    label={
                                       entry.layoutId &&
                                       _.find(
                                          [
                                             {
                                                id: 1,
                                                layoutTitle: "1-up Name",
                                             },
                                             {
                                                id: 2,
                                                layoutTitle: "2-up Name",
                                             },
                                             {
                                                id: 3,
                                                layoutTitle: "3-up Name",
                                             },
                                             {
                                                id: 4,
                                                layoutTitle: "Victory",
                                             },
                                             {
                                                id: 5,
                                                layoutTitle: "Generic Text Key",
                                             },
                                          ],
                                          (e) => {
                                             return entry.layoutId == e.id;
                                          }
                                       ).layoutTitle
                                    }
                                    color='warning'
                                    variant='outlined'
                                    sx={{ fontWeight: "bold" }}
                                 />
                              </Box>
                           </>
                        )}

                        {!entry.layoutId && (
                           <Stack
                              sx={{
                                 backgroundColor: "rgba(0,0,0,.2)",
                                 p: 2,
                                 borderRadius: 2,
                                 width: 450,
                              }}
                              spacing={2}
                           >
                              <SelectLowerThirdName
                                 direction='row'
                                 label='Layout'
                                 size='small'
                                 menuOptionKey='layoutTitle'
                                 options={[
                                    { id: 1, layoutTitle: "1-up Name" },
                                    { id: 2, layoutTitle: "2-up Name" },
                                    { id: 3, layoutTitle: "3-up Name" },
                                    { id: 4, layoutTitle: "Victory" },
                                    {
                                       id: 5,
                                       layoutTitle: "Generic Text Key",
                                    },
                                 ]}
                                 value={entry.layoutId}
                                 callback={(value) => {
                                    updateEntry(entry.id, {
                                       layoutId: value,
                                    });
                                    setActiveEdit(entry.id);
                                 }}
                              />
                           </Stack>
                        )}

                        <Stack
                           spacing={2}
                           sx={{
                              width: "100%",
                           }}
                        >
                           {entry.layoutId && (
                              <Stack
                                 direction='row'
                                 alignItems='flex-start'
                                 sx={{
                                    color: "#fff",
                                    width: "100%",
                                 }}
                                 spacing={4}
                              >
                                 {allCustomNames && allPlayerNames && (
                                    <Stack>
                                       <b
                                          style={{
                                             textTransform: entry.titleUppercase
                                                ? "uppercase"
                                                : "inherit",
                                          }}
                                       >
                                          {entry.custom1id &&
                                             getCustomName(
                                                "title",
                                                entry.custom1id
                                             )}
                                       </b>

                                       <i
                                          style={{
                                             fontSize: ".8em",
                                             color: "#999",
                                             textTransform:
                                                entry.descriptionUppercase
                                                   ? "uppercase"
                                                   : "inherit",
                                          }}
                                       >
                                          {entry.custom1id &&
                                             getCustomName(
                                                "description",
                                                entry.custom1id
                                             )}
                                       </i>

                                       <b
                                          style={{
                                             textTransform: entry.titleUppercase
                                                ? "uppercase"
                                                : "inherit",
                                          }}
                                       >
                                          {entry.player1id &&
                                             getPlayerName(
                                                "title",
                                                entry.player1id
                                             )}
                                       </b>
                                       <i
                                          style={{
                                             fontSize: ".8em",
                                             color: "#999",
                                             textTransform:
                                                entry.descriptionUppercase
                                                   ? "uppercase"
                                                   : "inherit",
                                          }}
                                       >
                                          {entry.player1id &&
                                             getPlayerName(
                                                "twitter",
                                                entry.player1id
                                             )}
                                       </i>
                                    </Stack>
                                 )}

                                 {allCustomNames &&
                                    allPlayerNames &&
                                    entry.layoutId >= 2 &&
                                    entry.layoutId < 4 && (
                                       <Stack>
                                          <b
                                             style={{
                                                textTransform:
                                                   entry.titleUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.custom2id &&
                                                getCustomName(
                                                   "title",
                                                   entry.custom2id
                                                )}
                                          </b>
                                          <i
                                             style={{
                                                fontSize: ".8em",
                                                color: "#999",
                                                textTransform:
                                                   entry.descriptionUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.custom2id &&
                                                getCustomName(
                                                   "description",
                                                   entry.custom2id
                                                )}
                                          </i>

                                          <b
                                             style={{
                                                textTransform:
                                                   entry.titleUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.player2id &&
                                                getPlayerName(
                                                   "title",
                                                   entry.player2id
                                                )}
                                          </b>

                                          <i
                                             style={{
                                                fontSize: ".8em",
                                                color: "#999",
                                                textTransform:
                                                   entry.descriptionUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.player2id &&
                                                getPlayerName(
                                                   "twitter",
                                                   entry.player2id
                                                )}
                                          </i>
                                       </Stack>
                                    )}

                                 {allCustomNames &&
                                    allPlayerNames &&
                                    entry.layoutId >= 3 &&
                                    entry.layoutId < 4 && (
                                       <Stack>
                                          <b
                                             style={{
                                                textTransform:
                                                   entry.titleUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.custom3id &&
                                                getCustomName(
                                                   "title",
                                                   entry.custom3id
                                                )}
                                          </b>
                                          <i
                                             style={{
                                                fontSize: ".8em",
                                                color: "#999",
                                                textTransform:
                                                   entry.descriptionUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.custom3id &&
                                                getCustomName(
                                                   "description",
                                                   entry.custom3id
                                                )}
                                          </i>

                                          <b
                                             style={{
                                                textTransform:
                                                   entry.titleUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.player3id &&
                                                getPlayerName(
                                                   "title",
                                                   entry.player3id
                                                )}
                                          </b>

                                          <i
                                             style={{
                                                fontSize: ".8em",
                                                color: "#999",
                                                textTransform:
                                                   entry.descriptionUppercase
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {entry.player3id &&
                                                getPlayerName(
                                                   "twitter",
                                                   entry.player3id
                                                )}
                                          </i>
                                       </Stack>
                                    )}
                              </Stack>
                           )}
                        </Stack>
                        <Stack
                           sx={{
                              backgroundColor: "rgba(0,0,0,.2)",
                              p: 2,
                              borderRadius: 2,
                           }}
                           alignItems='center'
                           spacing={2}
                        >
                           <Stack
                              direction='row'
                              spacing={1}
                              alignItems='center'
                           >
                              <b>LOAD</b>
                              <Button
                                 variant='contained'
                                 color='success'
                                 onClick={() => viewerLoad(1, entry.id)}
                              >
                                 1
                              </Button>
                              <Button
                                 variant='contained'
                                 color='success'
                                 onClick={() => viewerLoad(2, entry.id)}
                              >
                                 2
                              </Button>
                              <Button
                                 variant='contained'
                                 color='success'
                                 onClick={() => viewerLoad(3, entry.id)}
                              >
                                 3
                              </Button>
                           </Stack>
                        </Stack>
                        <Button
                           color='secondary'
                           variant='outlined'
                           onClick={() => handleEntryEditToggle(entry.id)}
                        >
                           {activeEdit == entry.id ? <>Close</> : <>Edit</>}
                        </Button>
                        <Button
                           variant='outlined'
                           color='error'
                           sx={{ ml: 4 }}
                           onClick={() => deleteEntry(entry.id)}
                        >
                           <DeleteIcon />
                        </Button>
                     </Stack>

                     {activeEdit == entry.id && (
                        <Stack
                           key={"featuredCardGroup-" + entry.id}
                           direction='row'
                           alignItems='center'
                           justifyContent='center'
                           sx={{
                              pl: 4,
                              py: 2,
                              color: "#fff",
                              borderTop: "1px solid #333",
                              width: "100%",
                              backgroundColor:
                                 i % 2
                                    ? "rgba(255,255,255,.2)"
                                    : "rgba(255,255,255,.1)",
                           }}
                           spacing={4}
                        >
                           <Stack
                              sx={{
                                 backgroundColor: "rgba(0,0,0,.2)",
                                 p: 2,
                                 borderRadius: 2,
                                 width: 300,
                              }}
                              spacing={2}
                           >
                              <SelectLowerThirdName
                                 direction='row'
                                 label='Layout'
                                 size='small'
                                 menuOptionKey='layoutTitle'
                                 options={[
                                    { id: 1, layoutTitle: "1-up Name" },
                                    { id: 2, layoutTitle: "2-up Name" },
                                    { id: 3, layoutTitle: "3-up Name" },
                                    { id: 4, layoutTitle: "Victory" },
                                    {
                                       id: 5,
                                       layoutTitle: "Generic Text Key",
                                    },
                                 ]}
                                 value={entry.layoutId}
                                 callback={(value) =>
                                    updateEntry(entry.id, {
                                       layoutId: value,
                                    })
                                 }
                              />

                              <FormGroup>
                                 <Stack direction='row'>
                                    <FormControlLabel
                                       control={
                                          <Switch
                                             checked={entry.showDescription}
                                             onChange={(e) => {
                                                updateEntry(entry.id, {
                                                   showDescription: e.target
                                                      .checked
                                                      ? true
                                                      : false,
                                                });
                                             }}
                                             inputProps={{
                                                "aria-label": "controlled",
                                             }}
                                          />
                                       }
                                       labelPlacement='bottom'
                                       label='Show Description'
                                       sx={{
                                          color: "#999",
                                          textAlign: "center",
                                       }}
                                       componentsProps={{
                                          typography: {
                                             variant: "h6",
                                             style: { color: "#999" },
                                          },
                                       }}
                                    />
                                    <FormControlLabel
                                       control={
                                          <Switch
                                             checked={entry.titleUppercase}
                                             onChange={(e) => {
                                                updateEntry(entry.id, {
                                                   titleUppercase: e.target
                                                      .checked
                                                      ? true
                                                      : false,
                                                });
                                             }}
                                             inputProps={{
                                                "aria-label": "controlled",
                                             }}
                                          />
                                       }
                                       labelPlacement='bottom'
                                       label='Title All Caps'
                                       sx={{
                                          color: "#999",
                                          textAlign: "center",
                                       }}
                                       componentsProps={{
                                          typography: {
                                             variant: "h6",
                                             style: { color: "#999" },
                                          },
                                       }}
                                    />
                                    <FormControlLabel
                                       control={
                                          <Switch
                                             checked={
                                                entry.descriptionUppercase
                                             }
                                             onChange={(e) => {
                                                updateEntry(entry.id, {
                                                   descriptionUppercase: e
                                                      .target.checked
                                                      ? true
                                                      : false,
                                                });
                                             }}
                                             inputProps={{
                                                "aria-label": "controlled",
                                             }}
                                          />
                                       }
                                       labelPlacement='bottom'
                                       label='Description All Caps'
                                       sx={{
                                          color: "#999",
                                          textAlign: "center",
                                       }}
                                       componentsProps={{
                                          typography: {
                                             variant: "h6",
                                             style: { color: "#999" },
                                          },
                                       }}
                                    />
                                 </Stack>
                              </FormGroup>
                           </Stack>

                           <Stack
                              spacing={2}
                              sx={{
                                 width: "100%",
                              }}
                           >
                              {entry.layoutId && (
                                 <Stack
                                    direction='row'
                                    alignItems='flex-start'
                                    sx={{
                                       color: "#fff",
                                       width: "100%",
                                    }}
                                    spacing={4}
                                 >
                                    <Stack
                                       sx={{
                                          width: 200,
                                       }}
                                    >
                                       {entry.layoutId != 4 && (
                                          <SelectLowerThirdName
                                             label='Custom Name'
                                             size='small'
                                             options={_.orderBy(
                                                allCustomNames,
                                                ["title"],
                                                ["asc"]
                                             )}
                                             value={entry.custom1id}
                                             callback={(value) =>
                                                updateEntry(entry.id, {
                                                   custom1id: value,
                                                   player1id: null,
                                                })
                                             }
                                          />
                                       )}

                                       <SelectLowerThirdName
                                          label={
                                             entry.layoutId == 4
                                                ? "Winner"
                                                : "Player Name"
                                          }
                                          placeholder='Player'
                                          size='small'
                                          menuOptionKey='menuTitle'
                                          options={allPlayerNames}
                                          value={entry.player1id}
                                          callback={(value) =>
                                             updateEntry(entry.id, {
                                                custom1id: null,
                                                player1id: value,
                                             })
                                          }
                                       />
                                    </Stack>

                                    {entry.layoutId >= 2 &&
                                       entry.layoutId < 4 && (
                                          <Stack
                                             sx={{
                                                width: 200,
                                             }}
                                          >
                                             <SelectLowerThirdName
                                                label='Custom Name'
                                                size='small'
                                                options={allCustomNames}
                                                value={entry.custom2id}
                                                callback={(value) =>
                                                   updateEntry(entry.id, {
                                                      custom2id: value,
                                                      player2id: null,
                                                   })
                                                }
                                             />

                                             <SelectLowerThirdName
                                                label='Player Name'
                                                size='small'
                                                menuOptionKey='menuTitle'
                                                options={allPlayerNames}
                                                value={entry.player2id}
                                                callback={(value) =>
                                                   updateEntry(entry.id, {
                                                      custom2id: null,
                                                      player2id: value,
                                                   })
                                                }
                                             />
                                          </Stack>
                                       )}

                                    {entry.layoutId >= 3 &&
                                       entry.layoutId < 4 && (
                                          <Stack
                                             sx={{
                                                width: 200,
                                             }}
                                          >
                                             <SelectLowerThirdName
                                                label='Custom Name'
                                                size='small'
                                                options={allCustomNames}
                                                value={entry.custom3id}
                                                callback={(value) =>
                                                   updateEntry(entry.id, {
                                                      custom3id: value,
                                                      player3id: null,
                                                   })
                                                }
                                             />

                                             <SelectLowerThirdName
                                                label='Player Name'
                                                size='small'
                                                menuOptionKey='menuTitle'
                                                options={allPlayerNames}
                                                value={entry.player3id}
                                                callback={(value) =>
                                                   updateEntry(entry.id, {
                                                      custom3id: null,
                                                      player3id: value,
                                                   })
                                                }
                                             />
                                          </Stack>
                                       )}
                                 </Stack>
                              )}
                           </Stack>
                        </Stack>
                     )}
                  </>
               ))}
         </Stack>
      </>
   );
}
