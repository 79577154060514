import { useState, useMemo, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

function useLogHandler() {
   const socket = useContext(SocketContext);

   const [log, setLog] = useState([]);

   useEffect(() => {
      // console.log(log);
   }, [log]);

   const formatLog = (log) => {
      log.map((row) => {
         let key = Object.keys(row.updates)[0];
         const value = Object.values(row.updates)[0];
         key = key === "WinsP1" ? "Player 1" : "Player 2";

         row.player = key;
         row.wins = value;

         return row;
      });

      return log;
   };

   const handlers = useMemo(
      () => ({
         subscribeLog: () => {
            socket.emit("subscribeLog", (response) => {
               setLog(formatLog(response.log));
            });

            socket.on("logUpdate", (data) => {
               setLog(formatLog(data));
            });
         },
      }),
      [socket]
   );

   return [log, handlers];
}

export default useLogHandler;
