import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function CheckActive({ active }) {
   return (
      <Stack
         justifyContent='center'
         sx={{
            left: -40,
            width: 30,
            height: "100%",
            position: "absolute",
            color: "#fff",
            backgroundColor:
               active === 0
                  ? "#25b569"
                  : active === 1
                  ? "#67c1f5"
                  : active === 2
                  ? "#f85454"
                  : active === "decklist"
                  ? "#848484"
                  : "transparent",
         }}
      >
         <Box>{active === true ? "•" : active !== false ? active + 1 : ""}</Box>
      </Stack>
   );
}
