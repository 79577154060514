import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function useGlobals(init = {}) {
   const socket = useContext(SocketContext);
   const [globals, setGlobals] = useState([]);

   useEffect(() => {
      if (globals) {
         // console.log("Globals", globals);
      }
   }, [globals]);

   const handlers = useMemo(
      () => ({
         updateGlobal: (id, tournamentId) => {
            axios
               .get(SERVER_URL + "/api/updateGlobal/" + id + "/" + tournamentId)
               .then((response) => {
                  handlers.getGlobals();
               });
         },
         updateFormats: (currentFormats, format) => {
            let updatedFormats = currentFormats;

            if (currentFormats.includes(format)) {
               updatedFormats = currentFormats.filter(
                  (item) => item !== format
               );
            } else {
               updatedFormats = [...currentFormats, format];
            }

            axios
               .post(SERVER_URL + "/api/updateFormats", updatedFormats)
               .then((response) => {
                  handlers.getGlobals();
               });
         },
         updateDraftSets: (sets) => {
            axios
               .post(SERVER_URL + "/api/updateDraftSets", sets)
               .then((response) => {
                  handlers.getGlobals();
               });
         },
         getGlobals: () => {
            socket.emit("getGlobals", (response) => {
               setGlobals(response);
            });
         },
      }),
      [socket]
   );

   return [globals, handlers];
}

export default useGlobals;
