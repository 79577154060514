import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

//HOOKS
import useControls from "../../hooks/useControls";

export default function PlayerSelect({ selected, callback }) {
   const [allPlayers, { getAllPlayers }] = useControls();

   useEffect(() => {
      getAllPlayers();
   }, []);

   const handleChange = (e) => {
      if (e.target.value) {
         callback(e.target.value);
      }
   };

   return (
      <>
         {allPlayers && (
            <Box sx={{ py: 2, px: 4, backgroundColor: "#fff", width: "100%" }}>
               <FormControl fullWidth sx={{ maxWidth: 400 }}>
                  <InputLabel id={"playerSelect"}>Player</InputLabel>
                  <Select
                     value={selected}
                     label={"Player"}
                     onChange={handleChange}
                  >
                     <MenuItem value=''>Select a Player</MenuItem>
                     {allPlayers.map((player) => (
                        <MenuItem key={player.id} value={player.id}>
                           {player.PlayerNameLastFirst}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         )}
      </>
   );
}
