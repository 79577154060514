import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import SortIcon from "@mui/icons-material/FilterList";

import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

//HOOKS
import useControls from "../../hooks/useControls";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <Box
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               <Typography>{children}</Typography>
            </Box>
         )}
      </Box>
   );
}

export default function Decklist({ tab, index, decklist, callback }) {
   const theme = useTheme();

   const [orderBy, setOrderBy] = useState("CMC");
   const [sort, setSort] = useState("asc");

   const handleLoad = (fileName, card) => {
      if (fileName && card) {
         callback({
            type: "image",
            value: fileName,
            cardName: card.CardName,
            card: card,
         });
      }
   };

   const handleShowSideboard = () => {
      callback({
         type: "sideboard",
         title: decklist.LastName,
         value: decklist.Sideboard,
      });
   };

   return (
      <>
         {decklist && (
            <TabPanel value={tab} index={index} sx={{ pt: 3 }}>
               <Typography
                  variant='h3'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     mb: 2,
                     color: theme.palette.text.orange,
                     textAlign: "center",
                  }}
               >
                  {decklist.Archetype}
               </Typography>

               <Stack
                  direction='row'
                  spacing={2}
                  alignItems='center'
                  justifyContent='center'
                  sx={{ mb: 4 }}
               >
                  <Button
                     size='small'
                     variant={orderBy == "CMC" ? "contained" : "outlined"}
                     color={orderBy == "CMC" ? "error" : "primary"}
                     onClick={() => setOrderBy("CMC")}
                  >
                     CMC
                  </Button>

                  <Button
                     size='small'
                     variant={orderBy == "CardName" ? "contained" : "outlined"}
                     color={orderBy == "CardName" ? "error" : "primary"}
                     onClick={() => setOrderBy("CardName")}
                  >
                     Card Name
                  </Button>

                  {sort == "asc" && (
                     <IconButton
                        size='small'
                        variant={"outlined"}
                        onClick={() => setSort("desc")}
                     >
                        <SortIcon sx={{ transform: "rotate(180deg)" }} />
                     </IconButton>
                  )}

                  {sort == "desc" && (
                     <IconButton
                        size='small'
                        variant={"outlined"}
                        onClick={() => setSort("asc")}
                     >
                        <SortIcon />
                     </IconButton>
                  )}
               </Stack>

               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     color: theme.palette.text.dark,
                     textAlign: "center",
                  }}
               >
                  SPELLS
               </Typography>

               {_.orderBy(
                  _.filter(decklist.Cards, function (card) {
                     return card.CardType.toLowerCase().indexOf("land") === -1;
                  }),
                  [orderBy],
                  [sort]
               ).map((row, i) => (
                  <Box
                     layout
                     key={"card" + i}
                     sx={{
                        position: "relative",
                        px: 1.2,
                        pt: 0.85,
                        pb: 0.6,
                        mb: 0.4,
                        fontSize: "1rem",
                        lineHeight: "1rem",
                     }}
                  >
                     <Stack direction='row' spacing={1} alignItems='center'>
                        <Box
                           sx={{
                              whiteSpace: "nowrap",
                              width: "100%",
                              overflow: "hidden",
                              maskImage:
                                 "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                              maskRepeat: "no-repeat",
                           }}
                        >
                           {row.CardName}
                        </Box>

                        <Box
                           sx={{
                              position: "absolute",
                              right: 90,
                              zIndex: 10,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maskImage:
                                 "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                              maskRepeat: "no-repeat",
                              pl: "25px",
                              py: 1,
                           }}
                        >
                           <ManaIconsFormatted
                              iconWidth={15}
                              mana={row.CardManaCost}
                           />
                        </Box>
                        <Button
                           value={row.FileName}
                           variant={row.FileName ? "outlined" : "disabled"}
                           onClick={() => handleLoad(row.FileName, row)}
                        >
                           Load
                        </Button>
                     </Stack>
                  </Box>
               ))}

               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textTransform: "uppercase",
                     mb: 2,
                     color: theme.palette.text.dark,
                     textAlign: "center",
                  }}
               >
                  LANDS
               </Typography>
               {_.orderBy(
                  _.filter(decklist.Cards, function (card) {
                     return card.CardType.toLowerCase().indexOf("land") > -1;
                  }),
                  ["CardName"],
                  ["asc"]
               ).map((row, i) => (
                  <Box
                     layout
                     key={"card" + i}
                     sx={{
                        position: "relative",
                        px: 1.2,
                        pt: 0.85,
                        pb: 0.6,
                        mb: 0.4,
                        fontSize: "1rem",
                        lineHeight: "1rem",
                     }}
                  >
                     <Stack direction='row' spacing={1} alignItems='center'>
                        <Box
                           sx={{
                              whiteSpace: "nowrap",
                              width: "100%",
                              overflow: "hidden",
                              maskImage:
                                 "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                              maskRepeat: "no-repeat",
                           }}
                        >
                           {row.CardName}
                        </Box>

                        <Box
                           sx={{
                              position: "absolute",
                              right: 90,
                              zIndex: 10,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maskImage:
                                 "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                              maskRepeat: "no-repeat",
                              pl: "25px",
                              py: 1,
                           }}
                        >
                           <ManaIconsFormatted
                              iconWidth={15}
                              mana={row.CardManaCost}
                           />
                        </Box>
                        <Button
                           value={row.FileName}
                           variant={row.FileName ? "outlined" : "disabled"}
                           onClick={() => handleLoad(row.FileName, row)}
                        >
                           Load
                        </Button>
                     </Stack>
                  </Box>
               ))}

               <Typography
                  variant='h4'
                  component='div'
                  align='center'
                  sx={{
                     textTransform: "uppercase",
                     mt: 4,
                     color: theme.palette.text.dark,
                     textAlign: "center",
                  }}
               >
                  SIDEBOARD
                  <br />
                  <Button
                     size='small'
                     variant='contained'
                     color='error'
                     sx={{ mb: 2 }}
                     onClick={handleShowSideboard}
                  >
                     Show Sideboard
                  </Button>
               </Typography>

               {_.orderBy(decklist.Sideboard, [orderBy], [sort]).map(
                  (row, i) => (
                     <Box
                        layout
                        key={"sideboard" + i}
                        sx={{
                           position: "relative",
                           px: 1.2,
                           pt: 0.85,
                           pb: 0.6,
                           mb: 0.4,
                           fontSize: "1rem",
                           lineHeight: "1rem",
                        }}
                     >
                        <Stack direction='row' spacing={1} alignItems='center'>
                           <Box
                              sx={{
                                 whiteSpace: "nowrap",
                                 width: "100%",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                                 maskRepeat: "no-repeat",
                              }}
                           >
                              {row.CardName}
                           </Box>

                           <Box
                              sx={{
                                 position: "absolute",
                                 right: 90,
                                 zIndex: 10,
                                 whiteSpace: "nowrap",
                                 overflow: "hidden",
                                 maskImage:
                                    "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                                 maskRepeat: "no-repeat",
                                 pl: "25px",
                                 py: 1,
                              }}
                           >
                              <ManaIconsFormatted
                                 iconWidth={15}
                                 mana={row.CardManaCost}
                              />
                           </Box>
                           <Button
                              value={row.FileName}
                              variant={row.FileName ? "outlined" : "disabled"}
                              onClick={() => handleLoad(row.FileName, row)}
                           >
                              Load
                           </Button>
                        </Stack>
                     </Box>
                  )
               )}
            </TabPanel>
         )}
      </>
   );
}
