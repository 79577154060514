import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import LoaderIcon from "./LoaderIconInline";

import { useTheme } from "@mui/material/styles";

// DOWNLOADER
import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

function base64Resize(sourceBase64, scale, fileName) {
   const _scale = scale;
   var img = document.createElement("img");
   img.setAttribute("src", sourceBase64);

   img.onload = () => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var cw = canvas.width;
      var ch = canvas.height;
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min(maxW / iw, maxH / ih);
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);

      canvas.toBlob(function (blob) {
         saveAs(blob, fileName + ".png");
      }, "image/png");
   };
}

export default function Downloader({ enabled, downloadName, children }) {
   const theme = useTheme();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   // DOWNLOAD
   useEffect(() => {
      if (downloadVisibility) {
         window.devicePixelRatio = 2;
         html2canvas(document.getElementById("downloadContent"), {
            useCORS: true,
            logging: false,
            backgroundColor: null,
         })
            .then((canvas) => {
               base64Resize(canvas.toDataURL(), 0.5, downloadVisibility);
            })
            .then(() => {
               setDownloadVisibility(false);
            });
      }
   }, [downloadVisibility]);

   const handleDownload = async (event) => {
      setDownloadVisibility(event.target.value);
   };

   return (
      <>
         <Button
            onClick={handleDownload}
            variant={enabled ? "contained" : "disabled"}
            color='warning'
            sx={{
               position: "relative",
               zIndex: 100,
               width: 220,
               height: 50,
            }}
            value={downloadName}
            size='small'
         >
            DOWNLOAD
         </Button>

         <Box
            id='downloadWindow'
            sx={{
               position: "fixed",
               top: 0,
               left: 0,
               width: "100%",
               height: "100%",
               zIndex: 1500,
               backgroundColor: "rgba(0,0,0,.8)",
               visibility: downloadVisibility ? "visible" : "hidden",
            }}
         >
            <Stack
               alignItems='center'
               justifyContent='center'
               sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: 1500,
                  backgroundColor: "#333",
               }}
            >
               <Typography
                  variant='h3'
                  component='div'
                  sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
               >
                  DOWNLOADING...
               </Typography>
               <LoaderIcon size='large' />
            </Stack>
            <Box
               className='transparentBG'
               sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: 1920,
                  height: 1080,
                  zIndex: 1000,
                  transformOrigin: "top left",
                  overflow: "hidden",
                  border: "1px solid #333",
               }}
            >
               <Box
                  sx={{
                     position: "absolute",
                     width: 1920,
                     height: 1080,
                     top: 0,
                     left: 0,
                     transformOrigin: "top left",
                     overflow: "hidden",
                  }}
                  id='downloadContent'
               >
                  {children}
               </Box>
            </Box>
         </Box>
      </>
   );
}
