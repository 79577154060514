import { useState, useMemo, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

function useFeatureMatchHandler() {
   const socket = useContext(SocketContext);

   const [players, setPlayers] = useState();
   const [allPlayers, setAllPlayers] = useState();
   const [gameState, setGameState] = useState();
   const [cardsInHand, setCardsInHand] = useState({ 1: [], 2: [] });
   const [lastUpdated, setLastUpdated] = useState(0);
   const [updateObject, setUpdateObject] = useState({});
   const [saveRequired, setSaveRequired] = useState(false);

   const [needsSave, setNeedsSave] = useState(false);

   const updateObjectRef = useRef(updateObject);

   useEffect(() => {
      // console.log(gameState);
   }, [gameState]);

   useEffect(() => {
      updateObjectRef.current = updateObject;
      if (Object.keys(updateObject).length) {
         setSaveRequired(true);
      }
   }, [updateObject]);

   const update = (tableId, updates, force = false) => {
      // console.log(updates);
      if (
         updates.hasOwnProperty("PlayerIdP1") ||
         updates.hasOwnProperty("PlayerIdP2") ||
         updates.hasOwnProperty("PlayerDecklistP1") ||
         updates.hasOwnProperty("PlayerDecklistP2") ||
         updates.hasOwnProperty("RoundTitle") ||
         updates.hasOwnProperty("BestOf")
      ) {
         setNeedsSave(true);
      }

      if (force) {
         socket.emit("updateFeatureMatch", tableId, updates);
      } else {
         setUpdateObject((prevState) => {
            let newState = { ...prevState, ...updates };
            return newState;
         });

         setGameState((prevState) => {
            let newState = { ...prevState, ...updates };
            return newState;
         });
      }
   };

   const handlers = useMemo(
      () => ({
         saveTableState: (table) => {
            setNeedsSave(false);
            socket.emit("saveTableState", table);
         },
         saveUpdates: (tableId) => {
            socket.emit("updateFeatureMatch", tableId, updateObjectRef.current);
            setUpdateObject({});
            setSaveRequired(false);
         },
         cancelUpdates: (tableId) => {
            setUpdateObject({});
            setSaveRequired(false);
            handlers.getFeatureMatchData(tableId);
         },
         refreshFMPhotos: (tableId) => {
            socket.emit("refreshFMPhotos", tableId);
         },
         subscribeFeatureMatch: (tableId) => {
            socket.emit("subscribeFeatureMatch", tableId, (response) => {
               setGameState(response.gamestate);
               setPlayers(response.players);
               setCardsInHand(response.cardsInHand);
            });

            socket.on("refreshFMPhotos", (refreshTableId, players) => {
               if (refreshTableId === tableId) {
                  setPlayers(players);
                  setLastUpdated(Date.now());
               }
            });

            socket.on("cardsInHand", (playerPosition, cards) => {
               setCardsInHand((prevState) => {
                  let newState = { ...prevState };
                  newState[playerPosition] = cards;
                  return newState;
               });
            });

            socket.on("dataUpdate", (data) => {
               if (data.tableId == tableId) {
                  if (data.type === "gamestate" && data.json) {
                     setGameState({ ...data.json, ...updateObjectRef.current });
                  }

                  if (data.type === "players" && data.json) {
                     setPlayers(data.json);
                  }
               }
            });
         },
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               setAllPlayers(response);
            });
         },
         getFeatureMatchData: (tableId) => {
            socket.emit("getFeatureMatchData", tableId, (response) => {
               setGameState(response);
            });
         },
      }),
      [socket]
   );

   return [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      handlers,
   ];
}

export default useFeatureMatchHandler;
