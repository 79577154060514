import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/decklists/DataGrid";

import Wrapper from "../components/layout/Wrapper";

import DecklistPreview from "./viewers/DecklistPreview";
import DecklistPreviewDraft from "./viewers/DecklistPreviewDraft";

//HOOKS
import useDatabaseManagement from "../hooks/useDatabaseManagement";
import useDecklistHandler from "../hooks/useDecklistHandler";

export default function Decklists({ parent }) {
   const columnWidth = useRef();
   const [currentViewerId, setCurrentViewerId] = useState();

   const [layout1, setLayout1] = useState();
   const [layout2, setLayout2] = useState();
   const [layout3, setLayout3] = useState();
   const [previewDecklist1, setPreviewDecklist1] = useState();
   const [previewDecklist2, setPreviewDecklist2] = useState();
   const [previewDecklist3, setPreviewDecklist3] = useState();

   // WINDOW LISTENER
   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOK

   const [log, previewData, notification, { importPlayers, importDecklists }] =
      useDatabaseManagement();

   const [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      {
         decklistViewerUpdate,
         getDecklist,
         getAllDecklists,
         decklistViewerLoad,
      },
   ] = useDecklistHandler();

   useEffect(() => {
      getAllDecklists();
   }, []);

   useEffect(() => {
      // console.log(allDecklists);
   }, [allDecklists]);

   useEffect(() => {
      switch (currentViewerId) {
         case 1:
            decklistViewerUpdate(1, {
               layout: layout1,
               decklistId: previewDecklist1,
            });
            break;
         case 2:
            decklistViewerUpdate(2, {
               layout: layout2,
               decklistId: previewDecklist2,
            });
            break;
         case 3:
            decklistViewerUpdate(3, {
               layout: layout3,
               decklistId: previewDecklist3,
            });
            break;
      }
   }, [
      currentViewerId,
      layout1,
      layout2,
      layout3,
      previewDecklist1,
      previewDecklist2,
      previewDecklist3,
   ]);

   const upsertDecklists = async () => {
      await importDecklists(false).then((response) => {
         getAllDecklists();
         alert(response.data);
      });
   };

   const upsertPlayers = async () => {
      await importPlayers(false).then(() => {
         getAllDecklists();
         alert("Updated all Player info.");
      });
   };

   const show = (viewerId, id, layout) => {
      setCurrentViewerId(viewerId);
      handleLoadPreview(viewerId, id, true, layout);
   };

   const handleLoadPreview = (viewerId, id, visibility, layout) => {
      switch (viewerId) {
         case 1:
            setPreviewDecklist1(id);
            setLayout1(layout);
            break;
         case 2:
            setPreviewDecklist2(id);
            setLayout2(layout);
            break;
         case 3:
            setPreviewDecklist3(id);
            setLayout3(layout);
            break;
      }

      let decklistData = _.find(allDecklists, function (o) {
         return o.id == id;
      });
   };

   return (
      <Wrapper>
         <Stack
            direction='row'
            sx={{ width: "100%", mb: 2 }}
            alignItems='center'
            justifyContent='space-between'
         >
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase" }}
            >
               Decklists
            </Typography>

            <Stack direction='row' spacing={2}>
               <Button
                  variant='outlined'
                  color='secondary'
                  onClick={upsertPlayers}
               >
                  Import All Player Info
               </Button>
               <Button
                  variant='contained'
                  color='success'
                  onClick={upsertDecklists}
               >
                  Import All Decklists
               </Button>
            </Stack>
         </Stack>

         {allDecklists && (
            <DataGrid
               data={allDecklists}
               type='decklistOverview'
               callback={decklistViewerLoad}
               load={show}
            />
         )}

         <Grid container spacing={2}>
            <Grid
               item
               xs={4}
               ref={columnWidth}
               sx={{ color: "#fff", position: "relative" }}
            >
               <Stack direction='row' alignItems='center' sx={{ mt: 4, mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
                  >
                     {layout1} PREVIEW 1
                  </Typography>
               </Stack>
               {layout1 != "Draft" && (
                  <Box
                     sx={{ height: 1080 * iframeScale }}
                     key='standardPreview'
                  >
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreview
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist1}
                        />
                     </Box>
                  </Box>
               )}
               {layout1 == "Draft" && (
                  <Box sx={{ height: 1080 * iframeScale }} key='draftPreview'>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreviewDraft
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist1}
                        />
                     </Box>
                  </Box>
               )}
            </Grid>

            <Grid item xs={4} sx={{ color: "#fff", position: "relative" }}>
               <Stack direction='row' alignItems='center' sx={{ mt: 4, mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
                  >
                     {layout2} PREVIEW 2
                  </Typography>
               </Stack>
               {layout2 != "Draft" && (
                  <Box
                     sx={{ height: 1080 * iframeScale }}
                     key='standardPreview'
                  >
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreview
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist2}
                        />
                     </Box>
                  </Box>
               )}
               {layout2 == "Draft" && (
                  <Box sx={{ height: 1080 * iframeScale }} key='draftPreview'>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreviewDraft
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist2}
                        />
                     </Box>
                  </Box>
               )}
            </Grid>

            <Grid item xs={4} sx={{ color: "#fff", position: "relative" }}>
               <Stack direction='row' alignItems='center' sx={{ mt: 4, mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
                  >
                     {layout3} PREVIEW 3
                  </Typography>
               </Stack>
               {layout3 != "Draft" && (
                  <Box
                     sx={{ height: 1080 * iframeScale }}
                     key='standardPreview'
                  >
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreview
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist3}
                        />
                     </Box>
                  </Box>
               )}
               {layout3 == "Draft" && (
                  <Box sx={{ height: 1080 * iframeScale }} key='draftPreview'>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "6px solid #ccc",
                           mt: 3,
                           backgroundColor: "#222",
                        }}
                     >
                        <DecklistPreviewDraft
                           iframeScale={iframeScale}
                           preview={false}
                           decklistId={previewDecklist3}
                        />
                     </Box>
                  </Box>
               )}
            </Grid>
         </Grid>
      </Wrapper>
   );
}
