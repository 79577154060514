import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import DebugOverlay from "../widgets/DebugOverlay";
import WrapperViewer from "../layout/WrapperViewer";

import ScorebarPlayer from "./ScorebarPlayer";
import ScorebarLifetotal from "./ScorebarLifetotal";
import ScorebarGameround from "./ScorebarGameround";
import Bullets from "./Bullets";
import PlayerFlag from "./PlayerFlag";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function PlayerDecklistSelect({
   playerPosition,
   tableId,
   playerData,
   label,
   selected,
   callback,
}) {
   // USE SOCKETS HOOK
   const [
      players,
      allPlayersList,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, refreshFMPhotos, getAllPlayers },
   ] = useFeatureMatchHandler();

   const handleChange = (e) => {
      callback(
         tableId,
         {
            ["PlayerDecklistP" + playerPosition]: e.target.value
               ? e.target.value
               : null,
         },
         true
      );
   };

   return (
      <>
         {playerData && (
            <FormControl fullWidth sx={{ maxWidth: 300 }}>
               <InputLabel id={"player" + playerPosition + "select"}>
                  Decklist
               </InputLabel>
               <Select
                  value={selected}
                  label={"Decklist" + playerPosition}
                  onChange={handleChange}
               >
                  <MenuItem value=''>Hide Decklist</MenuItem>
                  {playerData &&
                     playerData.Decklists[0] != null &&
                     _.compact(
                        _.orderBy(
                           playerData.Decklists,
                           ["Format", "Name"],
                           ["asc", "asc"]
                        )
                     ).map((decklist) => (
                        <MenuItem key={decklist.id} value={decklist.id}>
                           {decklist.Format} | {decklist.Archetype}
                        </MenuItem>
                     ))}
               </Select>
            </FormControl>
         )}
      </>
   );
}
