import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Wrapper from "../components/layout/Wrapper";

//HOOKS
import useTableHistory from "../hooks/useTableHistory";

export default function TableHistory() {
   // USE TABLE HISTORY HOOK
   const [
      tableHistory,
      { getTableHistory, addTableGraphics, deleteTableHistoryRow },
   ] = useTableHistory();

   useEffect(() => {
      getTableHistory();
   }, []);

   useEffect(() => {
      // console.log(tableHistory);
   }, [tableHistory]);

   return (
      <Wrapper key={"tableHistory"}>
         <Typography
            variant='h3'
            component='div'
            sx={{ textAlign: "center", textTransform: "uppercase" }}
         >
            Table History
         </Typography>

         {tableHistory && tableHistory.length > 0 && (
            <Table>
               <TableHead sx={{ background: "#333" }}>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Round / Table
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Best Of
                  </TableCell>
                  {/* <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Actions
                  </TableCell> */}
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Player 1
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Player 2
                  </TableCell>
                  <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>
                     Saved At
                  </TableCell>
                  <TableCell
                     sx={{ color: "#fff", fontWeight: "bold" }}
                  ></TableCell>
               </TableHead>
               <TableBody>
                  {tableHistory.map((row, i) => (
                     <TableRow sx={{ background: "#ccc" }}>
                        <TableCell>
                           <b>
                              {row.title}
                              <br />
                              {row.table}
                           </b>
                        </TableCell>
                        <TableCell>
                           <b>{row.bestOf}</b>
                        </TableCell>
                        {/* <TableCell>
                           <Button
                              variant='contained'
                              color='error'
                              size='small'
                              onClick={() =>
                                 addTableGraphics(
                                    row.player1.id,
                                    row.player2.id
                                 )
                              }
                           >
                              Create H2H /<br />
                              Player Profiles
                           </Button>
                        </TableCell> */}
                        <TableCell>
                           {row.player1 ? row.player1.Name : "No Player"}
                           <br />
                           <i>
                              {row.player1 && row.player1.decklist
                                 ? row.player1.decklist.Archetype +
                                   " | " +
                                   row.player1.decklist.Format
                                 : "No Decklist"}
                           </i>
                        </TableCell>
                        <TableCell>
                           {row.player2 ? row.player2.Name : "No Player"}
                           <br />
                           <i>
                              {row.player2 && row.player2.decklist
                                 ? row.player2.decklist.Archetype +
                                   " | " +
                                   row.player2.decklist.Format
                                 : "No Decklist"}
                           </i>
                        </TableCell>
                        <TableCell>{row.createdAt}</TableCell>
                        <TableCell>
                           <Button
                              variant='contained'
                              color='error'
                              size='small'
                              value={row.id}
                              onClick={deleteTableHistoryRow}
                           >
                              DELETE
                           </Button>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         )}
      </Wrapper>
   );
}
