import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import PoisonImage from "../../images/poison.png";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ScorebarPoison({ playerPosition, poison }) {
   const theme = useTheme();

   return (
      <Box
         sx={{
            position: "absolute",
            left: "61.5%",
            transform: "translateX(-50%)",
            width: "auto",
            top: 0,
            height: "100%",
         }}
      >
         <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ height: "100%" }}
         >
            <AnimatePresence>
               {poison > 0 && (
                  <Typography
                     variant='fmPoison'
                     component='div'
                     key={"p" + playerPosition + "poison"}
                     component={motion.div}
                     initial={{
                        opacity: 0,
                        y: -20,
                     }}
                     animate={{
                        opacity: 1,
                        y: 0,
                     }}
                     exit={{ opacity: 0, y: 0, scale: 1.2 }}
                     transition={{ easing: "easeIn", duration: 0.5 }}
                     sx={{
                        width: "100%",
                        position: "absolute",
                     }}
                  >
                     <Stack
                        direction='row'
                        spacing={2}
                        alignItems='center'
                        justifyContent='flex-end'
                        sx={{ position: "relative" }}
                     >
                        <Box sx={{ height: 60 }}>{poison}</Box>
                        <img src={PoisonImage} height='55' />
                     </Stack>
                  </Typography>
               )}
            </AnimatePresence>
         </Stack>
      </Box>
   );
}
