import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";

import DashboardTableCombined from "../components/featurematch/DashboardTableCombined";
import useLogHandler from "../hooks/useLogHandler";

export default function FeatureMatchAdminCombined() {
   // USE LOG HOOK
   const [log, { subscribeLog }] = useLogHandler();

   useEffect(() => {
      subscribeLog();
   }, []);

   const getLogEntries = (tableId) => {
      return _.filter(log, function (row) {
         return row.tableId === tableId;
      });
   };

   return (
      <Wrapper key={"featureMatchAdmin"}>
         <Typography
            variant='h3'
            component='div'
            sx={{ textAlign: "center", textTransform: "uppercase", my: 2 }}
         >
            Feature Match Admin
         </Typography>

         <Stack spacing={4}>
            <DashboardTableCombined tableId={1} log={getLogEntries(1)} />

            <DashboardTableCombined tableId={2} log={getLogEntries(2)} />

            <DashboardTableCombined tableId={3} log={getLogEntries(3)} />

            <DashboardTableCombined tableId={4} log={getLogEntries(4)} />
         </Stack>
      </Wrapper>
   );
}
