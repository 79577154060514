import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Downloader from "../components/widgets/Downloader";

import Wrapper from "../components/layout/Wrapper";
import PlayerSelect from "../components/top8movingon/PlayerSelect";

import Top8MovingOnViewer from "./viewers/Top8MovingOnViewer";

//HOOKS
import useTop8MovingOn from "../hooks/useTop8MovingOn";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Top8({ parent = "" }) {
   let navigate = useNavigate();

   // USE HOOK
   const [
      allPlayers,
      top8MovingOn,
      top8MovingOnLeaderboard,
      show,
      runTimestamp,
      {
         getAllPlayers,
         getTop8MovingOn,
         getTop8MovingOnLeaderboard,
         update,
         load,
         showHide,
         subscribeTop8MovingOnViewer,
      },
   ] = useTop8MovingOn();

   useEffect(() => {
      getAllPlayers();
      getTop8MovingOn();
   }, []);

   useEffect(() => {
      // console.log(top8MovingOn);
   }, [top8MovingOn]);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // HIDE SHOW
   const handleShowHide = (e) => {
      showHide(e.target.value);
   };

   return (
      <Wrapper>
         <Stack direction='row' sx={{ width: "100%" }} alignItems='center'>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               Top 8 Moving On
            </Typography>

            <Box sx={{}}>
               {top8MovingOn && (
                  <Downloader
                     enabled={true}
                     downloadName={"Top8MovingOn_" + Date.now()}
                  >
                     <Top8MovingOnViewer data={top8MovingOn} />
                  </Downloader>
               )}
            </Box>
         </Stack>
         <Grid container spacing={3} sx={{ mb: 8, maxWidth: 1525 }}>
            <Grid
               item
               xs={parent != "producer" ? 6 : 12}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale + 100, position: "relative" }}
            >
               {parent != "producer" && (
                  <>
                     <Typography
                        variant='h5'
                        component='div'
                        sx={{ textTransform: "uppercase", mb: 2 }}
                     >
                        Top 8 Output
                     </Typography>

                     <Stack direction='row' spacing={2}>
                        <Button
                           variant='contained'
                           color='warning'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"toggle"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           TOGGLE
                        </Button>
                        <Button
                           variant='contained'
                           color='success'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"show"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           SHOW
                        </Button>
                        <Button
                           variant='contained'
                           color='error'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"hide"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           HIDE
                        </Button>
                     </Stack>
                  </>
               )}
               <Box
                  sx={{
                     position: "relative",
                     width: 1920,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                     mt: 2,
                  }}
                  className='transparentBG'
               >
                  <Top8MovingOnViewer data={top8MovingOn} />
               </Box>
            </Grid>
         </Grid>
         {parent != "producer" && (
            <Box
               sx={{
                  position: "relative",
                  pt: 3,
                  mt: 4,
               }}
            >
               <Stack spacing={4} alignItems='flex-start'>
                  <Button
                     variant='outlined'
                     color='warning'
                     sx={{ whiteSpace: "nowrap" }}
                     value={"toggle"}
                     onClick={() => load()}
                     size='small'
                  >
                     REFRESH STANDINGS DATA
                  </Button>
                  {top8MovingOn && allPlayers && (
                     <React.Fragment key={"top8MovingOn"}>
                        {[...Array(8).keys()].map((i) => (
                           <React.Fragment key={"playerSelect" + i}>
                              <PlayerSelect
                                 allPlayers={allPlayers}
                                 selected={
                                    top8MovingOn[i + 1]
                                       ? top8MovingOn[i + 1]
                                       : ""
                                 }
                                 slot={i + 1}
                                 callback={update}
                              />
                           </React.Fragment>
                        ))}
                     </React.Fragment>
                  )}
               </Stack>
            </Box>
         )}
         )}
      </Wrapper>
   );
}
