import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";
import StandingsSelect from "../components/playerprofiles/StandingsSelect";
import TableHistoryModal from "../components/widgets/TableHistoryModal";

import DeleteIcon from "@mui/icons-material/Delete";
import NotificationWidget from "../components/widgets/NotificationWidget";

import PlayerProfileViewer from "./viewers/PlayerProfileViewer";

//HOOKS
import usePlayerProfiles from "../hooks/usePlayerProfiles";

export default function PlayerProfiles({ parent = "" }) {
   let navigate = useNavigate();

   const [
      notification,
      allPlayerProfiles,
      playerProfile,
      show,
      runTimestamp,
      {
         update,
         getPlayerProfile,
         deletePlayerProfile,
         getAllPlayerProfiles,
         addPlayerProfile,
         playerProfileViewerLoad,
         playerProfileViewerShowHide,
      },
   ] = usePlayerProfiles();

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      getAllPlayerProfiles();
   }, []);

   useEffect(() => {
      // console.log(allPlayerProfiles);
   }, [allPlayerProfiles]);

   const handleLoadSlide = (e) => {
      playerProfileViewerLoad(e.target.name, e.target.id);
   };

   const handleShowHide = (e) => {
      playerProfileViewerShowHide(e.target.name, e.target.value);
   };

   const handleChangeStandings = (id, e) => {
      update(id, { [e.id]: e.value });
   };

   const handleDelete = (id, title) => {
      if (id) {
         if (
            window.confirm("Are you sure you want to delete '" + title + "'?")
         ) {
            deletePlayerProfile(id);
         }
      }
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Player Profile Slides
         </Typography>

         {parent != "producer" && (
            <Grid container spacing={3} sx={{ mb: 8, maxWidth: 1525 }}>
               <Grid
                  item
                  xs={6}
                  ref={columnWidth}
                  sx={{
                     height: 1080 * iframeScale + 100,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mb: 2 }}
                  >
                     Player Profile Output 1
                  </Typography>

                  <Button
                     variant='contained'
                     color='warning'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={1}
                     value={"toggle"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     TOGGLE
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={1}
                     value={"show"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     SHOW
                  </Button>
                  <Button
                     variant='contained'
                     color='error'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={1}
                     value={"hide"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     HIDE
                  </Button>

                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                        mt: 2,
                     }}
                     className='transparentBG'
                  >
                     <PlayerProfileViewer
                        data={playerProfile}
                        show={true}
                        previewOutput={1}
                     />
                  </Box>
               </Grid>
               <Grid
                  item
                  xs={6}
                  sx={{
                     height: 1080 * iframeScale + 100,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mt: 4, mb: 2 }}
                  >
                     Player Profile Output 2
                  </Typography>

                  <Button
                     variant='contained'
                     color='warning'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={2}
                     value={"toggle"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     TOGGLE
                  </Button>
                  <Button
                     variant='contained'
                     color='success'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={2}
                     value={"show"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     SHOW
                  </Button>
                  <Button
                     variant='contained'
                     color='error'
                     sx={{ mr: 2, whiteSpace: "nowrap", px: 4 }}
                     name={2}
                     value={"hide"}
                     onClick={handleShowHide}
                     size='small'
                  >
                     HIDE
                  </Button>
                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                        mt: 2,
                     }}
                     className='transparentBG'
                  >
                     <PlayerProfileViewer
                        data={playerProfile}
                        show={true}
                        previewOutput={2}
                     />
                  </Box>
               </Grid>
            </Grid>
         )}

         {allPlayerProfiles && (
            <Table variant='dashboard' sx={{ maxWidth: 1500, mt: 3 }}>
               <TableHead>
                  <TableRow>
                     <TableCell>ID</TableCell>
                     <TableCell>Player</TableCell>
                     <TableCell>Data Points</TableCell>
                     <TableCell>Decklist</TableCell>
                     <TableCell>Bulletpoints</TableCell>
                     <TableCell>Record / Rank</TableCell>
                     <TableCell align='right' width='400'>
                        {parent != "producer" && (
                           <Button
                              onClick={addPlayerProfile}
                              variant='contained'
                              color='error'
                           >
                              + ADD NEW
                           </Button>
                        )}

                        <TableHistoryModal callback={getAllPlayerProfiles} />
                     </TableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {allPlayerProfiles.map((row) => (
                     <TableRow key={"slideRow" + row.id}>
                        <TableCell width='80'>{row.id}</TableCell>
                        <TableCell>
                           {row.Player ? row.Player.PlayerName : "-NONE-"}
                        </TableCell>
                        <TableCell>
                           {row.DataFields &&
                              row.DataFields.data.map((field, i) => (
                                 <React.Fragment
                                    key={"datfield-" + field + "-" + i}
                                 >
                                    {field.title && (
                                       <>
                                          {i + 1}: {field.title.toUpperCase()}
                                       </>
                                    )}
                                    <br />
                                 </React.Fragment>
                              ))}
                        </TableCell>
                        <TableCell>
                           {row.PlayerDecklist && (
                              <>{row.PlayerDecklist.Archetype}</>
                           )}
                        </TableCell>
                        <TableCell>
                           {row.DataFields &&
                              row.DataFields.bulletpoints.map((field, i) => (
                                 <React.Fragment
                                    key={"bulletpoint-" + field + "-" + i}
                                 >
                                    {field.title && (
                                       <>{field.title.toUpperCase()}</>
                                    )}
                                    <br />
                                 </React.Fragment>
                              ))}
                        </TableCell>
                        <TableCell>
                           <StandingsSelect
                              selected={row.StandingsId ? row.StandingsId : 0}
                              callback={(e) => handleChangeStandings(row.id, e)}
                           />
                        </TableCell>
                        <TableCell align='right'>
                           <Stack direction='row' spacing={1}>
                              {parent != "producer" && (
                                 <>
                                    <Button
                                       variant='outlined'
                                       color='success'
                                       sx={{ whiteSpace: "nowrap" }}
                                       fullWidth
                                       id={row.id}
                                       name={1}
                                       onClick={handleLoadSlide}
                                    >
                                       LOAD 1
                                    </Button>

                                    <Button
                                       variant='outlined'
                                       color='success'
                                       sx={{ whiteSpace: "nowrap" }}
                                       fullWidth
                                       id={row.id}
                                       name={2}
                                       onClick={handleLoadSlide}
                                    >
                                       LOAD 2
                                    </Button>
                                 </>
                              )}
                              <Button
                                 onClick={() =>
                                    navigate(
                                       "/" + parent + "/playerprofile/" + row.id
                                    )
                                 }
                                 variant='outlined'
                                 color='error'
                                 fullWidth
                              >
                                 {parent != "producer" ? "EDIT" : "PREVIEW"}
                              </Button>
                              {parent != "producer" && (
                                 <Button
                                    variant='outlined'
                                    color='error'
                                    fullWidth
                                    onClick={() =>
                                       handleDelete(
                                          row.id,
                                          row.Player
                                             ? row.Player.PlayerName
                                             : "Slide " + row.id
                                       )
                                    }
                                 >
                                    <DeleteIcon />
                                 </Button>
                              )}
                           </Stack>
                        </TableCell>
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         )}

         {notification && (
            <NotificationWidget
               notification={notification.message}
               key={"notification" + notification.date}
            />
         )}
      </Wrapper>
   );
}
