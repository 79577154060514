import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function FeatureCardsViewer({ activeData, show, preview }) {
   return (
      <WrapperViewer>
         <AnimatePresence>
            {show && activeData && (
               <Box
                  sx={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     width: 1920,
                     height: 1080,
                     textAlign: "center",
                     background: activeData.layout.layoutImage
                        ? "url(" +
                          SERVER_URL +
                          "/images/lowerthirds/" +
                          activeData.layout.layoutImage +
                          ") top left no-repeat"
                        : "none",
                  }}
                  key={activeData.id}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{
                     opacity: 1,
                     transition: { delay: 0.5 },
                  }}
                  exit={{
                     opacity: 0,
                  }}
                  transition={{
                     easing: "easeOut",
                     duration: preview ? 0 : 0.5,
                  }}
               >
                  <Stack
                     sx={{
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                        zIndex: 100,
                        color: "#000",
                        ...activeData.layout.style,
                     }}
                     direction='row'
                     justifyContent='space-between'
                     alignItems='center'
                  >
                     {[...Array(activeData.layout.nameCount).keys()].map(
                        (nameIndex) => (
                           <Stack
                              sx={{
                                 width: activeData.layout.nameContainerWidth
                                    ? activeData.layout.nameContainerWidth
                                    : "auto",
                              }}
                           >
                              {activeData["custom" + (nameIndex + 1)] && (
                                 <>
                                    <Box
                                       className='lowerThirdTitle'
                                       sx={{
                                          textTransform:
                                             activeData.titleUppercase == 1
                                                ? "uppercase"
                                                : "inherit",
                                       }}
                                    >
                                       {
                                          activeData["custom" + (nameIndex + 1)]
                                             .title
                                       }
                                    </Box>
                                    {activeData.showDescription && (
                                       <Box
                                          className='lowerThirdDescription'
                                          sx={{
                                             textTransform:
                                                activeData.descriptionUppercase ==
                                                1
                                                   ? "uppercase"
                                                   : "inherit",
                                          }}
                                       >
                                          {
                                             activeData[
                                                "custom" + (nameIndex + 1)
                                             ].description
                                          }
                                          &nbsp;
                                       </Box>
                                    )}
                                 </>
                              )}
                              {activeData["player" + (nameIndex + 1)] && (
                                 <>
                                    {activeData.layout.id == 4 && (
                                       <Stack>
                                          <Box
                                             className='lowerThirdTitle'
                                             sx={{
                                                lineHeight: ".9em",
                                                textTransform:
                                                   activeData.titleUppercase ==
                                                   1
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             <Box sx={{ fontSize: ".8em" }}>
                                                {
                                                   activeData[
                                                      "player" + (nameIndex + 1)
                                                   ].first
                                                }
                                             </Box>
                                             <Box sx={{ fontSize: "1em" }}>
                                                {
                                                   activeData[
                                                      "player" + (nameIndex + 1)
                                                   ].last
                                                }
                                             </Box>
                                          </Box>
                                       </Stack>
                                    )}

                                    {activeData.layout.id != 4 && (
                                       <>
                                          <Box
                                             className='lowerThirdTitle'
                                             sx={{
                                                textTransform:
                                                   activeData.titleUppercase ==
                                                   1
                                                      ? "uppercase"
                                                      : "inherit",
                                             }}
                                          >
                                             {
                                                activeData[
                                                   "player" + (nameIndex + 1)
                                                ].first
                                             }{" "}
                                             {
                                                activeData[
                                                   "player" + (nameIndex + 1)
                                                ].last
                                             }
                                          </Box>
                                          {activeData.showDescription && (
                                             <Box
                                                className='lowerThirdDescription'
                                                sx={{
                                                   textTransform:
                                                      activeData.descriptionUppercase ==
                                                      1
                                                         ? "uppercase"
                                                         : "inherit",
                                                }}
                                             >
                                                {
                                                   activeData[
                                                      "player" + (nameIndex + 1)
                                                   ].twitterHandle
                                                }
                                                &nbsp;
                                             </Box>
                                          )}
                                       </>
                                    )}
                                 </>
                              )}
                           </Stack>
                        )
                     )}
                  </Stack>
               </Box>
            )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
