import { useState, forwardRef, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import LoaderIcon from "./LoaderIconInline";

export default function NotificationWidget({ notification }) {
   const [open, setOpen] = useState(false);

   useEffect(() => {
      if (notification) {
         setOpen(true);
      }
   }, [notification]);

   const handleClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }

      setOpen(false);
   };

   return (
      <Snackbar
         open={open}
         autoHideDuration={3000}
         onClose={handleClose}
         anchorOrigin={{
            vertical: "top",
            horizontal: "right",
         }}
      >
         <Alert
            onClose={handleClose}
            sx={{ backgroundColor: "#222", color: "#fff" }}
            severity='warning'
            icon={
               notification === "loading" ? (
                  <LoaderIcon size='small' style={{ marginRight: 2 }} />
               ) : (
                  <WarningAmberIcon fontSize='inherit' />
               )
            }
         >
            {notification !== "loading" && (
               <>
                  <AlertTitle>Alert</AlertTitle>
                  {notification}
               </>
            )}
         </Alert>
      </Snackbar>
   );
}
