import { useState, useMemo, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useGraphicsOverlay() {
   const socket = useContext(SocketContext);

   const [loaded, setLoaded] = useState(false);
   const [data, setData] = useState();
   const [visible, setVisible] = useState(false);
   const [visibleOpeningHand, setVisibleOpeningHand] = useState(false);
   const [openingHand, setOpeningHand] = useState({ p2: 7, p1: 7 });
   const [rotation, setRotation] = useState(0);

   const [playerId1, setPlayerId1] = useState();
   const [playerId2, setPlayerId2] = useState();
   const [player1Decklists, setPlayer1Decklists] = useState();
   const [player2Decklists, setPlayer2Decklists] = useState();

   const openingHandRef = useRef(openingHand);

   useEffect(() => {
      openingHandRef.current = openingHand;
   }, [openingHand]);

   const handlers = useMemo(
      () => ({
         getDecklistsByPlayerId: (playerPosition, playerIds) => {
            socket.emit("getDecklistsByPlayerId", playerIds, (response) => {
               if (playerPosition === 1) {
                  setPlayerId1(playerIds.playerId);
                  setPlayer1Decklists(response);
               }
               if (playerPosition === 2) {
                  setPlayerId2(playerIds.playerId);
                  setPlayer2Decklists(response);
               }
            });
         },
         toggleVisibility: (visibility) => {
            socket.emit("toggleVisibility", visibility);
         },
         toggleOpeningHandVisibility: (visibility) => {
            socket.emit("updateOpeningHand", openingHandRef.current);
            socket.emit("toggleOpeningHandVisibility", visibility);
         },
         toggleRotation: (rotation) => {
            socket.emit("toggleRotation", rotation);
         },
         updateOpeningHand: (data) => {
            socket.emit("updateOpeningHand", data);
         },
         overlayDataLoad: (data) => {
            handlers.toggleRotation(0);

            if (typeof data !== "object") {
               data = JSON.parse(data);
            }
            socket.emit("overlayDataLoad", data);
         },
         subscribeOverlay: () => {
            socket.emit("subscribeOverlay", (response) => {
               // console.log(response);
            });

            socket.on("overlayDataLoad", (data) => {
               setData(data);
            });

            socket.on("toggleVisibility", (action) => {
               switch (action) {
                  case "show":
                     setVisible(true);
                     break;
                  case "hide":
                     setVisible(false);
                     break;
                  case "toggle":
                     setVisible((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });

            socket.on("updateOpeningHand", (data) => {
               setOpeningHand((prevState) => {
                  let newState = { ...prevState, ...data };
                  return newState;
               });
            });

            socket.on("toggleOpeningHandVisibility", (visibility) => {
               setVisibleOpeningHand(visibility);
            });

            socket.on("toggleRotation", (rotation) => {
               setRotation(rotation);
            });
         },
      }),
      [socket]
   );

   return [
      loaded,
      setLoaded,
      data,
      visible,
      visibleOpeningHand,
      openingHand,
      rotation,
      playerId1,
      playerId2,
      player1Decklists,
      player2Decklists,
      handlers,
   ];
}
