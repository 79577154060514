import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

import CustomLeaderboardPreview from "./CustomLeaderboardPreview";

//HOOKS
import useCustomLeaderboard from "../../hooks/useCustomLeaderboard";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CustomLeaderboardViewer() {
   // USE HOOK
   const [
      notification,
      all,
      activeData,
      show,
      runTimestamp,
      allPlayers,
      currentPage,
      { subscribeViewer },
   ] = useCustomLeaderboard();

   useEffect(() => {
      subscribeViewer();
   }, []);

   return (
      <CustomLeaderboardPreview
         currentPage={currentPage}
         activeData={activeData}
         show={show}
         key={runTimestamp}
      />
   );
}
