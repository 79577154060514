import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

export default function SideboardView({ cards }) {
   const theme = useTheme();

   return (
      <AnimatePresence>
         {cards &&
            cards.map((row, i) => (
               <Box
                  layout
                  component={motion.div}
                  sx={{
                     position: "relative",
                     fontFamily: "GothamNarrow-Bold",
                     px: 1.2,
                     pt: 0.85,
                     pb: 0.6,
                     mb: 0.4,
                     fontSize: "1rem",
                     lineHeight: "1rem",
                     color: theme.palette.text.white,
                     backgroundColor: "#1f1f1f",
                  }}
                  key={"sideboardViewer"}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ layout: { duration: 0.4 } }}
               >
                  <Stack direction='row' spacing={1} alignItems='center'>
                     <Box
                        sx={{
                           whiteSpace: "nowrap",
                           width: "100%",
                           overflow: "hidden",
                           maskImage:
                              "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)",
                           maskRepeat: "no-repeat",
                        }}
                     >
                        {row.CardName}
                     </Box>

                     <Box
                        sx={{
                           position: "absolute",
                           right: 5,
                           zIndex: 10,
                           whiteSpace: "nowrap",
                           overflow: "hidden",
                           maskImage:
                              "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 25px, rgba(0,0,0,1) 100%)",
                           maskRepeat: "no-repeat",
                           backgroundColor: "#1f1f1f",
                           pl: "25px",
                        }}
                     >
                        <ManaIconsFormatted
                           iconWidth={15}
                           mana={row.CardManaCost}
                        />
                     </Box>
                  </Stack>
               </Box>
            ))}
      </AnimatePresence>
   );
}
