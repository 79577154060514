import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import UrlToCopy from "../components/widgets/UrlToCopy";
import TextInput from "../components/inputs/TextInput";

import PlayerSelect from "../components/ledversus/PlayerSelect";
import PlayerDeckSelect from "../components/ledversus/PlayerDeckSelect";

import LEDVersusViewer from "./viewers/LEDVersusViewer";

//HOOKS
import useLEDVersus from "../hooks/useLEDVersus";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function LEDVersus({ parent = "" }) {
   let navigate = useNavigate();

   // USE HOOK
   const [
      title,
      allPlayers,
      selectedPlayers,
      show,
      {
         updateTitle,
         getAllPlayers,
         getSelectedPlayers,
         update,
         updateDeck,
         showStandings,
         hideStandings,
         showHide,
         subscribeLEDVersusViewer,
      },
   ] = useLEDVersus();

   useEffect(() => {
      subscribeLEDVersusViewer();
      getAllPlayers();
      getSelectedPlayers();
   }, []);

   useEffect(() => {
      // console.log(selectedPlayers);
   }, [selectedPlayers]);

   const columnWidth = useRef();
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         2160;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // HIDE SHOW
   const handleShowHide = (e) => {
      showHide(e.target.value);
   };

   const findPlayerID = (slot, players) => {
      const player = _.find(players, function (obj) {
         if (obj.id == slot) {
            return true;
         }
      });

      return player ? player.playerId : "";
   };

   return (
      <>
         <Grid container spacing={3} sx={{ maxWidth: 1525 }}>
            <Grid
               item
               xs={parent != "producer" ? 8 : 12}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale + 100, position: "relative" }}
            >
               {parent != "producer" && (
                  <>
                     <Stack direction='row' spacing={2}>
                        <Button
                           variant='contained'
                           color='warning'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"toggle"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           TOGGLE
                        </Button>
                        <Button
                           variant='contained'
                           color='success'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"show"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           SHOW
                        </Button>
                        <Button
                           variant='contained'
                           color='error'
                           sx={{ whiteSpace: "nowrap", px: 4 }}
                           value={"hide"}
                           onClick={handleShowHide}
                           size='small'
                        >
                           HIDE
                        </Button>
                     </Stack>
                  </>
               )}
               <Box
                  sx={{
                     position: "relative",
                     width: 2160,
                     height: 1080,
                     transform: "scale(" + iframeScale + ")",
                     transformOrigin: "top left",
                     overflow: "hidden",
                     border: "1px solid #333",
                     mt: 2,
                  }}
                  className='transparentBG'
               >
                  <LEDVersusViewer data={selectedPlayers} />
               </Box>
            </Grid>
         </Grid>

         <UrlToCopy title='Viewer URL' path='/viewer/ledversus' />

         {parent != "producer" && (
            <Box
               sx={{
                  width: "70%",
                  position: "relative",
                  pt: 3,
                  mt: 4,
               }}
            >
               <Box sx={{ p: 1, backgroundColor: "#222", mb: 3, width: 300 }}>
                  <TextInput
                     color='rgba(255,255,255,.7)'
                     value={title ? title : ""}
                     callback={(value) => updateTitle("LEDVersusTitle", value)}
                  />
               </Box>

               {selectedPlayers && allPlayers && (
                  <Stack direction='row' sx={{ width: "73%" }} spacing={4}>
                     {[...Array(2).keys()].map((i) => (
                        <Stack sx={{ width: "100%" }}>
                           <Typography
                              variant='h5'
                              sx={{
                                 textTransform: "uppercase",
                                 textAlign: "center",
                                 lineHeight: "1em",
                                 mb: 1,
                              }}
                           >
                              Player {i + 1}
                           </Typography>
                           <PlayerSelect
                              allPlayers={allPlayers}
                              selected={findPlayerID(i + 1, selectedPlayers)}
                              slot={i + 1}
                              callback={update}
                           />

                           {selectedPlayers[i] &&
                              selectedPlayers[i].showResults == false && (
                                 <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={() =>
                                       showStandings(selectedPlayers[i].id)
                                    }
                                 >
                                    SHOW STANDINGS
                                 </Button>
                              )}

                           {selectedPlayers[i] &&
                              selectedPlayers[i].showResults == true && (
                                 <Button
                                    variant='contained'
                                    color='error'
                                    onClick={() =>
                                       hideStandings(selectedPlayers[i].id)
                                    }
                                 >
                                    HIDE STANDINGS
                                 </Button>
                              )}

                           <PlayerDeckSelect
                              selected={selectedPlayers[i]}
                              slot={i + 1}
                              callback={updateDeck}
                           />
                        </Stack>
                     ))}
                  </Stack>
               )}
            </Box>
         )}
      </>
   );
}
