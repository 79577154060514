import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useLEDVersus() {
   const socket = useContext(SocketContext);

   const [title, setTitle] = useState("");
   const [allPlayers, setAllPlayers] = useState([]);
   const [selectedPlayers, setSelectedPlayers] = useState([]);
   const [show, setShow] = useState(true);

   const handlers = useMemo(
      () => ({
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               if (response) {
                  setAllPlayers(response);
               }
            });
         },
         getSelectedPlayers: () => {
            socket.emit("getLEDVersus", (response) => {
               if (response) {
                  setTitle(response.title);
                  setSelectedPlayers(response.players);
               }
            });
         },
         updateTitle: (id, data) => {
            socket.emit("updateGlobal", id, data);
         },
         update: (id, playerId) => {
            socket.emit("updateLEDVersus", {
               id: id,
               playerId: playerId,
               decklistId: 0,
               showResults: false,
            });
         },
         updateDeck: (id, decklistId) => {
            socket.emit("updateLEDVersus", { id: id, decklistId: decklistId });
         },
         showStandings: (id) => {
            socket.emit("updateLEDVersus", {
               id: id,
               showResults: true,
            });
         },
         hideStandings: (id) => {
            socket.emit("updateLEDVersus", {
               id: id,
               showResults: false,
            });
         },
         load: () => {
            socket.emit("LEDVersusViewerLoad");
         },
         showHide: (action) => {
            socket.emit("LEDVersusViewerShowHide", action);
         },
         subscribeLEDVersusViewer: () => {
            socket.emit("subscribeLEDVersusViewer", (response) => {
               console.log(response);
            });

            socket.on("LEDVersusViewerLoad", (players) => {
               handlers.getSelectedPlayers();
            });

            socket.on("LEDVersusViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [title, allPlayers, selectedPlayers, show, handlers];
}
