import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import Keys from "../components/lowerthirds/Keys";
import Names from "../components/lowerthirds/Names";

import TextInput from "../components/inputs/TextInput";
import SelectLowerThirdName from "../components/inputs/SelectLowerThirdName";
import SelectInput from "../components/inputs/SelectInput";

import Wrapper from "../components/layout/Wrapper";

import LowerThirdsPreview from "./viewers/LowerThirdsPreview";
import LowerThirdsViewer from "./viewers/LowerThirdsViewer";

//HOOKS
import useLowerThirds from "../hooks/useLowerThirds";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "90%",
   overflowY: "auto",
   maxWidth: 1200,
   bgcolor: "#333",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function LowerThirds() {
   let navigate = useNavigate();

   // MODAL
   const [open, setOpen] = useState(false);
   const handleOpen = () => setOpen(true);
   const handleClose = () => setOpen(false);

   const [cardParent, setCardParent] = useState();

   // USE HOOK
   const [
      entries,
      allCustomNames,
      allPlayerNames,
      activeData,
      show,
      {
         viewerLoad,
         viewerShowHide,
         getEntries,
         addEntry,
         deleteEntry,
         updateEntry,
         addCard,
         deleteCard,
         addName,
         updateName,
         deleteName,
         subscribeViewer,
      },
   ] = useLowerThirds();

   useEffect(() => {
      subscribeViewer();
      getEntries();
   }, []);

   const columnWidth = useRef();

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   const previewHeight =
      columnWidth.current &&
      parseInt(window.getComputedStyle(columnWidth.current).height) + 100;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   const editNames = () => {
      setOpen(true);
   };

   const handleAddCard = (entryId, card) => {
      setCardParent();

      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      let cards = [
         ...entryRow.cards,
         {
            id: card.ID,
            CardName: card.CardName,
            FileName: card.FileName,
            featured: false,
            order: entryRow.cards.length + 1,
         },
      ];

      updateEntry(entryId, { cards: cards });
   };

   const handleDeleteCard = (entryId, cardId) => {
      const entryRow = _.find(entries, (entry) => {
         if (entry.id === entryId) {
            return true;
         }
      });

      let cards = _.filter(entryRow.cards, function (x) {
         return x.id !== cardId;
      });

      updateEntry(entryId, { cards: cards });
   };

   const handleLoadSlide = (e) => {
      viewerLoad(e.target.id);
   };

   const handleShowHide = (e) => {
      viewerShowHide(e.target.value);
   };

   return (
      <>
         <Wrapper>
            <Stack
               spacing={3}
               alignItems='flex-start'
               sx={{ mb: 2, height: previewHeight }}
            >
               <Stack direction='row' alignItems='center'>
                  <Typography
                     variant='h3'
                     component='div'
                     sx={{ width: "100%" }}
                  >
                     Lower Thirds
                  </Typography>
               </Stack>

               <Stack direction='row' sx={{ width: "100%", pb: 5 }} spacing={4}>
                  <Box
                     item
                     width='30%'
                     ref={columnWidth}
                     sx={{
                        height: 1080 * iframeScale,
                        position: "relative",
                     }}
                  >
                     <Typography variant='h5'>Output 1</Typography>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           border: "1px solid #333",
                        }}
                        className='transparentBG'
                     >
                        <LowerThirdsViewer outputOverride={1} />
                     </Box>
                  </Box>
                  <Box
                     item
                     width='30%'
                     sx={{
                        height: 1080 * iframeScale,
                        position: "relative",
                     }}
                  >
                     <Typography variant='h5'>Output 2</Typography>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           border: "1px solid #333",
                        }}
                        className='transparentBG'
                     >
                        <LowerThirdsViewer outputOverride={2} />
                     </Box>
                  </Box>
                  <Box
                     item
                     width='30%'
                     sx={{
                        height: 1080 * iframeScale,
                        position: "relative",
                     }}
                  >
                     <Typography variant='h5'>Output 3</Typography>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           border: "1px solid #333",
                        }}
                        className='transparentBG'
                     >
                        <LowerThirdsViewer outputOverride={3} />
                     </Box>
                  </Box>
               </Stack>
            </Stack>

            <Box
               sx={{
                  height: window.innerHeight - previewHeight - 150,
                  overflowY: "auto",
               }}
            >
               <Keys
                  entries={entries}
                  addEntry={addEntry}
                  updateEntry={updateEntry}
                  deleteEntry={deleteEntry}
                  viewerLoad={viewerLoad}
                  allCustomNames={allCustomNames}
                  allPlayerNames={allPlayerNames}
                  actions={{ editNames }}
               />
            </Box>
         </Wrapper>

         <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
               <Button
                  color='secondary'
                  sx={{ position: "absolute", top: 10, right: 10 }}
                  onClick={handleClose}
               >
                  <CloseIcon />
               </Button>
               <Typography sx={{ mt: 2 }}>
                  <Names
                     allCustomNames={allCustomNames}
                     addName={addName}
                     updateName={updateName}
                     deleteName={deleteName}
                  />
               </Typography>
            </Box>
         </Modal>
      </>
   );
}
