import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function AdjustValue({
   label,
   tableId,
   property,
   amounts,
   maxValue,
   value,
   resetAmount,
   hideReset,
   callback,
   force = false,
}) {
   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, getAllPlayers },
   ] = useFeatureMatchHandler();

   const decrement = (amount) => {
      if (property === "WinsP1" || property === "WinsP2") {
         callback(tableId, { ["LifeTotalP1"]: 20 });
         callback(tableId, { ["LifeTotalP2"]: 20 });
         callback(tableId, { ["PoisonP1"]: 0 });
         callback(tableId, { ["PoisonP2"]: 0 });
         callback(tableId, { ["MulliganP1"]: 0 });
         callback(tableId, { ["MulliganP2"]: 0 });
      }

      callback(tableId, { [property]: Math.max(value - amount, 0) }, force);
   };

   const increment = (amount) => {
      if (property === "WinsP1" || property === "WinsP2") {
         callback(tableId, { ["LifeTotalP1"]: 20 });
         callback(tableId, { ["LifeTotalP2"]: 20 });
         callback(tableId, { ["PoisonP1"]: 0 });
         callback(tableId, { ["PoisonP2"]: 0 });
         callback(tableId, { ["MulliganP1"]: 0 });
         callback(tableId, { ["MulliganP2"]: 0 });
      }

      callback(
         tableId,
         {
            [property]: maxValue
               ? Math.min(Math.ceil(maxValue / 2), value + amount)
               : value + amount,
         },
         force
      );
   };

   const reset = (amount) => {
      callback(tableId, { [property]: resetAmount });
   };

   const ascending = (a, b) => {
      return a - b;
   };

   const descending = (a, b) => {
      return b - a;
   };

   return (
      <Stack spacing={0} sx={{ mt: 1 }} alignItems='center'>
         <Typography
            variant='h5'
            color='text.dark'
            component='div'
            sx={{ mb: 2 }}
         >
            {label}
         </Typography>
         <Stack direction='row' spacing={1}>
            <Stack direction='row' spacing={1}>
               {amounts.sort(descending).map((amount) => (
                  <Button
                     key={"decrement" + amount}
                     variant='outlined'
                     onClick={() => decrement(amount)}
                  >
                     -{amount}
                  </Button>
               ))}
            </Stack>

            <Typography
               variant='h3'
               color='text.primary'
               component='div'
               sx={{ mb: 2, width: 100 }}
            >
               {value}
            </Typography>

            <Stack direction='row' spacing={1}>
               {amounts.sort(ascending).map((amount) => (
                  <Button
                     key={"increment" + amount}
                     variant='outlined'
                     onClick={() => increment(amount)}
                  >
                     +{amount}
                  </Button>
               ))}
            </Stack>
         </Stack>
         {!hideReset && (
            <Button
               size='small'
               variant='outlined'
               color='alert'
               onClick={() => reset()}
               sx={{ mt: 1 }}
            >
               RESET
            </Button>
         )}
      </Stack>
   );
}
