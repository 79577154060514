import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DebugOverlay from "../widgets/DebugOverlay";
import WrapperViewer from "../layout/WrapperViewer";

import PlayerSelect from "./PlayerSelect";
import PlayerDecklistSelect from "./PlayerDecklistSelect";
import AdjustRoundTitle from "./AdjustRoundTitle";

import AdjustValue from "./AdjustValue";
import BestOfValue from "./BestOfValueHorizontal";

import FeatureMatchViewer from "../../routes/viewers/FeatureMatch";
import FeatureMatchOverlay from "../../routes/viewers/FeatureMatchOverlay";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   width: "80%",
   transform: "translate(-50%, -50%)",
   bgcolor: "#151515",
   border: "2px solid #333",
   boxShadow: 24,
   p: 4,
   textAlign: "center",
};

export default function DashboardTableCombined({ tableId, log }) {
   const columnWidth = useRef();
   const [previewOverlay, setPreviewOverlay] = useState(false);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      {
         subscribeFeatureMatch,
         refreshFMPhotos,
         getAllPlayers,
         saveUpdates,
         saveTableState,
         cancelUpdates,
      },
   ] = useFeatureMatchHandler();

   // MODAL
   const [time, setTime] = useState(Date.now());
   const [openRoundTitle, setOpenRoundTitle] = useState(false);
   const handleOpenRoundTitle = () => setOpenRoundTitle(true);
   const handleCloseRoundTitle = () => setOpenRoundTitle(false);

   useEffect(() => {
      subscribeFeatureMatch(tableId);
      getAllPlayers();

      const interval = setInterval(() => setTime(Date.now()), 10000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   useEffect(() => {
      // console.log(allPlayers);
   }, [allPlayers]);

   useEffect(() => {
      // console.log(players);
   }, [players]);

   const handleTextfieldKeyPress = (e) => {
      if (e.keyCode === 13) {
         e.target.blur();
      }
   };

   const updateRoundTitle = (value) => {
      update(tableId, { ["RoundTitle"]: value }, true);
      handleCloseRoundTitle();
   };

   const handleResetAll = () => {
      update(
         tableId,
         {
            ["LifeTotalP1"]: 20,
            ["LifeTotalP2"]: 20,
            ["PoisonP1"]: 0,
            ["PoisonP2"]: 0,
         },
         true
      );
   };

   const timeSinceUpdate = (lastUpdate) => {
      let date1 = new Date(lastUpdate);
      let date2 = new Date(time);
      let difference = date2.getTime() - date1.getTime();

      const secondsAgo = Math.round(difference / 1000);
      const minutesAgo = Math.round(difference / 60000);

      return secondsAgo >= 60
         ? minutesAgo + " minute" + (minutesAgo > 1 ? "s ago" : "")
         : "less than a minute ago";
   };

   const saveTable = (tableId) => {
      const tableData = {
         table: "Table " + ["A", "B", "C", "D"][tableId - 1],
         title: gameState.RoundTitle,
         bestOf: gameState.BestOf,
         player1id: gameState.PlayerIdP1,
         player1decklist: gameState.PlayerDecklistP1,
         player2id: gameState.PlayerIdP2,
         player2decklist: gameState.PlayerDecklistP2,
      };
      saveTableState(tableData);
   };

   useEffect(() => {
      // console.log(log);
   }, [log]);

   return (
      <>
         <Stack
            direction='row'
            align='center'
            sx={{
               backgroundColor: saveRequired ? "#f00" : "#000",
               border: "2px solid #333",
            }}
         >
            <Typography
               variant='h3'
               color='text.white'
               component='div'
               sx={{
                  p: 2,
               }}
            >
               <Stack sx={{ height: "100%" }} justifyContent='center'>
                  FM {["A", "B", "C", "D"][tableId - 1]}
               </Stack>
            </Typography>

            <Stack
               sx={{
                  backgroundColor: saveRequired ? "#ffc5c6" : "#ccc",
                  width: "100%",
               }}
            >
               <Stack
                  direction='row'
                  spacing={2}
                  sx={{
                     py: 2,
                     px: 4,
                     width: "100%",
                     backgroundColor: "#333",
                     position: "relative",
                  }}
               >
                  <Button
                     onClick={handleOpenRoundTitle}
                     variant='outlined'
                     color='warning'
                     size='small'
                  >
                     Update Round Title
                  </Button>

                  <Button
                     sx={{ borderRadius: 0 }}
                     variant='outlined'
                     color='error'
                     onClick={handleResetAll}
                     size='small'
                  >
                     RESET SCOREBAR
                  </Button>

                  <Button
                     variant='outlined'
                     color='secondary'
                     onClick={() => refreshFMPhotos(tableId)}
                     size='small'
                  >
                     REFRESH PLAYER DATA
                  </Button>

                  {gameState && (
                     <BestOfValue
                        label='Best Of'
                        tableId={tableId}
                        property='BestOf'
                        value={gameState.BestOf}
                        amounts={[3, 5, 7, 9]}
                        callback={update}
                     />
                  )}

                  <Stack
                     direction='row'
                     spacing={2}
                     alignItems='center'
                     justifyContent='flex-end'
                     sx={{ position: "absolute", right: 30 }}
                  >
                     {saveRequired && (
                        <>
                           <Button
                              variant='contained'
                              color='secondary'
                              onClick={() => cancelUpdates(tableId)}
                              size='small'
                           >
                              CANCEL
                           </Button>
                           <Button
                              variant='contained'
                              color='error'
                              onClick={() => saveUpdates(tableId)}
                              size='small'
                           >
                              SAVE SCORE UPDATES
                           </Button>
                        </>
                     )}
                  </Stack>
               </Stack>

               {needsSave && (
                  <Button
                     variant='contained'
                     color='error'
                     onClick={() => saveTable(tableId)}
                     size='small'
                     sx={{
                        m: 1,
                     }}
                  >
                     SAVE TABLE HISTORY
                  </Button>
               )}

               {gameState && (
                  <Stack
                     direction='row'
                     spacing={2}
                     justifyContent='flex-start'
                     sx={{
                        p: 3,
                        width: "100%",
                        backgroundColor: needsSave ? "#ffdcdd" : "auto",
                     }}
                  >
                     <Stack>
                        <Box
                           sx={{
                              height: 1080 * 0.28145,
                              width: 540,
                              mb: 4,
                              textAlign: "left",
                           }}
                           ref={columnWidth}
                        >
                           <Box
                              iframeScale={0.28145}
                              sx={{
                                 position: "relative",
                                 width: 1920,
                                 height: 1080,
                                 transform: "scale(0.28145)",
                                 transformOrigin: "top left",
                                 overflow: "hidden",
                                 border: "1px solid #333",
                              }}
                              className='transparentBG'
                           >
                              {previewOverlay && (
                                 <Box
                                    sx={{
                                       position: "absolute",
                                       top: 0,
                                       left: 0,
                                       zIndex: 1000,
                                       outline: "orange 2px solid",
                                       outlineOffset: -4,
                                       width: 1920,
                                       height: 1080,
                                    }}
                                 >
                                    <FeatureMatchOverlay />
                                 </Box>
                              )}
                              <FeatureMatchViewer tableId={tableId} />
                           </Box>
                        </Box>

                        <Grid container sx={{ mb: 2, fontSize: ".7rem" }}>
                           <Grid item xs={12}>
                              <Typography
                                 variant='h4'
                                 color='text.dark'
                                 component='div'
                                 sx={{ mb: 1 }}
                              >
                                 Win Log
                              </Typography>
                           </Grid>
                           {log.map((logRow) => (
                              <>
                                 <Grid item xs={3}>
                                    {logRow.player}
                                 </Grid>
                                 <Grid item xs={3}>
                                    {logRow.wins} Wins
                                 </Grid>
                                 <Grid item xs={6}>
                                    {timeSinceUpdate(logRow.createdAt)}
                                 </Grid>
                              </>
                           ))}
                        </Grid>
                     </Stack>

                     <Stack sx={{ textAlign: "center", width: "100%" }}>
                        <Typography
                           variant='h4'
                           color='text.dark'
                           component='div'
                           sx={{ mb: 2 }}
                        >
                           Player 1
                        </Typography>

                        <Box sx={{ mb: 2, fontSize: ".7rem" }}>
                           <b>Opening Hand</b>:{" "}
                           {gameState.MulliganP1
                              ? gameState.MulliganP1
                              : "WAITING FOR IPAD"}
                        </Box>

                        <Box>
                           <PlayerSelect
                              playerPosition={1}
                              allPlayersList={allPlayers}
                              selected={gameState.PlayerIdP1}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                           <PlayerDecklistSelect
                              playerPosition={1}
                              playerData={_.find(allPlayers, (player) => {
                                 return player.id === gameState.PlayerIdP1;
                              })}
                              selected={gameState.PlayerDecklistP1}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>
                        <AdjustValue
                           label='Life Total'
                           tableId={tableId}
                           property='LifeTotalP1'
                           value={gameState.LifeTotalP1}
                           amounts={[1]}
                           resetAmount={20}
                           hideReset
                           callback={update}
                        />
                        <AdjustValue
                           label='Poison'
                           tableId={tableId}
                           property='PoisonP1'
                           value={gameState.PoisonP1}
                           amounts={[1]}
                           resetAmount={0}
                           hideReset
                           callback={update}
                        />
                        <AdjustValue
                           label='Wins'
                           tableId={tableId}
                           property='WinsP1'
                           value={gameState.WinsP1}
                           amounts={[1]}
                           maxValue={gameState.BestOf}
                           resetAmount={0}
                           hideReset
                           force
                           callback={update}
                        />
                     </Stack>

                     <Stack sx={{ textAlign: "center", width: "100%" }}>
                        <Typography
                           variant='h4'
                           color='text.dark'
                           component='div'
                           sx={{ mb: 2 }}
                        >
                           Player 2
                        </Typography>

                        <Box sx={{ mb: 2, fontSize: ".7rem" }}>
                           <b>Opening Hand</b>:{" "}
                           {gameState.MulliganP2
                              ? gameState.MulliganP2
                              : "WAITING FOR IPAD"}
                        </Box>

                        <Box>
                           <PlayerSelect
                              playerPosition={2}
                              allPlayersList={allPlayers}
                              selected={gameState.PlayerIdP2}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                           <PlayerDecklistSelect
                              playerPosition={2}
                              playerData={_.find(allPlayers, (player) => {
                                 return player.id === gameState.PlayerIdP2;
                              })}
                              selected={gameState.PlayerDecklistP2}
                              tableId={tableId}
                              callback={update}
                           />
                        </Box>

                        <AdjustValue
                           label='Life Total'
                           tableId={tableId}
                           property='LifeTotalP2'
                           value={gameState.LifeTotalP2}
                           amounts={[1]}
                           resetAmount={20}
                           hideReset
                           callback={update}
                        />

                        <AdjustValue
                           label='Poison'
                           tableId={tableId}
                           property='PoisonP2'
                           value={gameState.PoisonP2}
                           amounts={[1]}
                           resetAmount={0}
                           hideReset
                           callback={update}
                        />

                        <AdjustValue
                           label='Wins'
                           tableId={tableId}
                           property='WinsP2'
                           value={gameState.WinsP2}
                           amounts={[1]}
                           maxValue={gameState.BestOf}
                           resetAmount={0}
                           hideReset
                           force
                           callback={update}
                        />
                     </Stack>

                     <Box sx={{ textAlign: "center", width: "100%" }}>
                        {tableId == 1 && cardsInHand && (
                           <Stack spacing={1}>
                              <Typography
                                 variant='h5'
                                 color='text.dark'
                                 component='div'
                              >
                                 Player 1 | Cards In Hand
                              </Typography>
                              <Table variant='fmCardsInHand'>
                                 <TableBody>
                                    {cardsInHand["1"] &&
                                       cardsInHand["1"].map((row, i) => (
                                          <TableRow
                                             key={
                                                "cardRowP1" +
                                                i +
                                                "-" +
                                                row.card.Name
                                             }
                                          >
                                             <TableCell
                                                component='th'
                                                scope='row'
                                             >
                                                {row.card.Name}
                                             </TableCell>
                                          </TableRow>
                                       ))}
                                 </TableBody>
                              </Table>

                              <Typography
                                 variant='h5'
                                 color='text.dark'
                                 component='div'
                                 sx={{ pt: 2 }}
                              >
                                 Player 2 | Cards In Hand
                              </Typography>
                              <Table variant='fmCardsInHand'>
                                 <TableBody>
                                    {cardsInHand["2"] &&
                                       cardsInHand["2"].length === 0 && (
                                          <TableRow key={"nocards"}>
                                             <TableCell
                                                component='th'
                                                scope='row'
                                                sx={{ textAlign: "center" }}
                                             >
                                                <b>No cards in hand...</b>
                                             </TableCell>
                                          </TableRow>
                                       )}
                                    {cardsInHand["2"] &&
                                       cardsInHand["2"].map((row, i) => (
                                          <TableRow
                                             key={
                                                "cardRowP2" +
                                                i +
                                                "-" +
                                                row.card.Name
                                             }
                                          >
                                             <TableCell
                                                component='th'
                                                scope='row'
                                             >
                                                {row.card.Name}
                                             </TableCell>
                                          </TableRow>
                                       ))}
                                 </TableBody>
                              </Table>
                           </Stack>
                        )}
                     </Box>
                  </Stack>
               )}

               {gameState && (
                  <Stack
                     direction='row'
                     spacing={2}
                     alignItems='stretch'
                     sx={{ backgroundColor: "#222", px: 4, py: 2 }}
                  >
                     {tableId == 1 && (
                        <Button
                           onClick={() =>
                              update(
                                 tableId,
                                 {
                                    visibleCardsInHand:
                                       !gameState.visibleCardsInHand,
                                 },
                                 true
                              )
                           }
                           variant={
                              gameState.visibleCardsInHand
                                 ? "contained"
                                 : "outlined"
                           }
                           color={
                              gameState.visibleCardsInHand ? "success" : "alert"
                           }
                           size='small'
                        >
                           Cards In Hand
                        </Button>
                     )}
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleScoreBars: !gameState.visibleScoreBars,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleScoreBars ? "contained" : "outlined"
                        }
                        color={gameState.visibleScoreBars ? "success" : "alert"}
                        size='small'
                     >
                        Score Bars
                     </Button>
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleProfileImages:
                                    !gameState.visibleProfileImages,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleProfileImages
                              ? "contained"
                              : "outlined"
                        }
                        color={
                           gameState.visibleProfileImages ? "success" : "alert"
                        }
                        size='small'
                     >
                        Profile Images
                     </Button>
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleWins: !gameState.visibleWins,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleWins ? "contained" : "outlined"
                        }
                        color={gameState.visibleWins ? "success" : "alert"}
                        size='small'
                     >
                        Game Wins
                     </Button>
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleStandings: !gameState.visibleStandings,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleStandings ? "contained" : "outlined"
                        }
                        color={gameState.visibleStandings ? "success" : "alert"}
                        size='small'
                     >
                        Standings
                     </Button>
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleTopLogo: !gameState.visibleTopLogo,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleTopLogo ? "contained" : "outlined"
                        }
                        color={gameState.visibleTopLogo ? "success" : "alert"}
                        size='small'
                     >
                        Top Logo
                     </Button>
                     <Button
                        onClick={() =>
                           update(
                              tableId,
                              {
                                 visibleCountry: !gameState.visibleCountry,
                              },
                              true
                           )
                        }
                        variant={
                           gameState.visibleCountry ? "contained" : "outlined"
                        }
                        color={gameState.visibleCountry ? "success" : "alert"}
                        size='small'
                     >
                        Country
                     </Button>

                     {tableId === 1 && (
                        <Button
                           onClick={() => setPreviewOverlay(!previewOverlay)}
                           variant={previewOverlay ? "contained" : "outlined"}
                           color='warning'
                           size='small'
                        >
                           Preview Card Overlay
                        </Button>
                     )}
                  </Stack>
               )}
            </Stack>
         </Stack>

         <Modal open={openRoundTitle} onClose={handleCloseRoundTitle}>
            <Box sx={style}>
               <AdjustRoundTitle
                  save={updateRoundTitle}
                  cancel={handleCloseRoundTitle}
               />
            </Box>
         </Modal>
      </>
   );
}
