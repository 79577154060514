import { useState, useMemo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useDecklistHandler() {
   const socket = useContext(SocketContext);
   const { viewerId } = useParams();

   const [liveData, setLiveData] = useState();
   const [decklist, setDecklist] = useState();
   const [decklistCards, setDecklistCards] = useState();
   const [sideboardCards, setSideboardCards] = useState();
   const [allDecklists, setAllDecklists] = useState();

   const handlers = useMemo(
      () => ({
         getDecklist: (decklistId) => {
            socket.emit("getDecklist", decklistId, (response) => {
               if (response) {
                  setDecklist(response);
                  setDecklistCards(response.MainDeck);
                  setSideboardCards(response.Sideboard);
               }
            });
         },
         getAllDecklists: () => {
            socket.emit("getAllDecklists", (response) => {
               setAllDecklists(response);
            });
         },
         decklistViewerLoad: (loadViewerId, decklistId) => {
            if (loadViewerId) {
               socket.emit("decklistViewerLoad", loadViewerId, decklistId);
            } else {
               socket.emit("decklistViewerLoad", viewerId, decklistId);
            }
         },
         getFeatureMatchDecklists: (tableId) => {
            socket.emit("getFeatureMatchDecklists", tableId, (response) => {
               setAllDecklists(response);
            });
         },
         decklistViewerUpdate: (viewerId, data) => {
            socket.emit("decklistViewerUpdate", viewerId, data);
         },
         subscribeDecklistViewer: () => {
            socket.emit("subscribeDecklistViewer", viewerId, (response) => {
               console.log(response);
            });

            socket.on("decklistViewerUpdate", (data) => {
               setLiveData(data);
            });

            socket.on("decklistViewerLoad", (decklistId) => {
               socket.emit("getDecklist", decklistId, (response) => {
                  if (response) {
                     setDecklist(response);
                  }
               });
            });
         },
      }),
      [socket]
   );

   return [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      handlers,
   ];
}
