import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function usePlayerProfiles() {
   const socket = useContext(SocketContext);

   const [notification, setNotification] = useState();
   const [allPlayerProfiles, setAllPlayerProfiles] = useState();
   const [playerProfile, setPlayerProfile] = useState();
   const [show, setShow] = useState(false);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   useEffect(() => {
      setShow(true);
   }, [playerProfile]);

   const handlers = useMemo(
      () => ({
         update: (id, updates) => {
            socket.emit("updatePlayerProfile", id, updates, (response) => {
               handlers.getPlayerProfile(id);
               handlers.getAllPlayerProfiles();
            });
         },
         playerProfileViewerLoad: (output, id) => {
            socket.emit("playerProfileViewerLoad", output, id);
         },
         playerProfileViewerShowHide: (output, action) => {
            socket.emit("playerProfileViewerShowHide", output, action);
         },
         getPlayerProfile: (id) => {
            socket.emit("getPlayerProfile", id, (response) => {
               setPlayerProfile(response);
            });
         },
         deletePlayerProfile: (id) => {
            socket.emit("deletePlayerProfile", id, (response) => {
               setAllPlayerProfiles(response);
            });
         },
         getAllPlayerProfiles: () => {
            socket.emit("getAllPlayerProfiles", (response) => {
               setAllPlayerProfiles(response);
            });
         },
         addPlayerProfile: (callback) => {
            socket.emit("addPlayerProfile", (response) => {
               handlers.getAllPlayerProfiles();
            });
         },
         subscribePlayerProfileViewer: (output) => {
            socket.emit("subscribePlayerProfileViewer", output, (response) => {
               console.log(response);
            });

            socket.on("playerProfileViewerLoad", (triggerOutput, id) => {
               if (triggerOutput == output) {
                  handlers.getPlayerProfile(id);
               }
            });

            socket.on(
               "playerProfileViewerShowHide",
               (triggerOutput, action) => {
                  if (triggerOutput == output) {
                     switch (action) {
                        case "show":
                           setRunTimestamp(Date.now());
                           setShow(true);
                           break;
                        case "hide":
                           setShow((prevState) => {
                              return false;
                           });
                           break;
                        case "toggle":
                           setShow((prevState) => {
                              return !prevState;
                           });
                           break;
                     }
                  }
               }
            );
         },
      }),
      [socket]
   );

   return [
      notification,
      allPlayerProfiles,
      playerProfile,
      show,
      runTimestamp,
      handlers,
   ];
}
