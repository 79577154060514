import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SERVER_MESSAGE = process.env.REACT_APP_SERVER_MESSAGE;

export default function ServerMessage({ children }) {
   return (
      <>
         {SERVER_MESSAGE && (
            <Box
               sx={{
                  backgroundColor: "#f00",
                  fontWeight: "bold",
                  color: "#fff",
                  textTransform: "uppercase",
                  py: 0.2,
                  px: 4,
                  textAlign: "center",
                  fontSize: "1em",
                  position: "fixed",
                  bottom: 5,
                  right: 5,
                  zIndex: 2000,
                  opacity: 0.9,
                  pointerEvents: "none",
                  border: "1px solid #fff",
               }}
            >
               {SERVER_MESSAGE}
            </Box>
         )}
         {children}
      </>
   );
}
