import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import DebugOverlay from "../widgets/DebugOverlay";
import WrapperViewer from "../layout/WrapperViewer";

import ScorebarPlayer from "./ScorebarPlayer";
import ScorebarLifetotal from "./ScorebarLifetotal";
import ScorebarGameround from "./ScorebarGameround";
import Bullets from "./Bullets";
import PlayerFlag from "./PlayerFlag";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function PlayerSelect({
   playerPosition,
   tableId,
   allPlayersList,
   label,
   selected,
   callback,
}) {
   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, refreshFMPhotos, getAllPlayers },
   ] = useFeatureMatchHandler();

   const handlePlayerChange = (e) => {
      callback(
         tableId,
         {
            ["PlayerIdP" + playerPosition]: e.target.value,
            ["PlayerDecklistP" + playerPosition]: "",
            ["MulliganP" + playerPosition]: 7,
         },
         true
      );
   };

   return (
      <>
         {allPlayersList && (
            <FormControl fullWidth sx={{ maxWidth: 300 }}>
               <InputLabel id={"player" + playerPosition + "select"}>
                  Player {playerPosition}
               </InputLabel>
               <Select
                  value={selected}
                  label={"Player" + playerPosition}
                  onChange={handlePlayerChange}
               >
                  <MenuItem value=''>Select a Player</MenuItem>
                  {allPlayersList.map((player) => (
                     <MenuItem key={player.id} value={player.id}>
                        {player.PlayerNameLastFirst}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         )}
      </>
   );
}
