import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import DownloadIcon from "@mui/icons-material/Download";
import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DataGridContainer({
   data,
   height,
   type,
   disableColumnFilter,
   handleLoadIframe,
   callback,
   preview,
}) {
   let columns;
   let initialState;

   columns = [
      {
         field: "id",
         headerName: "Card Id",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "FileName",
         headerName: "Image",
         editable: false,
         width: 120,
         filterable: false,
         renderCell: (params) => (
            <Box
               sx={{
                  height: "100%",
                  p: 1,
                  backgroundColor: "#fff",
               }}
            >
               <img
                  src={
                     SERVER_URL +
                     "/cardimages/thumbs/" +
                     encodeURIComponent(params.row.FileName) +
                     ".jpg"
                  }
                  height='100%'
               />
            </Box>
         ),
      },
      {
         field: "CardName",
         headerName: "Card Name",
         editable: false,
         width: 260,
      },
      {
         field: "Set",
         headerName: "Set",
         editable: false,
         width: 100,
      },
      {
         field: "SetName",
         headerName: "Set Name",
         editable: false,
         width: 250,
      },
      {
         field: "CardManaCost",
         headerName: "Mana Cost",
         editable: false,
         width: 100,
         renderCell: (params) => (
            <ManaIconsFormatted iconWidth={15} mana={params.row.CardManaCost} />
         ),
      },
      {
         field: "preview",
         headerName: "Preview",
         editable: false,
         width: 130,
         filterable: false,
         renderCell: (params) => (
            <Button
               fullWidth
               variant='outlined'
               onClick={handlePreview}
               id={params.row.ID}
            >
               Preview
            </Button>
         ),
      },
      {
         field: "download",
         headerName: "Download",
         editable: false,
         width: 180,
         filterable: false,
         renderCell: (params) => (
            <Button
               fullWidth
               size='small'
               startIcon={<DownloadIcon />}
               variant='outlined'
               color='warning'
               onClick={handleDownload}
               value={params.row.FileName + ".png"}
            >
               DOWNLOAD
            </Button>
         ),
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "Name", sort: "asc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: false,
         },
      },
   };

   const handleCallback = (e) => {
      callback(e.target.id, e.target.value);
   };

   const handlePreview = (e) => {
      preview(e.target.id);
   };

   const handleDownload = (e) => {
      saveAs(SERVER_URL + "/cardimages/png/" + e.target.value, e.target.value);
   };

   return (
      <>
         <Box sx={{ height: height ? height : 400, width: "100%" }}>
            <DataGrid
               getRowId={(row) => row.ID}
               initialState={initialState}
               sx={{ backgroundColor: "#efefef" }}
               rows={data}
               rowHeight={
                  type === "cardOverview" || type === "cardOverlay" ? 120 : 40
               }
               disableColumnFilter={disableColumnFilter}
               columns={columns}
               rowsPerPageOptions={[100, 200, 300, 1000, 5000]}
               components={{ Toolbar: GridToolbar }}
               componentsProps={{
                  toolbar: {
                     printOptions: {
                        disableToolbarButton: true,
                     },
                  },
               }}
               disableSelectionOnClick
            />
         </Box>
      </>
   );
}
