import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import DownloadIcon from "@mui/icons-material/Download";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DataGridContainer({
   data,
   height,
   type,
   disableColumnFilter,
   handleLoadIframe,
   callback,
   preview,
}) {
   let columns;
   let initialState;

   columns = [
      {
         field: "Rank",
         headerName: "Rank",
         editable: false,
         width: 80,
         filterable: false,
      },
      {
         field: "LastName",
         headerName: "Last",
         editable: false,
         width: 200,
         filterable: true,
         valueGetter: (params) => params.row.LastName,
      },
      {
         field: "FirstName",
         headerName: "First",
         editable: false,
         width: 200,
         filterable: true,
         valueGetter: (params) => params.row.FirstName,
      },
      {
         field: "COUNTRY",
         headerName: "Country",
         editable: false,
         width: 200,
         filterable: true,
         valueGetter: (params) => params.row.COUNTRY,
         renderCell: (params) => (
            <>
               <img
                  src={params.row.CountryFlag}
                  width='20'
                  style={{ marginRight: "1em" }}
               />
               {params.row.COUNTRY}
            </>
         ),
      },
      {
         field: "MatchWins",
         headerName: "Wins",
         editable: false,
         width: 100,
         filterable: true,
      },
      {
         field: "MatchLosses",
         headerName: "Losses",
         editable: false,
         width: 100,
         filterable: true,
      },
      {
         field: "MatchDraws",
         headerName: "Draws",
         editable: false,
         width: 100,
         filterable: true,
      },
      {
         field: "Points",
         headerName: "Points",
         editable: false,
         width: 100,
         filterable: true,
      },
      {
         field: "Age",
         headerName: "Age",
         editable: false,
         width: 80,
         filterable: true,
         valueGetter: (params) => params.row.Age,
      },
      {
         field: "HOF",
         headerName: "HOF",
         editable: false,
         width: 80,
         filterable: true,
         valueGetter: (params) => params.row.HOF,
      },
      {
         field: "PREFERRED_PRONOUNS",
         headerName: "Pronouns",
         editable: false,
         width: 120,
         filterable: true,
         valueGetter: (params) => params.row.PREFERRED_PRONOUNS,
      },
      {
         field: "TwitterHandle",
         headerName: "Twitter",
         editable: false,
         width: 200,
         filterable: false,
         valueGetter: (params) => params.row.TwitterHandle,
      },
      {
         field: "id",
         headerName: "ID",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "PlayerId",
         headerName: "Player Id",
         editable: false,
         width: 100,
         filterable: false,
         valueGetter: (params) => params.row.PlayerId,
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "Rank", sort: "asc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: false,
            PlayerId: false,
            TournamentId: false,
            Status: false,
            Tiebreaker1: false,
            Tiebreaker2: false,
            Tiebreaker3: false,
         },
      },
   };

   const handleCallback = (e) => {
      callback(e.target.id, e.target.value);
   };

   const handlePreview = (e) => {
      preview(e.target.id);
   };

   const handleDownload = (e) => {
      saveAs(SERVER_URL + "/cardimages/png/" + e.target.value, e.target.value);
   };

   return (
      <>
         <Box sx={{ height: height ? height : 400, width: "100%" }}>
            <DataGrid
               componentsProps={{
                  toolbar: {
                     csvOptions: {
                        fileName: "PlayerStandings_" + Date.now(),
                     },
                  },
               }}
               initialState={initialState}
               sx={{ backgroundColor: "#efefef" }}
               rows={data}
               rowHeight={
                  type === "cardOverview" || type === "cardOverlay" ? 120 : 40
               }
               disableColumnFilter={disableColumnFilter}
               columns={columns}
               rowsPerPageOptions={[100, 200, 300, 1000, 5000]}
               components={{ Toolbar: GridToolbar }}
               componentsProps={{
                  toolbar: {
                     printOptions: {
                        disableToolbarButton: true,
                     },
                  },
               }}
               disableSelectionOnClick
            />
         </Box>
      </>
   );
}
