import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";

import CelebrityDashboardDraftTable from "../components/draft/CelebrityDashboardDraftTable";

export default function CelebrityDraftAdmin() {
   return (
      <Wrapper key={"draftAdmin"}>
         <Typography
            variant='h3'
            component='div'
            sx={{ textAlign: "center", textTransform: "uppercase" }}
         >
            Celebrity Draft Admin
         </Typography>

         <CelebrityDashboardDraftTable draftId={2} />
      </Wrapper>
   );
}
