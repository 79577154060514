import React, { useState, useContext, useEffect } from "react";

import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import ManaIconsFormatted from "../decklists/ManaIconsFormatted";
import CardImage from "../cardsinhand/CardImage";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   right: 0,
   transform: "translateY(-50%)",
   width: "40%",
   height: "80%",
   overflowY: "auto",
   overflowX: "hidden",
   bgcolor: "#222",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function CardSearch({ allCards, open, close, callback }) {
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);

   const handleClick = () => {
      // setCurrentText(text);
   };

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCards.filter(({ CardSearchString }) =>
               CardSearchString.toLowerCase().includes(
                  searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
               )
            );
         let result = search(searchValue);
         console.log(result);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const clearSearch = () => {
      setSearchResults([]);
      setSearchValue("");
   };

   const handleCallback = (rowId, card) => {
      callback(rowId, card);
      setSearchResults([]);
      setSearchValue("");
   };

   return (
      <Modal open={isNaN(open) ? false : true} onClose={close}>
         <Box sx={style}>
            <Box sx={{ mb: 2 }}>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                  }}
               >
                  Card Search
               </Typography>
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                  }}
               >
                  Max. Result 30 cards — type at least 3 characters
               </Typography>
            </Box>

            <Box>
               <TextField
                  fullWidth
                  placeholder='Enter text here'
                  value={searchValue}
                  inputProps={{
                     style: { fontSize: 24, textAlign: "center" },
                  }}
                  onClick={(event) => {
                     event.target.select();
                  }}
                  onChange={handleSearch}
               />
            </Box>

            <Stack
               direction='row'
               alignItems='center'
               justifyContent='center'
               sx={{ color: "#fff" }}
            >
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     my: 2,
                  }}
               >
                  {searchResults.length} Results
               </Typography>

               <Button
                  onClick={clearSearch}
                  sx={{ ml: 4 }}
                  variant='outlined'
                  color='error'
               >
                  CLEAR
               </Button>
            </Stack>

            <Stack alignItems='center' spacing={1}>
               {searchResults &&
                  searchResults.map((card, i) => (
                     <Button
                        variant='ipadButtonCIH'
                        color='add'
                        fullWidth
                        onClick={() => handleCallback(open, card)}
                        key={"cardRow" + i + "-" + card.CardName}
                     >
                        <Stack
                           direction='row'
                           sx={{ width: "100%" }}
                           alignItems='center'
                           justifyContent='flex-end'
                           spacing={3}
                        >
                           <Box
                              sx={{
                                 width: 100,
                                 height: 70,
                                 position: "relative",
                                 overflow: "hidden",
                              }}
                           >
                              <CardImage
                                 key={"card" + i + "-" + card.ID}
                                 id={card.CardSearchString}
                                 name={card.CardName}
                                 borderRadius={0.5}
                                 showName={false}
                                 fileName={card.FileName}
                              />
                           </Box>
                           <Stack
                              spacing={0.5}
                              sx={{
                                 width: "100%",
                              }}
                           >
                              <Box
                                 sx={{
                                    width: "100%",
                                    textAlign: "left",
                                    fontSize: ".8em",
                                 }}
                              >
                                 {card.CardName}
                              </Box>
                              <ManaIconsFormatted
                                 iconWidth={12}
                                 mana={card.CardManaCost}
                              />
                           </Stack>
                        </Stack>
                     </Button>
                  ))}
            </Stack>

            <Button
               variant='outlined'
               sx={{
                  whiteSpace: "nowrap",
                  position: "absolute",
                  top: 10,
                  right: 10,
               }}
               onClick={close}
            >
               CLOSE
            </Button>
         </Box>
      </Modal>
   );
}
