import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useTop8MovingOn from "../../hooks/useTop8MovingOn";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Top8MovingOnViewer() {
   // USE HOOK
   const [
      allPlayers,
      top8MovingOn,
      top8MovingOnLeaderboard,
      show,
      runTimestamp,
      {
         getAllPlayers,
         getTop8MovingOnLeaderboard,
         update,
         load,
         showHide,
         subscribeTop8MovingOnViewer,
      },
   ] = useTop8MovingOn();

   useEffect(() => {
      subscribeTop8MovingOnViewer();
      getTop8MovingOnLeaderboard();
   }, []);

   useEffect(() => {
      console.log(top8MovingOnLeaderboard);
   }, [top8MovingOnLeaderboard]);

   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <Stack
            sx={{
               position: "absolute",
               top: 40,
               left: 100,
               height: 200,
            }}
            justifyContent='center'
         >
            <Typography
               variant='h1Viewer'
               component='div'
               sx={{ textTransform: "uppercase" }}
            >
               Top 8 Moving On
            </Typography>
         </Stack>

         <Box key={runTimestamp}>
            <AnimatePresence>
               {top8MovingOnLeaderboard && show && (
                  <Stack
                     direction='row'
                     spacing={3}
                     sx={{
                        position: "absolute",
                        width: 1720,
                        top: 260,
                        left: 100,
                     }}
                     key={"top8MovingOn"}
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                        transition: { delay: 0.5 },
                     }}
                     exit={{
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: 0.5,
                     }}
                  >
                     <Table variant='standings'>
                        <TableHead>
                           <TableRow>
                              <TableCell
                                 width='180'
                                 sx={{ textAlign: "center" }}
                              >
                                 RANK
                              </TableCell>
                              <TableCell>NAME</TableCell>
                              <TableCell
                                 width='120'
                                 sx={{ textAlign: "center" }}
                              >
                                 WIN
                              </TableCell>
                              <TableCell
                                 width='120'
                                 sx={{ textAlign: "center" }}
                              >
                                 LOSS
                              </TableCell>
                              <TableCell
                                 width='120'
                                 sx={{ textAlign: "center", pr: 8 }}
                              >
                                 DRAW
                              </TableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {top8MovingOnLeaderboard.map((row, i) => (
                              <TableRow key={"row" + i}>
                                 <TableCell sx={{ textAlign: "center" }}>
                                    {row.Rank}
                                 </TableCell>
                                 <TableCell
                                    sx={{
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <Box
                                       className='fadeAway'
                                       sx={{
                                          position: "absolute",
                                          top: 0,
                                          right: -2,
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 100,
                                       }}
                                    />
                                    <Stack
                                       direction='row'
                                       spacing={2}
                                       sx={{
                                          position: "relative",
                                          textTransform: "uppercase",
                                       }}
                                    >
                                       <Box
                                          sx={{
                                             width: 50,
                                             height: 32,
                                             fontSize: 10,
                                             textAlign: "center",
                                          }}
                                       >
                                          {row.CountryFlag && (
                                             <Box
                                                sx={{
                                                   width: "100%",
                                                   height: "100%",
                                                   background:
                                                      "url(" +
                                                      row.CountryFlag +
                                                      ") center center",
                                                   backgroundSize: "contain",
                                                   backgroundRepeat:
                                                      "no-repeat",
                                                }}
                                             />
                                          )}
                                          {!row.CountryFlag && (
                                             <>{row.Country}</>
                                          )}
                                       </Box>
                                       <Box
                                          sx={{
                                             position: "absolute",
                                             left: 50,
                                             width: 425,
                                             whiteSpace: "nowrap",
                                             overflow: "hidden",
                                             top: "50%",
                                             transform: "translateY(-50%)",
                                          }}
                                       >
                                          {row.PlayerName}
                                       </Box>
                                    </Stack>
                                 </TableCell>
                                 <TableCell sx={{ textAlign: "center" }}>
                                    {row.MatchWins}
                                 </TableCell>
                                 <TableCell sx={{ textAlign: "center" }}>
                                    {row.MatchLosses}
                                 </TableCell>
                                 <TableCell sx={{ textAlign: "center", pr: 8 }}>
                                    {row.MatchDraws}
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </Stack>
               )}
            </AnimatePresence>
         </Box>
      </WrapperViewer>
   );
}
