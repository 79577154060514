import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function AdjustValue({
   label,
   property,
   amounts,
   resetAmount,
   callback,
}) {
   const [value, setValue] = useState(7);

   useEffect(() => {
      callback({ [property]: value });
   }, [value]);

   const decrement = (amount) => {
      setValue(Math.max(value - amount, 0));
   };

   const increment = (amount) => {
      setValue(value + amount);
   };

   const reset = (amount) => {
      setValue(resetAmount);
   };

   const ascending = (a, b) => {
      return a - b;
   };

   const descending = (a, b) => {
      return b - a;
   };

   return (
      <Stack
         spacing={0}
         sx={{ my: 4, border: "1px solid #333", py: 2 }}
         alignItems='center'
      >
         <Typography
            variant='h4'
            color='text.dark'
            component='div'
            sx={{ mb: 2 }}
         >
            {label}
         </Typography>
         <Stack
            direction='row'
            spacing={1}
            alignItems='center'
            justifyContent='center'
         >
            <Typography
               variant='h3'
               color='text.light'
               component='div'
               sx={{ textAlign: "center", px: 2 }}
               size='small'
            >
               {value}
            </Typography>

            {amounts.sort(descending).map((amount) => (
               <Button
                  key={"decrement" + amount}
                  variant='outlined'
                  onClick={() => decrement(amount)}
                  size='small'
               >
                  -{amount}
               </Button>
            ))}

            {amounts.sort(ascending).map((amount) => (
               <Button
                  key={"increment" + amount}
                  variant='outlined'
                  onClick={() => increment(amount)}
                  size='small'
               >
                  +{amount}
               </Button>
            ))}

            <Button
               size='small'
               variant='outlined'
               color='alert'
               onClick={() => reset()}
               size='small'
            >
               RESET
            </Button>
         </Stack>
      </Stack>
   );
}
