import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function AdjustValueIpad({
   label,
   tableId,
   property,
   amounts,
   maxValue,
   value,
   resetAmount,
   callback,
}) {
   // USE SOCKETS HOOK
   const [
      players,
      allPlayers,
      gameState,
      cardsInHand,
      update,
      lastUpdated,
      saveRequired,
      needsSave,
      { subscribeFeatureMatch, getAllPlayers },
   ] = useFeatureMatchHandler();

   const decrement = (amount) => {
      if (property === "WinsP1" || property === "WinsP2") {
         callback(tableId, { ["LifeTotalP1"]: 20 });
         callback(tableId, { ["LifeTotalP2"]: 20 });
         callback(tableId, { ["PoisonP1"]: 0 });
         callback(tableId, { ["PoisonP2"]: 0 });
         callback(tableId, { ["MulliganP1"]: 7 });
         callback(tableId, { ["MulliganP2"]: 7 });
      }

      callback(tableId, { [property]: Math.max(value - amount, 0) });
   };

   const increment = (amount) => {
      if (property === "WinsP1" || property === "WinsP2") {
         callback(tableId, { ["LifeTotalP1"]: 20 });
         callback(tableId, { ["LifeTotalP2"]: 20 });
         callback(tableId, { ["PoisonP1"]: 0 });
         callback(tableId, { ["PoisonP2"]: 0 });
         callback(tableId, { ["MulliganP1"]: 7 });
         callback(tableId, { ["MulliganP2"]: 7 });
      }

      callback(tableId, {
         [property]: maxValue
            ? Math.min(Math.ceil(maxValue / 2), value + amount)
            : value + amount,
      });
   };

   const reset = (amount) => {
      callback(tableId, { [property]: resetAmount });
   };

   const ascending = (a, b) => {
      return a - b;
   };

   const descending = (a, b) => {
      return b - a;
   };

   return (
      <Stack spacing={0} sx={{ my: 4 }} alignItems='center'>
         {amounts.length > 1 && (
            <>
               <Typography
                  variant='h2'
                  color='text.medium'
                  component='div'
                  sx={{ fontSize: "15vw", textAlign: "center", mt: 4 }}
               >
                  {value}
               </Typography>

               <Stack
                  direction='row'
                  spacing={1}
                  justifyContent='center'
                  alignItems='center'
               >
                  <Button
                     key={"decrement5"}
                     variant='ipadButton'
                     onClick={() => decrement(5)}
                     sx={{ height: "7vh", width: "7vw", fontSize: "2vw" }}
                  >
                     -{5}
                  </Button>
                  <Button
                     key={"decrement1"}
                     variant='ipadButton'
                     onClick={() => decrement(1)}
                     sx={{ height: "10vh", width: "10vw", fontSize: "4vw" }}
                  >
                     -{1}
                  </Button>
                  <Button
                     key={"increment1"}
                     variant='ipadButton'
                     onClick={() => increment(1)}
                     sx={{ height: "10vh", width: "10vw", fontSize: "4vw" }}
                  >
                     +{1}
                  </Button>
                  <Button
                     key={"increment5"}
                     variant='ipadButton'
                     onClick={() => increment(5)}
                     sx={{ height: "7vh", width: "7vw", fontSize: "2vw" }}
                  >
                     +{5}
                  </Button>
               </Stack>
            </>
         )}
         {amounts.length === 1 && (
            <Stack
               direction='row'
               spacing={10}
               justifyContent='center'
               alignItems='center'
            >
               <Button
                  key={"decrement1"}
                  variant='contained'
                  color='secondary'
                  onClick={() => decrement(1)}
                  sx={{ height: "10vh", width: "10vw", fontSize: "4vw" }}
               >
                  -
               </Button>
               <Typography
                  variant='h2'
                  color='text.medium'
                  component='div'
                  sx={{ fontSize: "20vw", textAlign: "center", width: "20vw" }}
               >
                  {value}
               </Typography>
               <Button
                  key={"increment1"}
                  variant='contained'
                  color='secondary'
                  onClick={() => increment(1)}
                  sx={{ height: "10vh", width: "10vw", fontSize: "4vw" }}
               >
                  +
               </Button>
            </Stack>
         )}
      </Stack>
   );
}
