import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function MatchWins({ wins, column, slot, callback }) {
   const theme = useTheme();

   const decrement = () => {
      callback(column, slot, Math.max(wins - 1, 0));
   };

   const increment = () => {
      callback(column, slot, wins + 1);
   };

   return (
      <Stack direction='row' spacing={1}>
         <Stack direction='row' spacing={1}>
            <Button
               key={"decrement"}
               variant='outlined'
               onClick={decrement}
               sx={{ height: "100%" }}
            >
               -1
            </Button>
         </Stack>

         <Typography
            variant='h3'
            color='text.primary'
            component='div'
            sx={{ width: 70, textAlign: "center", lineHeight: 2 }}
         >
            {wins}
         </Typography>

         <Stack direction='row' spacing={1}>
            <Button key={"increment"} variant='outlined' onClick={increment}>
               +1
            </Button>
         </Stack>
      </Stack>
   );
}
