import React, { useState, useContext, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

export default function AllPlayerSelect({
   label,
   playername,
   selected,
   players,
   callback,
}) {
   const handlePlayerChange = (e) => {
      callback(e.target.value);
   };

   const [focus, setFocus] = useState(false);

   return (
      <>
         {playername && players && !focus && (
            <>
               <Button
                  variant='outlined'
                  sx={{ ml: 3 }}
                  onClick={() => setFocus(true)}
               >
                  Change
               </Button>
            </>
         )}

         {!playername && players && !focus && (
            <Button
               variant='outlined'
               sx={{ ml: 3 }}
               color='error'
               onClick={() => setFocus(true)}
            >
               Select Player
            </Button>
         )}

         {players && focus && (
            <>
               <FormControl fullWidth sx={{ width: "100%" }}>
                  <InputLabel id={"players"}>Players</InputLabel>
                  <Select
                     value={selected ? selected.playerId : ""}
                     label={"Players"}
                     onChange={handlePlayerChange}
                     onBlur={() => setFocus(false)}
                     sx={{ backgroundColor: "#fff" }}
                  >
                     <MenuItem value=''>Select a Player</MenuItem>
                     {players.map((player) => (
                        <MenuItem key={player.playerId} value={player.playerId}>
                           {player.PlayerNameLastFirst}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </>
         )}
      </>
   );
}
