import React, { useState, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CardImage({
   id,
   width,
   name,
   fileName,
   borderRadius = 2.8,
   showName = true,
}) {
   return (
      <>
         {!fileName && (
            <img
               src={SERVER_URL + "/images/card-back.png"}
               style={{
                  width: "100%",
                  position: "absolute",
                  top: -4,
                  left: 0,
                  display: "block",
               }}
            />
         )}

         {fileName && (
            <img
               src={
                  SERVER_URL +
                  "/cardimages/thumbs/" +
                  encodeURIComponent(fileName) +
                  ".jpg"
               }
               style={{
                  width: "100%",
                  position: "absolute",
                  top: -4,
                  left: 0,
                  display: "block",
               }}
            />
         )}
      </>
   );
}
