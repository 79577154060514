import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";

import DashboardDraftPicker from "../components/draft/DashboardDraftPicker";

export default function DraftIpad() {
   return (
      <Box
         sx={{
            backgroundColor: "#111",
            height: window.innerHeight,
            overflow: "hidden",
         }}
         key={"draftIpad1"}
      >
         <DashboardDraftPicker draftId={1} />
      </Box>
   );
}
