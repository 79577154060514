import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

export default function Bullets({ bestof, wins }) {
   return (
      <>
         <Stack
            direction='row'
            spacing={1}
            sx={{
               position: "absolute",
               zIndex: 100,
               left: "50%",
               transform: "translateX(-50%)",
            }}
         >
            {Array(Math.ceil(bestof / 2))
               .fill()
               .map((bullet, i) => (
                  <Box
                     key={"bulletOutline" + i}
                     sx={{
                        width: 25,
                        height: 25,
                     }}
                  >
                     {wins >= i + 1 && (
                        <Box
                           sx={{
                              width: "100%",
                              height: "100%",
                              backgroundColor: "#e83411",
                              border: 4,
                              borderColor: "#e83411",
                              borderRadius: 100,
                              boxSizing: "border-box",
                           }}
                        />
                     )}
                  </Box>
               ))}
         </Stack>
         <Stack
            direction='row'
            spacing={1}
            sx={{
               position: "absolute",
               left: "50%",
               transform: "translateX(-50%)",
            }}
         >
            {Array(Math.ceil(bestof / 2))
               .fill()
               .map((bullet, i) => (
                  <Box
                     key={"bulletActive" + i}
                     sx={{
                        width: 25,
                        height: 25,
                     }}
                  >
                     <Box
                        sx={{
                           width: "100%",
                           height: "100%",
                           border: 4,
                           borderColor: "#626262",
                           borderRadius: 100,
                           boxSizing: "border-box",
                        }}
                     />
                  </Box>
               ))}
         </Stack>
      </>
   );
}
