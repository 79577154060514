import { motion, AnimatePresence } from "framer-motion";
import Box from "@mui/material/Box";

export default function LoaderIconInline({ isLoaded, size }) {
   return (
      <AnimatePresence>
         {!isLoaded && (
            <Box
               component={motion.div}
               initial={{ opacity: 0 }}
               animate={{ opacity: 1 }}
               exit={{ opacity: 0 }}
               transition={{ duration: 0.4 }}
            >
               <img src='/loader.svg' width={size === "small" ? 20 : 100} />
            </Box>
         )}
      </AnimatePresence>
   );
}
