import React, { useState, useContext, useEffect, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";
import FeatureMatchViewer from "../routes/viewers/FeatureMatch";

import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export default function ProducerDashboard() {
   const columnWidth = useRef();

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Overview
         </Typography>

         {/* PREVIEW IFRAMES */}
         <Grid container spacing={6} sx={{ mb: 4 }}>
            <Grid item xs={12} md={6} ref={columnWidth} sx={{ color: "#fff" }}>
               <Box
                  sx={{
                     overflow: "hidden",
                     width: 1920 * iframeScale,
                     height: 1080 * iframeScale,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h2'
                     component='div'
                     sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                        zIndex: 1000,
                        textAlign: "center",
                     }}
                  >
                     A
                  </Typography>

                  <Box
                     iframeScale={iframeScale}
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <FeatureMatchViewer tableId={1} />
                  </Box>
               </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ color: "#fff" }}>
               <Box
                  sx={{
                     overflow: "hidden",
                     width: 1920 * iframeScale,
                     height: 1080 * iframeScale,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h2'
                     component='div'
                     sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                        zIndex: 1000,
                        textAlign: "center",
                     }}
                  >
                     B
                  </Typography>

                  <Box
                     iframeScale={iframeScale}
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <FeatureMatchViewer tableId={2} />
                  </Box>
               </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ color: "#fff" }}>
               <Box
                  sx={{
                     overflow: "hidden",
                     width: 1920 * iframeScale,
                     height: 1080 * iframeScale,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h2'
                     component='div'
                     sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                        zIndex: 1000,
                        textAlign: "center",
                     }}
                  >
                     C
                  </Typography>

                  <Box
                     iframeScale={iframeScale}
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <FeatureMatchViewer tableId={3} />
                  </Box>
               </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{ color: "#fff" }}>
               <Box
                  sx={{
                     overflow: "hidden",
                     width: 1920 * iframeScale,
                     height: 1080 * iframeScale,
                     position: "relative",
                  }}
               >
                  <Typography
                     variant='h2'
                     component='div'
                     sx={{
                        top: "50%",
                        left: "50%",
                        transform: "translateY(-50%)",
                        position: "absolute",
                        zIndex: 1000,
                        textAlign: "center",
                     }}
                  >
                     D
                  </Typography>

                  <Box
                     iframeScale={iframeScale}
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <FeatureMatchViewer tableId={4} />
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </Wrapper>
   );
}
