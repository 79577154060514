import React, { useState, useContext, useEffect } from "react";

import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import DecklistPreview from "./DecklistPreview";
import DecklistPreviewDraft from "./DecklistPreviewDraft";

//HOOKS
import useDecklistHandler from "../../hooks/useDecklistHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function DecklistViewer() {
   const [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      { subscribeDecklistViewer, getDecklist, getAllDecklists },
   ] = useDecklistHandler();

   useEffect(() => {
      subscribeDecklistViewer();
   }, []);

   useEffect(() => {
      // getDecklist(decklist);
   }, [decklist]);

   useEffect(() => {
      // console.log(liveData);
   }, [liveData]);

   return (
      <>
         {liveData && (
            <>
               {liveData.layout != "Draft" && (
                  <DecklistPreview
                     preview={false}
                     iframeScale={1}
                     decklistId={liveData.decklistId}
                     sideboardVisible={liveData.sideboardVisible}
                     zoomCard={liveData.zoomCard}
                     zoomSideboardCard={liveData.zoomSideboardCard}
                     zoomCardPos={liveData.zoomCardPos}
                     cardRotation={liveData.cardRotation}
                  />
               )}
               {liveData.layout == "Draft" && (
                  <DecklistPreviewDraft
                     preview={false}
                     iframeScale={1}
                     decklistId={liveData.decklistId}
                     sideboardVisible={liveData.sideboardVisible}
                     zoomCard={liveData.zoomCard}
                     zoomSideboardCard={liveData.zoomSideboardCard}
                     zoomCardPos={liveData.zoomCardPos}
                     cardRotation={liveData.cardRotation}
                  />
               )}
            </>
         )}
      </>
   );
}
