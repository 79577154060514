import React, { useState, useEffect } from "react";

import { Box, Button, Stack, TextField } from "@mui/material";
import { Edit, Save } from "@mui/icons-material";

export default function TextInput({
   visible = false,
   variant = "contained",
   label,
   value,
   color,
   width = 200,
   saveButton = true,
   callback,
}) {
   const [currentValue, setCurrentValue] = useState(value);
   const [edit, setEdit] = useState(visible);

   useEffect(() => {
      if (currentValue != value) {
         console.log("current value set to " + value);
         setCurrentValue(value);
      }
   }, [value]);

   const updateValue = (e) => {
      setCurrentValue(e.target.value);
   };

   const saveInput = () => {
      !visible && setEdit(false);
      callback(currentValue);
   };

   return (
      <Stack
         direction='row'
         alignItems='center'
         spacing={2}
         sx={{ color: color ? color : "inherit" }}
      >
         {edit && (
            <>
               <TextField
                  label={label}
                  value={currentValue}
                  onChange={updateValue}
                  onBlur={saveInput}
                  sx={{ width: width }}
               />
               {saveButton && (
                  <Button
                     startIcon={<Save />}
                     onClick={saveInput}
                     color='secondary'
                     variant='contained'
                     size='small'
                  >
                     SAVE
                  </Button>
               )}
            </>
         )}
         {!edit && (
            <>
               <Box
                  sx={{
                     fontWeight: "bold",
                     fontSize: "1.2em",
                     color: !currentValue ? "#666" : color,
                  }}
               >
                  {label && <>{label}: </>}
                  {currentValue ? currentValue : "- No title -"}
               </Box>
               <Button
                  startIcon={<Edit />}
                  onClick={() => setEdit(true)}
                  color='secondary'
                  size='small'
                  sx={{ opacity: 0.5 }}
               ></Button>
            </>
         )}
      </Stack>
   );
}
