import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PlayerSelect from "./PlayerSelect";
import AdjustDraftTitle from "./AdjustDraftTitle";
import AdjustValue from "./AdjustValue";

import ManaIconsFormatted from "../../components/decklists/ManaIconsFormatted";
import CardImage from "../../components/draft/CardImage";

import ReplaceCard from "../../components/draft/ReplaceCard";

import CelebrityDraftViewer from "../../routes/viewers/CelebrityDraftViewer";

//HOOKS
import useCelebrityDraftHandler from "../../hooks/useCelebrityDraftHandler";
import useCardHandler from "../../hooks/useCardHandler";
import useGlobals from "../../hooks/useGlobals";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   width: "80%",
   height: "80vh",
   overflowY: "auto",
   transform: "translate(-50%, -50%)",
   bgcolor: "#151515",
   border: "2px solid #333",
   userSelect: "text",
   color: "#fff",
   boxShadow: 24,
   p: 4,
   textAlign: "center",
   zIndex: 1000,
};

export default function CelebrityDashboardDraftTable({ draftId }) {
   const columnWidth = useRef();
   const [previewOverlay, setPreviewOverlay] = useState(false);
   const [showDecklistExport, setShowDecklistExport] = useState(false);
   const [replaceCard, setReplaceCard] = useState(null);

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOKS
   const [globals, { getGlobals }] = useGlobals();

   const [
      allCardNames,
      data,
      visible,
      rotation,
      loaded,
      setLoaded,
      {
         subscribeDraft,
         show,
         toggleCelebrityDraftVisibility,
         toggleCelebrityDraftRotation,
         celebrityDraftOverlayDataLoad,
      },
   ] = useCelebrityDraftHandler();

   const [allCards, notification, { getAllCards }] = useCardHandler();

   const [recent, setRecent] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(false);
   const [draftFormats, setDraftFormats] = useState([]);
   const [decklistExport, setDecklistExport] = useState("");

   // MODAL
   const [openRoundTitle, setOpenRoundTitle] = useState(false);
   const handleOpenRoundTitle = () => setOpenRoundTitle(true);
   const handleCloseRoundTitle = () => setOpenRoundTitle(false);

   useEffect(() => {
      subscribeDraft();
   }, []);

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCardNames.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const addToRecent = (card) => {
      if (card.type === "image") {
         setRecent((prevState) => {
            let oldState = [...prevState];
            let newState;

            const exists = _.find(oldState, function (row) {
               return row.cardName === card.cardName;
            });

            if (!exists) {
               newState = [card, ...oldState];
            } else {
               newState = oldState;
            }

            newState.map((row, i) => {
               row.id = i;
               return row;
            });
            return newState.slice(0, 14);
         });
      }
   };

   const showRecent = () => {
      let recents = recent.map((row) => {
         row = row.card;
         return row;
      });

      recents = _.orderBy(recents, ["CardName"], ["asc"]);
      setSearchResults(recents);
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   return (
      <Box sx={{ p: 4 }}>
         <Grid container spacing={4}>
            <Grid item sm={12} xl={6} sx={{ mb: 4 }} ref={columnWidth}>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     backgroundColor: "#777",
                     color: "#333",
                     py: 1.5,
                     mb: 2,
                  }}
               >
                  PREVIEW
               </Typography>
               <Box sx={{ height: 1080 * iframeScale }}>
                  <Box
                     iframeScale={iframeScale}
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <CelebrityDraftViewer />
                  </Box>
               </Box>
            </Grid>

            <Grid item sm={4} xl={2}>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     backgroundColor: "#ccc",
                     color: "#333",
                     py: 1.5,
                     mb: 2,
                  }}
               >
                  Card
               </Typography>

               {data && (
                  <>
                     {visible === true && (
                        <Button
                           fullWidth
                           sx={{ mb: 2 }}
                           variant='contained'
                           color='error'
                           onClick={() => toggleCelebrityDraftVisibility(false)}
                        >
                           Hide Card
                        </Button>
                     )}
                     {visible !== true && (
                        <Button
                           fullWidth
                           sx={{ mb: 2 }}
                           variant='outlined'
                           color='success'
                           onClick={() => toggleCelebrityDraftVisibility(true)}
                        >
                           Show Card
                        </Button>
                     )}

                     <Box sx={{ position: "relative", width: "100%" }}>
                        {!visible && (
                           <Box
                              sx={{
                                 position: "absolute",
                                 width: "100%",
                                 height: "100%",
                                 backgroundColor: "rgba(0,0,0,.6)",
                                 textAlign: "center",
                                 zIndex: "100",
                              }}
                           />
                        )}

                        {visible === true && (
                           <Button
                              fullWidth
                              sx={{ mb: 2 }}
                              variant='outlined'
                              color='success'
                              onClick={() =>
                                 toggleCelebrityDraftRotation(
                                    rotation === 90 ? 0 : 90
                                 )
                              }
                           >
                              Rotate
                           </Button>
                        )}

                        {data.type === "image" && (
                           <img
                              src={
                                 SERVER_URL +
                                 "/cardimages/png/" +
                                 encodeURIComponent(data.value) +
                                 ".png"
                              }
                              width='100%'
                              style={{
                                 display: "block",
                                 transformOrigin: "center center",
                                 transform:
                                    "rotate(" +
                                    rotation +
                                    "deg) " +
                                    (rotation
                                       ? "scale(.67) translateX(-50%)"
                                       : ""),
                              }}
                           />
                        )}
                     </Box>
                  </>
               )}
            </Grid>

            <Grid item sm={8} xl={4}>
               <Box sx={{ mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        backgroundColor: "#ccc",
                        color: "#333",
                        py: 1.5,
                        mb: 2,
                     }}
                  >
                     Card Search
                  </Typography>
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        textAlign: "center",
                        textTransform: "uppercase",
                     }}
                  >
                     Max. Result 30 cards — type at least 3 characters
                  </Typography>
               </Box>

               <Box>
                  <TextField
                     fullWidth
                     placeholder='Enter text here'
                     value={searchValue}
                     inputProps={{
                        style: { fontSize: 24, textAlign: "center" },
                     }}
                     onClick={(event) => {
                        event.target.select();
                     }}
                     onChange={handleSearch}
                  />
               </Box>

               <Stack
                  direction='row'
                  alignItems='stretch'
                  justifyContent='center'
                  sx={{ color: "#fff", my: 2 }}
                  spacing={2}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{
                        py: 2,
                        textAlign: "center",
                        textTransform: "uppercase",
                     }}
                  >
                     {searchResults.length} Results
                  </Typography>

                  <Button
                     onClick={() => setSearchResults([])}
                     sx={{ ml: 4 }}
                     size='small'
                     variant='outlined'
                     color='error'
                  >
                     CLEAR
                  </Button>
               </Stack>
               <Stack spacing={1}>
                  {searchResults &&
                     searchResults.map((card, i) => (
                        <Stack
                           spacing={2}
                           direction='row'
                           key={"searchResult" + i}
                        >
                           <Box
                              sx={{
                                 fontSize: ".8em",
                                 backgroundColor: "#333",
                                 color: "#ccc",
                                 width: "80%",
                                 borderRadius: 1,
                              }}
                              key={"cardSearchRow-" + card.id}
                           >
                              <Stack
                                 direction='row'
                                 sx={{ width: "100%" }}
                                 alignItems='center'
                                 justifyContent='flex-end'
                                 spacing={3}
                              >
                                 <Box
                                    sx={{
                                       width: 100,
                                       height: 70,
                                       position: "relative",
                                       overflow: "hidden",
                                    }}
                                 >
                                    <CardImage
                                       key={"cardSearchImage-" + card.ID}
                                       id={card.CardSearchString}
                                       name={card.CardName}
                                       borderRadius={0.5}
                                       showName={false}
                                       fileName={card.FileName}
                                    />
                                 </Box>
                                 <Stack
                                    spacing={0.5}
                                    sx={{
                                       width: "100%",
                                    }}
                                 >
                                    <Box
                                       sx={{
                                          width: "100%",
                                          textAlign: "left",
                                          fontWeight: "bold",
                                       }}
                                    >
                                       {card.CardName}
                                    </Box>
                                    <ManaIconsFormatted
                                       iconWidth={12}
                                       mana={card.CardManaCost}
                                    />
                                 </Stack>
                              </Stack>
                           </Box>
                           <Button
                              variant='outlined'
                              color='warning'
                              onClick={() => {
                                 celebrityDraftOverlayDataLoad({
                                    type: "image",
                                    value: card.FileName,
                                 });
                                 setTimeout(() => {
                                    toggleCelebrityDraftVisibility(true);
                                 }, 200);
                              }}
                           >
                              Show
                           </Button>
                        </Stack>
                     ))}
               </Stack>
            </Grid>
         </Grid>
      </Box>
   );
}
