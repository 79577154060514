import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

export default function useControls() {
   const socket = useContext(SocketContext);

   const [allPlayers, setAllPlayers] = useState();

   const handlers = useMemo(
      () => ({
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               setAllPlayers(response);
            });
         },
      }),
      [socket]
   );

   return [allPlayers, handlers];
}
