import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

import FileList from "../components/playerphotos/FileList";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export default function PlayerPhotos({ parent }) {
   return (
      <Wrapper>
         <Box>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase" }}
            >
               Player Photos
            </Typography>

            <FileList parent={parent} />
         </Box>
      </Wrapper>
   );
}
