import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";
import CardImage from "../components/featurematch/CardImage";
import Wrapper from "../components/layout/Wrapper";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";

import ManaIcons from "../components/decklists/ManaIcons";
import DashboardTable from "../components/featurematch/DashboardTable";
import Downloader from "../components/widgets/Downloader";

import PlayerSelect from "../components/roundbyround/PlayerSelect";
import RoundByRoundPreview from "./viewers/RoundByRoundPreview";

//HOOKS
import useRoundByRound from "../hooks/useRoundByRound";
import useDatabaseManagement from "../hooks/useDatabaseManagement";

export default function RoundByRoundEditor({ parent = "" }) {
   let { id } = useParams();

   const columnWidth = useRef();

   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });

      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   // TIME
   const [time, setTime] = useState(Date.now());

   const [
      allRoundByRound,
      roundByRound,
      show,
      runTimestamp,
      {
         update,
         addRoundByRound,
         getRoundByRound,
         upsertRoundByRound,
         getAllRoundByRound,
         subscribeRoundByRoundViewer,
      },
   ] = useRoundByRound();

   useEffect(() => {
      getRoundByRound(id);
   }, []);

   useEffect(() => {
      // console.log(roundByRound);
   }, [roundByRound]);

   const handleGetPlayerData = (e) => {
      upsertRoundByRound(id, e.target.id);
   };

   const handlePlayerChange = (updates) => {
      update(id, updates);
   };

   return (
      <Wrapper>
         <Stack direction='row' alignItems='center' sx={{ mb: 2 }}>
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase", width: "100%" }}
            >
               Round By Round Slide
            </Typography>
            {roundByRound && (
               <Downloader
                  enabled={true}
                  downloadName={
                     "RoundByRound_" +
                     roundByRound.LastName +
                     roundByRound.FirstName +
                     "_" +
                     Date.now()
                  }
               >
                  <RoundByRoundPreview
                     show={true}
                     roundByRoundId={id}
                     data={roundByRound}
                     key={"roundByRound-Viewer-" + id}
                  />
               </Downloader>
            )}
         </Stack>
         <Grid container spacing={8}>
            <Grid
               item
               xs={parent != "producer" ? 8 : 12}
               ref={columnWidth}
               sx={{ height: 1080 * iframeScale, position: "relative" }}
            >
               {roundByRound && (
                  <Box
                     sx={{
                        position: "relative",
                        width: 1920,
                        height: 1080,
                        transform: "scale(" + iframeScale + ")",
                        transformOrigin: "top left",
                        overflow: "hidden",
                        border: "1px solid #333",
                     }}
                     className='transparentBG'
                  >
                     <RoundByRoundPreview
                        show={true}
                        roundByRoundId={id}
                        data={roundByRound}
                        key={"roundByRound-Results-" + roundByRound.id}
                     />
                  </Box>
               )}
            </Grid>
            {parent != "producer" && (
               <Grid item xs={4}>
                  <PlayerSelect
                     key={"playerSelect"}
                     selected={
                        roundByRound && roundByRound.PlayerId
                           ? roundByRound.PlayerId
                           : ""
                     }
                     callback={(id) =>
                        handlePlayerChange({
                           ["PlayerId"]: id,
                           Results: [],
                        })
                     }
                  />
                  <Box sx={{ backgroundColor: "#fff", px: 4, pb: 4 }}>
                     {roundByRound &&
                        roundByRound.PlayerId &&
                        roundByRound.Result &&
                        roundByRound.Result.length == 0 && (
                           <Button
                              variant='contained'
                              color='error'
                              id={roundByRound.PlayerId}
                              onClick={handleGetPlayerData}
                              sx={{ mb: 4 }}
                           >
                              LOAD PLAYER DATA
                           </Button>
                        )}

                     {roundByRound && roundByRound.PlayerId && (
                        <Stack>
                           <Button
                              variant='outlined'
                              id={roundByRound.PlayerId}
                              onClick={handleGetPlayerData}
                              sx={{ mb: 1 }}
                           >
                              REFRESH PLAYER DATA
                           </Button>
                        </Stack>
                     )}
                  </Box>
               </Grid>
            )}
         </Grid>
      </Wrapper>
   );
}
