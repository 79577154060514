import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

function ScorebarPlayer({ players, playerPosition, gameState }) {
   const theme = useTheme();

   const [player, setPlayer] = useState();
   const [decklist, setDecklist] = useState();

   useEffect(() => {
      setPlayer(
         _.find(players, {
            id: gameState["PlayerIdP" + playerPosition],
         })
      );
   }, [players]);

   useEffect(() => {
      // if (player) {
      //    setDecklist(player.Decklist);
      // }
   }, [player]);

   return (
      <AnimatePresence>
         {player && (
            <Stack
               direction='row'
               justifyContent='flex-start'
               alignItems='center'
               sx={{
                  height: "100%",
                  width: "82%",
                  position: "absolute",
                  pl: 4,
               }}
            >
               <Box
                  key={"playerId" + player.id}
                  component={motion.div}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{
                     x: 0,
                     opacity: 1,
                     transition: { delay: 0.2, easing: "easeOut" },
                  }}
                  exit={{ opacity: 0, transition: { easing: "easeIn" } }}
                  sx={{ width: "100%", pt: 0.5 }}
               >
                  {player && (
                     <>
                        <Typography
                           variant='fmPlayerName'
                           component='div'
                           sx={{ textTransform: "uppercase" }}
                        >
                           {player.PlayerName}
                        </Typography>

                        <Typography
                           variant='fmPlayerPronoun'
                           component='div'
                           sx={{ textTransform: "uppercase" }}
                        >
                           {player.PREFERRED_PRONOUNS}
                        </Typography>
                     </>
                  )}
               </Box>

               <Box
                  key={"player" + playerPosition + "Deck" + player.decklist}
                  component={motion.div}
                  initial={{ x: -50, opacity: 0 }}
                  animate={{
                     x: 0,
                     opacity: 1,
                     transition: { delay: 0.2, easing: "easeOut" },
                  }}
                  exit={{ opacity: 0, transition: { easing: "easeIn" } }}
                  sx={{
                     pt: 0.5,
                     width: "32%",
                     zIndex: 500,
                     overflow: "hidden",
                  }}
               >
                  <AnimatePresence>
                     {player.decklist && (
                        <Typography
                           key={
                              "player" +
                              playerPosition +
                              "deckList-" +
                              player.decklist.Archetype
                           }
                           variant='fmPlayerDeck'
                           component={motion.div}
                           initial={{ x: -50, opacity: 0 }}
                           animate={{
                              x: 0,
                              opacity: 1,
                              transition: { delay: 0.4, easing: "easeOut" },
                           }}
                           exit={{
                              opacity: 0,
                              transition: { easing: "easeIn" },
                           }}
                           sx={{
                              textTransform: "uppercase",
                              width: "100%",
                              whiteSpace: "nowrap",
                           }}
                        >
                           {player.decklist.Archetype &&
                              player.decklist.Archetype}
                        </Typography>
                     )}
                  </AnimatePresence>
               </Box>
            </Stack>
         )}
      </AnimatePresence>
   );
}

export default ScorebarPlayer;
