import React, { useState, useEffect } from "react";

import { Box, Button, Stack, Select, MenuItem } from "@mui/material";
import { Edit, Save } from "@mui/icons-material";

export default function SelectInput({
   style,
   direction,
   size,
   label,
   value,
   boolean,
   options,
   callback,
}) {
   const [currentValue, setCurrentValue] = useState(value);

   useEffect(() => {
      if (currentValue != value) {
         console.log("current value set to " + value);
         setCurrentValue(value);
      }
   }, [value]);

   const updateValue = (e) => {
      setCurrentValue(e.target.value);
   };

   useEffect(() => {
      if (value != currentValue) {
         callback(currentValue);
      }
   }, [currentValue]);

   return (
      <Stack
         spacing={2}
         direction={direction ? direction : "row"}
         alignItems='center'
         sx={{ width: "100%" }}
      >
         <Box>{label}</Box>
         <Select
            value={currentValue}
            onChange={updateValue}
            size={size}
            fullWidth
            sx={{ ...style }}
         >
            {options.map((option) => (
               <MenuItem key={label + "-" + option} value={option}>
                  {boolean ? new Boolean(option).toString() : option}
               </MenuItem>
            ))}
         </Select>
      </Stack>
   );
}
