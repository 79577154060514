import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/decklists/DataGridInteractive";

import Wrapper from "../components/layout/Wrapper";

import DecklistPreview from "./viewers/DecklistPreview";
import DecklistPreviewDraft from "./viewers/DecklistPreviewDraft";
import Downloader from "../components/widgets/Downloader";

//HOOKS
import useDatabaseManagement from "../hooks/useDatabaseManagement";
import useDecklistHandler from "../hooks/useDecklistInteractiveHandler";

export default function Decklists({ parent }) {
   const columnWidth = useRef();
   const [live, setLive] = useState(false);

   const [layout, setLayout] = useState();
   const [previewDecklist, setPreviewDecklist] = useState();
   const [downloadName, setDownloadName] = useState();
   const [sideboardVisible, setSideboardVisible] = useState();
   const [zoomCardPos, setZoomCardPos] = useState();

   const [zoomCard, setZoomCard] = useState();
   const [zoomSideboardCard, setZoomSideboardCard] = useState();

   const [cardRotation, setCardRotation] = useState(false);

   // WINDOW LISTENER
   const iframeScale =
      columnWidth.current &&
      (parseInt(window.getComputedStyle(columnWidth.current).width) -
         parseInt(
            window
               .getComputedStyle(columnWidth.current)
               .getPropertyValue("padding-left")
         )) /
         1920;

   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   // USE HOOK

   const [log, previewData, notification, { importPlayers, importDecklists }] =
      useDatabaseManagement();

   const [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      {
         decklistViewerUpdate,
         getDecklist,
         getAllDecklists,
         decklistViewerLoad,
      },
   ] = useDecklistHandler();

   useEffect(() => {
      getAllDecklists();
   }, []);

   useEffect(() => {
      // console.log(allDecklists);
   }, [allDecklists]);

   useEffect(() => {
      if (live) {
         decklistViewerUpdate({
            layout: layout,
            decklistId: previewDecklist,
            sideboardVisible: sideboardVisible,
            zoomCard: zoomCard,
            zoomSideboardCard: zoomSideboardCard,
            zoomCardPos: zoomCardPos,
            cardRotation: cardRotation,
         });
      }
   }, [
      live,
      layout,
      previewDecklist,
      sideboardVisible,
      zoomCard,
      zoomSideboardCard,
      zoomCardPos,
      cardRotation,
   ]);

   const upsertDecklists = async () => {
      await importDecklists(false).then((response) => {
         getAllDecklists();
         alert(response.data);
      });
   };

   const upsertPlayers = async () => {
      await importPlayers(false).then(() => {
         getAllDecklists();
         alert("Updated all Player info.");
      });
   };

   const show = (id, layout) => {
      handleLoadPreview(id, true, layout);
   };

   const handleLoadPreview = (id, visibility, layout) => {
      setLayout(layout);
      setPreviewDecklist(id);
      hideSideboard(id);
      setZoomCard();
      setZoomCardPos();

      let decklistData = _.find(allDecklists, function (o) {
         return o.id == id;
      });

      setDownloadName(
         "Decklist_" +
            decklistData.PlayerName.replace(/[^A-Za-z0-9]/g, "") +
            "_" +
            (layout ? layout + "_" : "") +
            (decklistData.Archetype
               ? decklistData.Archetype.replace(/[^A-Za-z0-9]/g, "") + "_"
               : "") +
            Date.now()
      );
   };

   const toggleCardRotation = () => {
      setCardRotation((prevState) => {
         return !prevState;
      });
   };

   const showSideboard = (id) => {
      handleShowSideboard(id, true);
   };

   const hideSideboard = (id) => {
      handleShowSideboard(id, false);
   };

   const handleShowSideboard = (id, visibility) => {
      let decklistData = _.find(allDecklists, function (o) {
         return o.id == id;
      });

      setSideboardVisible(visibility);

      setDownloadName(
         "Decklist_" +
            (visibility ? "Sideboard_" : "") +
            decklistData.PlayerName.replace(/[^A-Za-z0-9]/g, "") +
            (decklistData.Archetype
               ? "_" + decklistData.Archetype.replace(/[^A-Za-z0-9]/g, "")
               : "") +
            "_" +
            Date.now()
      );
   };

   return (
      <Wrapper>
         <Stack
            direction='row'
            sx={{ width: "100%", mb: 2 }}
            alignItems='center'
            justifyContent='space-between'
         >
            <Typography
               variant='h3'
               component='div'
               sx={{ textTransform: "uppercase" }}
            >
               Decklists Interactive
            </Typography>

            <Stack direction='row' spacing={2}>
               <Button
                  variant='outlined'
                  color='secondary'
                  onClick={upsertPlayers}
               >
                  Import All Player Info
               </Button>
               <Button
                  variant='contained'
                  color='success'
                  onClick={upsertDecklists}
               >
                  Import All Decklists
               </Button>
            </Stack>
         </Stack>

         {allDecklists && (
            <DataGrid
               data={allDecklists}
               type='decklistOverview'
               callback={decklistViewerLoad}
               load={show}
            />
         )}

         <Grid container spacing={2}>
            <Grid
               item
               xs={12}
               ref={columnWidth}
               sx={{ color: "#fff", position: "relative" }}
            >
               <Stack direction='row' alignItems='center' sx={{ mt: 4, mb: 2 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
                  >
                     {layout} PREVIEW
                  </Typography>

                  {previewDecklist && (
                     <>
                        <Box sx={{ width: "100%", pl: 4 }}>
                           {parent != "producer" && (
                              <>
                                 {!live && (
                                    <Button
                                       variant='outlined'
                                       color='success'
                                       onClick={() => setLive(true)}
                                       sx={{
                                          width: 250,
                                          height: 50,
                                          whiteSpace: "nowrap",
                                          mr: 2,
                                          p: 2,
                                       }}
                                    >
                                       PREVIEW MODE
                                    </Button>
                                 )}

                                 {live && (
                                    <Button
                                       variant='contained'
                                       color='error'
                                       onClick={() => setLive(false)}
                                       sx={{
                                          width: 250,
                                          height: 50,
                                          whiteSpace: "nowrap",
                                          mr: 2,
                                          p: 2,
                                       }}
                                    >
                                       LIVE MODE
                                    </Button>
                                 )}
                              </>
                           )}
                        </Box>

                        {!sideboardVisible && layout != "Draft" && (
                           <Button
                              variant='outlined'
                              color='warning'
                              onClick={() => showSideboard(previewDecklist)}
                              sx={{
                                 width: 250,
                                 height: 50,
                                 whiteSpace: "nowrap",
                                 mr: 2,
                                 p: 2,
                              }}
                              size='small'
                           >
                              Sideboard
                           </Button>
                        )}

                        {sideboardVisible && (
                           <Button
                              variant='contained'
                              color='error'
                              onClick={() => hideSideboard(previewDecklist)}
                              sx={{
                                 width: 250,
                                 height: 50,
                                 whiteSpace: "nowrap",
                                 mr: 2,
                                 p: 2,
                              }}
                           >
                              Sideboard
                           </Button>
                        )}

                        <Downloader enabled={true} downloadName={downloadName}>
                           {layout != "Draft" && (
                              <DecklistPreview
                                 preview={true}
                                 controls={false}
                                 decklistId={previewDecklist}
                                 sideboardVisible={sideboardVisible}
                                 setZoomCard={setZoomCard}
                                 setZoomSideboardCard={setZoomSideboardCard}
                                 zoomCard={zoomCard}
                                 zoomSideboardCard={zoomSideboardCard}
                                 zoomCardPos={zoomCardPos}
                                 setZoomCardPos={setZoomCardPos}
                                 cardRotation={cardRotation}
                                 setCardRotation={setCardRotation}
                              />
                           )}
                           {layout == "Draft" && (
                              <DecklistPreviewDraft
                                 iframeScale={iframeScale}
                                 preview={true}
                                 controls={false}
                                 decklistId={previewDecklist}
                                 sideboardVisible={sideboardVisible}
                                 setZoomCard={setZoomCard}
                                 setZoomSideboardCard={setZoomSideboardCard}
                                 zoomCard={zoomCard}
                                 zoomSideboardCard={zoomSideboardCard}
                                 zoomCardPos={zoomCardPos}
                                 setZoomCardPos={setZoomCardPos}
                                 cardRotation={cardRotation}
                                 setCardRotation={setCardRotation}
                              />
                           )}
                        </Downloader>
                     </>
                  )}
               </Stack>
               {layout != "Draft" && (
                  <Box
                     sx={{ height: 1080 * iframeScale }}
                     key='standardPreview'
                  >
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "1px solid #ccc",
                           mt: 3,
                        }}
                        className='transparentBG'
                     >
                        <DecklistPreview
                           iframeScale={iframeScale}
                           preview={true}
                           controls={true}
                           decklistId={previewDecklist}
                           sideboardVisible={sideboardVisible}
                           setZoomCard={setZoomCard}
                           setZoomSideboardCard={setZoomSideboardCard}
                           zoomCard={zoomCard}
                           zoomSideboardCard={zoomSideboardCard}
                           zoomCardPos={zoomCardPos}
                           setZoomCardPos={setZoomCardPos}
                           cardRotation={cardRotation}
                           setCardRotation={setCardRotation}
                        />
                     </Box>
                  </Box>
               )}
               {layout == "Draft" && (
                  <Box sx={{ height: 1080 * iframeScale }} key='draftPreview'>
                     <Box
                        sx={{
                           position: "relative",
                           width: 1920,
                           height: 1080,
                           transform: "scale(" + iframeScale + ")",
                           transformOrigin: "top left",
                           overflow: "hidden",
                           outline: "1px solid #ccc",
                           mt: 3,
                        }}
                        className='transparentBG'
                     >
                        <DecklistPreviewDraft
                           iframeScale={iframeScale}
                           preview={true}
                           controls={true}
                           decklistId={previewDecklist}
                           sideboardVisible={sideboardVisible}
                           setZoomCard={setZoomCard}
                           setZoomSideboardCard={setZoomSideboardCard}
                           zoomCard={zoomCard}
                           zoomSideboardCard={zoomSideboardCard}
                           zoomCardPos={zoomCardPos}
                           setZoomCardPos={setZoomCardPos}
                           cardRotation={cardRotation}
                           setCardRotation={setCardRotation}
                        />
                     </Box>
                  </Box>
               )}
            </Grid>
         </Grid>
      </Wrapper>
   );
}
