import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/standings/DataGrid";

import Wrapper from "../components/layout/Wrapper";

import Downloader from "../components/widgets/Downloader";
import StandingsPreview from "./viewers/StandingsPreview";
import CopyToClipboardButton from "../components/widgets/CopyToClipboardButton";
import DeleteIcon from "@mui/icons-material/Delete";

import PreviewData from "../components/databasemanagement/PreviewData";

//HOOKS
import useStandingsHandler from "../hooks/useStandingsHandler";
import useDatabaseManagement from "../hooks/useDatabaseManagement";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Standings({ parent = "" }) {
   let navigate = useNavigate();

   // USE HOOK
   const [
      standings,
      setStandings,
      cachedStandings,
      tournamentRounds,
      notification,
      {
         getCachedStandings,
         getStandings,
         saveStandings,
         getTournamentRounds,
         deleteStandings,
         refreshTournament,
      },
   ] = useStandingsHandler();

   const [log, previewData, dbNotification, { insert, preview }] =
      useDatabaseManagement();

   useEffect(() => {
      getTournamentRounds();
      getCachedStandings();

      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
         clearInterval(interval);
      };
   }, []);

   useEffect(() => {
      // console.log(tournamentRounds);
      // console.log(cachedStandings);
   }, [tournamentRounds]);

   useEffect(() => {
      if (log.length > 0) {
         if (log[0].response.data.message === "Inserted") {
            getCachedStandings();
         }
      }
   }, [log]);

   // TIME
   const [time, setTime] = useState(Date.now());

   const timeSinceUpdate = (lastUpdate) => {
      if (lastUpdate) {
         let lastUpdateFormatted =
            lastUpdate.split(" ").slice(0, -1).join("T") + "Z";
         let date1 = new Date(lastUpdateFormatted);
         let date2 = new Date(time);
         let difference = date2.getTime() - date1.getTime();

         const secondsAgo = Math.max(0, Math.round(difference / 1000));
         const minutesAgo = Math.round(difference / 60000);

         return secondsAgo >= 60
            ? minutesAgo + " minute" + (minutesAgo > 1 ? "s" : "") + " ago"
            : secondsAgo + " seconds ago";
      }
   };

   const handleDelete = (id, title) => {
      if (id) {
         if (
            window.confirm(
               "Are you sure you want to delete '" + title + "' (" + id + ")?"
            )
         ) {
            deleteStandings(id);
         }
      }
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Standings
         </Typography>

         <Stack direction='row' spacing={4} alignItems='flex-start'>
            {parent != "producer" && (
               <Box sx={{ width: "25%" }}>
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ textTransform: "uppercase", mb: 2 }}
                  >
                     Tournament Rounds
                  </Typography>
                  <Table variant='dashboard' sx={{ maxWidth: 1500 }}>
                     <TableHead>
                        <TableRow>
                           <TableCell>
                              CONTROLS
                              <Button onClick={refreshTournament}>
                                 REFRESH
                              </Button>
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     {tournamentRounds && tournamentRounds.length > 0 && (
                        <TableBody>
                           {tournamentRounds.map((row) => (
                              <TableRow key={"slideRow" + row.ID}>
                                 <TableCell>
                                    <Button
                                       onClick={() => saveStandings(row.ID)}
                                       variant='outlined'
                                       color='success'
                                       fullWidth
                                    >
                                       SAVE {row.Name}
                                    </Button>
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     )}
                  </Table>
               </Box>
            )}
            <Box sx={{ width: "100%" }}>
               <Typography
                  variant='h5'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  Cached Standings
               </Typography>
               <Table variant='dashboard' sx={{ maxWidth: 1500 }}>
                  <TableHead>
                     <TableRow>
                        <TableCell>Round</TableCell>
                        <TableCell>Timestamp</TableCell>
                        <TableCell align='right' width='300'>
                           {/* <Button
                        onClick={() => preview("getCurrentStandings")}
                        variant='contained'
                        color='error'
                        fullWidth
                     >
                        + SAVE CURRENT STANDINGS
                     </Button> */}
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  {cachedStandings && (
                     <TableBody>
                        {cachedStandings.map((row) => (
                           <TableRow key={"slideRow" + row.id}>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>
                                 {timeSinceUpdate(row.updatedAt)}
                              </TableCell>
                              <TableCell align='right'>
                                 <Stack direction='row' spacing={2}>
                                    <Button
                                       onClick={() =>
                                          navigate(
                                             "/" +
                                                parent +
                                                "/standings/" +
                                                row.RoundId
                                          )
                                       }
                                       variant='outlined'
                                       color='error'
                                       fullWidth
                                    >
                                       VIEW
                                    </Button>

                                    {parent != "producer" && (
                                       <Button
                                          variant='outlined'
                                          color='error'
                                          fullWidth
                                          startIcon={<DeleteIcon />}
                                          onClick={() =>
                                             handleDelete(
                                                row.RoundId,
                                                row.description
                                             )
                                          }
                                       >
                                          DELETE
                                       </Button>
                                    )}
                                 </Stack>
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  )}
               </Table>
            </Box>
         </Stack>

         <PreviewData
            data={previewData}
            callback={(runUpdate) => {
               insert(runUpdate);
            }}
         />

         {dbNotification && (
            <NotificationWidget
               notification={dbNotification.message}
               key={"notification" + dbNotification.date}
            />
         )}
      </Wrapper>
   );
}
