import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { GridToolbar, DataGrid } from "@mui/x-data-grid";

const columns = [
   { field: "id", headerName: "Player ID", width: 100 },
   {
      field: "LastName",
      headerName: "last name",
      width: 180,
   },
   {
      field: "FirstName",
      headerName: "First name",
      width: 180,
   },
   {
      field: "HOF",
      headerName: "HOF",
      width: 125,
   },
   {
      field: "Country",
      headerName: "Country",
      width: 180,
   },
   {
      field: "Format",
      headerName: "Format",
      width: 180,
      valueGetter: (params) => {
         if (JSON.parse(params.row.Decklists).length > 0) {
            return JSON.parse(params.row.Decklists)[0].Format;
         } else {
            return "";
         }
      },
   },
   {
      field: "Archetype",
      headerName: "Archetype",
      width: 180,
      valueGetter: (params) => {
         if (JSON.parse(params.row.Decklists).length > 0) {
            return JSON.parse(params.row.Decklists)[0].Name;
         } else {
            return "";
         }
      },
   },
];

export default function PlayerSelect({ callback, selectedRows, rows }) {
   const [currentSelectedRows, setCurrentSelectedRows] = useState(
      JSON.parse(selectedRows)
   );

   useEffect(() => {
      if (currentSelectedRows != selectedRows) {
         callback(currentSelectedRows);
      }
   }, [currentSelectedRows]);

   return (
      <Box sx={{ height: 600, width: "100%" }}>
         <DataGrid
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 25,
                  },
               },
            }}
            pageSizeOptions={[25]}
            checkboxSelection
            disableRowSelectionOnClick
            selectionModel={currentSelectedRows}
            onSelectionModelChange={(newSelection) => {
               setCurrentSelectedRows(newSelection);
            }}
         />
      </Box>
   );
}
