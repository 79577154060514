import React, { useState, useContext, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Wrapper from "../components/layout/Wrapper";

import UrlToCopy from "../components/widgets/UrlToCopy";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Urls() {
   return (
      <Wrapper>
         <Stack spacing={2}>
            <Typography variant='h3' component='div'>
               Viewer URLs for vMix/Mitti:
            </Typography>

            <Stack sx={{ mt: 2 }} spacing={2}>
               <UrlToCopy title='LED Wall Bracket' path='/viewer/ledbracket' />
               <UrlToCopy title='LED Wall Players' path='/viewer/ledplayers' />
               <UrlToCopy title='LED Wall Versus' path='/viewer/ledversus' />
               <UrlToCopy
                  title='LED Wall Versus Top 8'
                  path='/viewer/ledversustop8'
               />
               <UrlToCopy
                  title='LED Wall Champion Top 8'
                  path='/viewer/ledchampiontop8'
               />
               <UrlToCopy
                  title='Lower Thirds Output 1'
                  path='/viewer/lowerthirds/1'
               />
               <UrlToCopy
                  title='Lower Thirds Output 2'
                  path='/viewer/lowerthirds/2'
               />
               <UrlToCopy
                  title='Lower Thirds Output 3'
                  path='/viewer/lowerthirds/3'
               />
               <UrlToCopy
                  title='Storyboards'
                  path='/viewer/customleaderboard'
               />
               <UrlToCopy title='Feature Cards' path='/viewer/featurecards' />

               <UrlToCopy title='Timer Background' path='/viewer/timer' />
               <UrlToCopy
                  title='Feature Match 1'
                  path='/viewer/featurematch/1'
               />
               <UrlToCopy
                  title='Feature Match 2'
                  path='/viewer/featurematch/2'
               />
               <UrlToCopy
                  title='Feature Match 3'
                  path='/viewer/featurematch/3'
               />
               <UrlToCopy
                  title='Feature Match 4'
                  path='/viewer/featurematch/4'
               />
               <UrlToCopy
                  title='Card Overlay Viewer'
                  path='/viewer/featurematchOverlay'
               />
               <UrlToCopy
                  title='Celebrity Draft Viewer'
                  path='/viewer/celebritydraft'
               />
               <UrlToCopy title='Draft Viewer' path='/viewer/draft/1' />
               <UrlToCopy
                  title='Decklist Viewer Interactive'
                  path='/viewer/decklistinteractive'
               />
               <UrlToCopy title='Decklist Viewer 1' path='/viewer/decklist/1' />
               <UrlToCopy title='Decklist Viewer 2' path='/viewer/decklist/2' />
               <UrlToCopy title='Decklist Viewer 3' path='/viewer/decklist/3' />
               <UrlToCopy title='Standings Viewer' path='/viewer/standings' />
               <UrlToCopy
                  title='Round by Round Viewer'
                  path='/viewer/roundbyround'
               />
               <UrlToCopy
                  title='Head to Head Viewer'
                  path='/viewer/headtohead'
               />
               <UrlToCopy
                  title='Player Profile Viewer 1'
                  path='/viewer/profile/1'
               />
               <UrlToCopy
                  title='Player Profile Viewer 2'
                  path='/viewer/profile/2'
               />
               <UrlToCopy title='Top 8 Bracket Viewer' path='/viewer/bracket' />
               <UrlToCopy title='Top 8 Moving On Viewer' path='/viewer/top8' />
            </Stack>
         </Stack>
      </Wrapper>
   );
}
