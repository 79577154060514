import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import WrapperViewer from "../../components/layout/WrapperViewer";

import SideboardView from "../../components/cardoverlay/SideboardView";

//HOOKS
import useGraphicsOverlay from "../../hooks/useGraphicsOverlay";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function preloadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
         resolve(img);
      };
      img.onerror = img.onabort = function () {
         reject(src);
      };
      img.src = src;
   });
}

export default function FeatureMatchOverlay() {
   const theme = useTheme();

   // USE SOCKETS HOOK
   const [
      loaded,
      setLoaded,
      data,
      visible,
      visibleOpeningHand,
      openingHand,
      rotation,
      playerId1,
      playerId2,
      player1Decklists,
      player2Decklists,
      { subscribeOverlay },
   ] = useGraphicsOverlay();

   useEffect(() => {
      subscribeOverlay();
   }, []);

   useEffect(() => {
      if (data) {
         setLoaded(false);
         if (data.type === "image") {
            preloadImage(
               SERVER_URL +
                  "/cardimages/png/" +
                  encodeURIComponent(data.value) +
                  ".png"
            )
               .then((response) => {
                  setLoaded(true);
               })
               .catch((error) => {
                  console.log("Couldn't load!");
                  setLoaded(false);
               });
         }
         if (data.type === "sideboard") {
            setLoaded(true);
         }
      }
   }, [data]);

   // useEffect(() => {
   //    if (loaded) {
   //       console.log("Loaded");
   //    } else {
   //       console.log("Unloaded");
   //    }
   // }, [loaded]);

   // useEffect(() => {
   //    console.log(visible);
   // }, [visible]);

   return (
      <>
         <Box
            sx={{
               cursor: "none",
            }}
         >
            <WrapperViewer>
               <AnimatePresence>
                  {visibleOpeningHand && openingHand && (
                     <>
                        <Box
                           key={"openingHandP1"}
                           initial={{ opacity: 0 }}
                           animate={{
                              opacity: 1,
                              transition: { duration: 0.6, delay: 0.3 },
                           }}
                           exit={{
                              opacity: 0,
                              transition: { duration: 0.4 },
                           }}
                           component={motion.div}
                           sx={{
                              color: theme.palette.text.dark,
                              position: "absolute",
                              width: 275,
                              top: 78,
                              height: 28,
                              py: 0.2,
                              pl: 3.5,
                              left: 360,
                              zIndex: 10,
                              backgroundColor: "#2c2c2c",
                              outline: "4px solid #222",
                           }}
                        >
                           <Typography
                              component='div'
                              variant='fmOpeningHand'
                              sx={{
                                 textTransform: "uppercase",
                              }}
                           >
                              OPENING HAND: <b>{openingHand.p2} CARDS</b>
                           </Typography>
                        </Box>
                        <Box
                           key={"openingHandP1"}
                           initial={{ opacity: 0 }}
                           animate={{
                              opacity: 1,
                              transition: { duration: 0.6, delay: 0.3 },
                           }}
                           exit={{
                              opacity: 0,
                              transition: { duration: 0.4 },
                           }}
                           component={motion.div}
                           sx={{
                              color: theme.palette.text.dark,
                              position: "absolute",
                              width: 275,
                              bottom: 78,
                              height: 28,
                              py: 0.2,
                              pl: 3.5,
                              left: 360,
                              zIndex: 10,
                              backgroundColor: "#2c2c2c",
                              outline: "4px solid #222",
                           }}
                        >
                           <Typography
                              component='div'
                              variant='fmOpeningHand'
                              sx={{
                                 textTransform: "uppercase",
                              }}
                           >
                              OPENING HAND: <b>{openingHand.p1} CARDS</b>
                           </Typography>
                        </Box>
                     </>
                  )}
               </AnimatePresence>
               <AnimatePresence>
                  {loaded && data && visible && (
                     <Box
                        key={data.value}
                        component={motion.div}
                        initial={{ left: -400, opacity: 0 }}
                        animate={{
                           left: 17 + (rotation ? 70 : 0),
                           opacity: 1,
                           rotate: rotation,
                        }}
                        exit={{ left: -400, opacity: 0 }}
                        transition={{ easing: "easeInOut", delay: 0.1 }}
                        sx={{
                           position: "absolute",
                           width: 322,
                           height: 496,
                           top: 292,
                           zIndex: 10,
                           transformOrigin: "center center",
                        }}
                     >
                        <Stack
                           justifyContent='center'
                           sx={{ height: "100%", width: "100%" }}
                        >
                           {data.type === "image" && (
                              <img
                                 src={
                                    SERVER_URL +
                                    "/cardimages/png/" +
                                    encodeURIComponent(data.value) +
                                    ".png"
                                 }
                                 width='100%'
                                 style={{
                                    display: "block",
                                 }}
                              />
                           )}
                           {data.type === "sideboard" && (
                              <Box>
                                 <Typography
                                    variant='fmPlayerDeck'
                                    component='div'
                                    sx={{
                                       textTransform: "uppercase",
                                       textAlign: "center",
                                       color: theme.palette.text.dark,
                                       whiteSpace: "nowrap",
                                       maskImage:
                                          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 95%, rgba(0,0,0,0) 100%)",
                                       maskRepeat: "no-repeat",
                                    }}
                                 >
                                    {data.title} Sideboard
                                 </Typography>

                                 <Box sx={{ px: 1 }}>
                                    <SideboardView
                                       key={"activeSideboard" + data.title}
                                       cards={data.value}
                                    />
                                 </Box>
                              </Box>
                           )}
                        </Stack>
                     </Box>
                  )}
               </AnimatePresence>
               <AnimatePresence>
                  {data && visible && (
                     <Box
                        key={"backgroundImage"}
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { delay: 0.3 } }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "absolute",
                           width: 339,
                           height: 512,
                           top: 284,
                           left: 9,
                           zIndex: 5,
                           background:
                              "url(" +
                              SERVER_URL +
                              "/images/ui/overlay-center-cover.png" +
                              ")",
                        }}
                     />
                  )}
               </AnimatePresence>
            </WrapperViewer>
         </Box>
      </>
   );
}
