import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import DebugOverlay from "../../components/widgets/DebugOverlay";
import WrapperViewer from "../../components/layout/WrapperViewer";

import DraftPicks from "../../components/draft/DraftPicks";
import ScorebarPlayer from "../../components/draft/ScorebarPlayer";
import ScorebarGameround from "../../components/featurematch/ScorebarGameround";

import PlayerPhoto from "../../components/draft/PlayerPhoto";
import PlayerFlag from "../../components/draft/PlayerFlag";

//HOOKS
import useCelebrityDraftHandler from "../../hooks/useCelebrityDraftHandler";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function preloadImage(src) {
   return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
         resolve(img);
      };
      img.onerror = img.onabort = function () {
         reject(src);
      };
      img.src = src;
   });
}

export default function CelebrityDraftViewer() {
   let draftId = 2;
   const theme = useTheme();

   const [
      allCardNames,
      data,
      visible,
      rotation,
      loaded,
      setLoaded,
      {
         subscribeDraft,
         show,
         toggleCelebrityDraftVisibility,
         toggleCelebrityDraftRotation,
         celebrityDraftOverlayDataLoad,
      },
   ] = useCelebrityDraftHandler();

   useEffect(() => {
      subscribeDraft(draftId);
   }, []);

   useEffect(() => {
      if (data) {
         setLoaded(false);
         if (data.type === "image") {
            preloadImage(
               SERVER_URL +
                  "/cardimages/png/" +
                  encodeURIComponent(data.value) +
                  ".png"
            )
               .then((response) => {
                  setLoaded(true);
               })
               .catch((error) => {
                  console.log("Couldn't load!");
                  setLoaded(false);
               });
         }
      }
   }, [data]);

   useEffect(() => {
      if (loaded) {
         // console.log("Loaded");
      } else {
         // console.log("Unloaded");
      }
   }, [loaded]);

   return (
      <>
         <Box
            sx={{
               cursor: "none",
            }}
            key={"draftId" + draftId}
         >
            <WrapperViewer>
               <AnimatePresence>
                  {loaded && data && visible && (
                     <Box
                        key={data.value}
                        component={motion.div}
                        initial={{ left: -400, opacity: 0 }}
                        animate={{
                           left: 17 + (rotation ? 70 : 0),
                           opacity: 1,
                           rotate: rotation,
                        }}
                        exit={{ left: -400, opacity: 0 }}
                        transition={{ easing: "easeInOut", delay: 0.1 }}
                        sx={{
                           position: "absolute",
                           width: 322,
                           height: 496,
                           top: 292,
                           zIndex: 1000,
                           transformOrigin: "center center",
                        }}
                     >
                        <Stack
                           justifyContent='center'
                           sx={{ height: "100%", width: "100%" }}
                        >
                           {data.type === "image" && (
                              <img
                                 src={
                                    SERVER_URL +
                                    "/cardimages/png/" +
                                    encodeURIComponent(data.value) +
                                    ".png"
                                 }
                                 width='100%'
                                 style={{
                                    display: "block",
                                 }}
                              />
                           )}
                        </Stack>
                     </Box>
                  )}
               </AnimatePresence>

               <AnimatePresence>
                  <Box
                     key={"logo"}
                     component={motion.div}
                     initial={{ scale: 1.2, opacity: 0, y: 0 }}
                     animate={{
                        scale: data && visible ? 0.9 : 1,
                        opacity: 1,
                        y: data && visible ? 390 : 0,
                     }}
                     exit={{ scale: 1.2, opacity: 0, y: 0 }}
                     transition={{ easing: "easeInOut", delay: 0 }}
                     sx={{
                        position: "absolute",
                        width: 356,
                        height: 450,
                        top: 315,
                        zIndex: 500,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/ui/magicon.png"}
                        width='90%'
                        style={{
                           position: "absolute",
                           top: "50%",
                           left: "50%",
                           transform: "translateX(-50%) translateY(-50%)",
                        }}
                     />
                  </Box>
               </AnimatePresence>

               <Box
                  sx={{
                     position: "absolute",
                     width: 356,
                     height: 1080,
                     top: 0,
                     left: 0,
                     zIndex: 10,
                     backgroundImage:
                        "url('" +
                        SERVER_URL +
                        "/images/ui/overlay-frame-draft.png')",
                  }}
               ></Box>

               <AnimatePresence>
                  {data && visible && (
                     <Box
                        key={"backgroundImage"}
                        component={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, transition: { delay: 0.3 } }}
                        transition={{ easing: "easeInOut" }}
                        sx={{
                           position: "absolute",
                           width: 339,
                           height: 512,
                           top: 284,
                           left: 9,
                           zIndex: 100,
                           background:
                              "url(" +
                              SERVER_URL +
                              "/images/ui/overlay-center-cover.png" +
                              ")",
                        }}
                     />
                  )}
               </AnimatePresence>
            </WrapperViewer>
         </Box>
      </>
   );
}
