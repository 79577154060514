import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CustomLeaderboardPreview({
   currentPage,
   activeData,
   show,
   runTimestamp,
   preview,
}) {
   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <AnimatePresence>
            {show && activeData && (
               <Stack
                  sx={{
                     position: "absolute",
                     top: 40,
                     left: 100,
                     height: 200,
                  }}
                  justifyContent='center'
                  key={activeData.key + "-title"}
                  component={motion.div}
                  initial={{ opacity: 0, scale: 1.1 }}
                  animate={{
                     opacity: 1,
                     scale: 1,
                  }}
                  exit={{
                     scale: 1.1,
                     opacity: 0,
                  }}
                  transition={{
                     easing: "easeOut",
                     duration: preview ? 0 : 0.4,
                  }}
               >
                  <Typography
                     variant='h1Viewer'
                     component='div'
                     sx={{ textTransform: "uppercase" }}
                  >
                     {activeData && activeData.Title}
                  </Typography>

                  <Typography
                     variant='h2Viewer'
                     component='div'
                     sx={{ textTransform: "uppercase" }}
                  >
                     {activeData && activeData.Round}
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {show &&
               activeData &&
               activeData.rows &&
               activeData.rows.length > 0 && (
                  <Stack
                     direction='row'
                     alignItems='flex-start'
                     spacing={3}
                     sx={{
                        position: "absolute",
                        width: 1720,
                        top: activeData.Rows > 9 ? 240 : 260,
                        left: 100,
                     }}
                     key={activeData.key + "-p" + currentPage}
                     component={motion.div}
                     initial={{ opacity: 0, x: 100 }}
                     animate={{
                        opacity: 1,
                        x: 0,
                        transition: { delay: 0.2 },
                     }}
                     exit={{
                        x: -100,
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: preview ? 0 : 0.3,
                     }}
                  >
                     {_.range(activeData.Columns).map((renderCol) => (
                        <Table variant='standings'>
                           <TableHead>
                              <TableRow>
                                 {activeData.rows[0].Rank && (
                                    <TableCell
                                       width={
                                          activeData.rows[0].Rank != 0
                                             ? "10%"
                                             : "2%"
                                       }
                                       sx={{ textAlign: "center" }}
                                    >
                                       {activeData.rows[0].Rank != 0
                                          ? "RANK"
                                          : ""}
                                    </TableCell>
                                 )}
                                 <TableCell
                                    sx={{
                                       pl: !activeData.rows[0].Rank ? 10 : 0,
                                    }}
                                 >
                                    NAME
                                 </TableCell>
                                 {activeData.Archetype &&
                                    activeData.Columns < 2 && (
                                       <TableCell
                                          width='28%'
                                          sx={{ textAlign: "center" }}
                                       >
                                          ARCHETYPE
                                       </TableCell>
                                    )}
                                 {activeData.rows[0].MatchWins >= 0 && (
                                    <>
                                       <TableCell
                                          width='6%'
                                          sx={{ textAlign: "center" }}
                                       >
                                          WIN
                                       </TableCell>
                                       <TableCell
                                          width='6%'
                                          sx={{ textAlign: "center" }}
                                       >
                                          LOSS
                                       </TableCell>
                                       <TableCell
                                          width='6%'
                                          sx={{ textAlign: "center" }}
                                       >
                                          DRAW
                                       </TableCell>
                                    </>
                                 )}
                              </TableRow>
                           </TableHead>
                           <TableBody>
                              {activeData.rows
                                 .slice(
                                    activeData.Columns *
                                       currentPage *
                                       activeData.Rows +
                                       renderCol * activeData.Rows,
                                    activeData.Columns *
                                       currentPage *
                                       activeData.Rows +
                                       activeData.Rows +
                                       renderCol * activeData.Rows
                                 )
                                 .map((row, i) => (
                                    <React.Fragment
                                       key={"column-" + renderCol + "-" + i}
                                    >
                                       <TableRow>
                                          {activeData.rows[0].Rank && (
                                             <TableCell
                                                sx={{ textAlign: "center" }}
                                             >
                                                {activeData.rows[0].Rank != 0
                                                   ? row.Rank
                                                   : ""}
                                             </TableCell>
                                          )}
                                          <TableCell
                                             sx={{
                                                width: activeData.Archetype
                                                   ? "auto"
                                                   : "40%",
                                                position: "relative",
                                                overflow: "hidden",
                                                pl: !activeData.rows[0].Rank
                                                   ? 10
                                                   : 0,
                                             }}
                                          >
                                             <Box
                                                className='fadeAway'
                                                sx={{
                                                   position: "absolute",
                                                   top: 0,
                                                   right: -2,
                                                   width: "100%",
                                                   height: "100%",
                                                   zIndex: 100,
                                                }}
                                             />
                                             <Stack
                                                direction='row'
                                                spacing={2}
                                                justifyContent='space-between'
                                                sx={{
                                                   position: "relative",
                                                   textTransform: "uppercase",
                                                }}
                                             >
                                                <Box
                                                   sx={{
                                                      width: 50,
                                                      height: 32,
                                                      fontSize: 10,
                                                      textAlign: "center",
                                                   }}
                                                >
                                                   {row.CountryFlag && (
                                                      <Box
                                                         sx={{
                                                            width: "100%",
                                                            height: "100%",
                                                            background:
                                                               "url(" +
                                                               row.CountryFlag +
                                                               ") center center",
                                                            backgroundSize:
                                                               "contain",
                                                            backgroundRepeat:
                                                               "no-repeat",
                                                         }}
                                                      />
                                                   )}
                                                   {!row.CountryFlag && (
                                                      <>{row.Country}</>
                                                   )}
                                                </Box>
                                                <Box
                                                   sx={{
                                                      position: "absolute",
                                                      left: 50,
                                                      width: "100%",
                                                      whiteSpace: "nowrap",
                                                      top: "50%",
                                                      transform:
                                                         "translateY(-50%)",
                                                   }}
                                                >
                                                   <span>{row.PlayerName}</span>
                                                </Box>
                                                {row.HOF == 1 && (
                                                   <Box
                                                      sx={{
                                                         width: 100,
                                                         position: "relative",
                                                         marginRight: 20,
                                                      }}
                                                   >
                                                      <img
                                                         src={
                                                            SERVER_URL +
                                                            "/images/icons/hof.png"
                                                         }
                                                         style={{
                                                            width: 60,
                                                            position:
                                                               "absolute",
                                                            top: "50%",
                                                            transform:
                                                               "translateY(-50%)",
                                                         }}
                                                      />
                                                   </Box>
                                                )}
                                             </Stack>
                                          </TableCell>
                                          {activeData.Archetype &&
                                             activeData.Columns < 2 && (
                                                <TableCell
                                                   sx={{ textAlign: "center" }}
                                                >
                                                   {row.DecklistArchetype}
                                                </TableCell>
                                             )}
                                          {activeData.rows[0].MatchWins >=
                                             0 && (
                                             <>
                                                <TableCell
                                                   sx={{ textAlign: "center" }}
                                                >
                                                   {row.MatchWins}
                                                </TableCell>
                                                <TableCell
                                                   sx={{ textAlign: "center" }}
                                                >
                                                   {row.MatchLosses}
                                                </TableCell>
                                                <TableCell
                                                   sx={{ textAlign: "center" }}
                                                >
                                                   {row.MatchDraws}
                                                </TableCell>
                                             </>
                                          )}
                                       </TableRow>
                                    </React.Fragment>
                                 ))}
                           </TableBody>
                        </Table>
                     ))}
                  </Stack>
               )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
