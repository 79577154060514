import React, { useState, useContext, useEffect } from "react";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import CloseIcon from "@mui/icons-material/Close";

import CardImage from "./CardImage";
import ManaIconsFormatted from "../decklists/ManaIconsFormatted";

export default function ReplaceCard({
   allCardNames,
   draftFormats,
   replaceId,
   replacePick,
   close,
}) {
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(true);

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCardNames.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const replaceCard = () => {
      console.log("replace");
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   return (
      <>
         <IconButton
            aria-label='close'
            color='error'
            sx={{ position: "absolute", top: 10, right: 10, zIndex: 100 }}
            onClick={close}
         >
            <CloseIcon />
         </IconButton>
         <Stack spacing={2} sx={{ maxWidth: 600, margin: "0 auto" }}>
            <Box sx={{ mb: 2 }}>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                     backgroundColor: "#ccc",
                     color: "#333",
                     py: 1.5,
                     mb: 2,
                  }}
               >
                  REPLACE CARD {replaceId}
               </Typography>
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     textAlign: "center",
                     textTransform: "uppercase",
                  }}
               >
                  Max. Result 30 cards — type at least 3 characters
               </Typography>
            </Box>

            <Box>
               <TextField
                  fullWidth
                  placeholder='Enter text here'
                  value={searchValue}
                  inputProps={{
                     style: { fontSize: 24, textAlign: "center" },
                  }}
                  onClick={(event) => {
                     event.target.select();
                  }}
                  onChange={handleSearch}
               />
            </Box>

            <Stack
               direction='row'
               alignItems='stretch'
               justifyContent='center'
               sx={{ color: "#fff", my: 2, width: "100%" }}
               spacing={2}
            >
               <Button
                  onClick={toggleFilterSets}
                  size='small'
                  variant={filterSets ? "contained" : "outlined"}
                  color={filterSets ? "error" : "secondary"}
                  fullWidth
               >
                  DRAFT MODE
                  <br />({draftFormats && draftFormats.join(", ")})
               </Button>
               <Typography
                  variant='h5'
                  component='div'
                  sx={{
                     py: 2,
                     textAlign: "center",
                     textTransform: "uppercase",
                  }}
               >
                  {searchResults.length} Results
               </Typography>

               <Button
                  onClick={() => {
                     setSearchValue("");
                     setSearchResults([]);
                  }}
                  sx={{ ml: 4 }}
                  size='small'
                  variant='outlined'
                  color='error'
               >
                  CLEAR
               </Button>
            </Stack>
            <Stack spacing={1}>
               {searchResults &&
                  searchResults.length > 0 &&
                  searchResults.map((card, i) => (
                     <Stack
                        spacing={2}
                        direction='row'
                        key={"searchResult" + i}
                     >
                        <Box
                           sx={{
                              fontSize: ".8em",
                              backgroundColor: "#333",
                              color: "#ccc",
                              width: "85%",
                              borderRadius: 1,
                           }}
                           key={"cardSearchRow-" + card.id}
                        >
                           <Stack
                              direction='row'
                              sx={{ width: "100%" }}
                              alignItems='center'
                              justifyContent='flex-end'
                              spacing={3}
                           >
                              <Box
                                 sx={{
                                    width: 100,
                                    height: 70,
                                    position: "relative",
                                    overflow: "hidden",
                                 }}
                              >
                                 <CardImage
                                    key={"cardSearchImage-" + card.ID}
                                    id={card.CardSearchString}
                                    name={card.CardName}
                                    borderRadius={0.5}
                                    showName={false}
                                    fileName={card.FileName}
                                 />
                              </Box>
                              <Stack
                                 spacing={0.5}
                                 sx={{
                                    width: "100%",
                                 }}
                              >
                                 <Box
                                    sx={{
                                       width: "100%",
                                       textAlign: "left",
                                       fontWeight: "bold",
                                    }}
                                 >
                                    {card.CardName}
                                 </Box>
                                 <ManaIconsFormatted
                                    iconWidth={12}
                                    mana={card.CardManaCost}
                                 />
                              </Stack>
                           </Stack>
                        </Box>
                        <Button
                           id={card.CardSearchString}
                           variant='contained'
                           color='success'
                           onClick={() => {
                              replacePick(replaceId, card);
                           }}
                           sx={{ width: "15%" }}
                        >
                           Replace
                        </Button>
                     </Stack>
                  ))}
            </Stack>

            <Button
               onClick={close}
               variant='outlined'
               color='secondary'
               size='large'
               sx={{ mr: 2 }}
            >
               CANCEL
            </Button>
         </Stack>
      </>
   );
}
