import { useState, useMemo, useContext, useRef, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useCustomLeaderboard() {
   const socket = useContext(SocketContext);

   const [notification, setNotification] = useState();
   const [all, setAll] = useState();
   const [activeData, setActiveData] = useState();
   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());
   const [allPlayers, setAllPlayers] = useState([]);
   const [currentPage, setCurrentPage] = useState(0);
   const maxPage = useRef(0);

   useEffect(() => {
      if (activeData && activeData.rows) {
         const totalRows = activeData.rows.length;
         const rowsPerPage = activeData.Rows * activeData.Columns;
         const pagesFrac = totalRows / rowsPerPage;

         if (pagesFrac == 1) {
            maxPage.current = 0;
         } else {
            maxPage.current = Math.floor(pagesFrac);
         }
         setCurrentPage(0);
      }
   }, [activeData]);

   useEffect(() => {
      // console.log(maxPage.current);
   }, [maxPage.current]);

   useEffect(() => {
      // console.log(currentPage);
   }, [currentPage]);

   const handlers = useMemo(
      () => ({
         loadAllPlayers: () => {
            socket.emit("getAllCustomLeaderboardPlayers", (response) => {
               setAllPlayers(response);
            });
         },
         update: (id, updates) => {
            socket.emit("updateCustomLeaderboard", id, updates, (response) => {
               handlers.get(id);
               handlers.getAll();
            });
         },
         viewerLoad: (id) => {
            socket.emit("customLeaderboardViewerLoad", id);
         },
         viewerShowHide: (action) => {
            socket.emit("customLeaderboardViewerShowHide", action);
         },
         get: (id) => {
            socket.emit("getCustomLeaderboard", id, (response) => {
               setActiveData(response);
            });
         },
         getAll: () => {
            socket.emit("getAllCustomLeaderboard", (response) => {
               // console.log(response);
               setAll(response);
            });
         },
         deleteLeaderboard: (id) => {
            socket.emit("deleteCustomLeaderboard", id, (response) => {
               setAll(response);
            });
         },
         add: (callback) => {
            socket.emit("addCustomLeaderboard", (response) => {
               handlers.getAll();
            });
         },
         setPage: (action) => {
            socket.emit("customLeaderboardSetPage", action);
         },
         updatePage: (action) => {
            switch (action) {
               case "next":
                  setCurrentPage((prevState) =>
                     Math.min(maxPage.current, prevState + 1)
                  );
                  break;
               case "prev":
                  setCurrentPage((prevState) => Math.max(prevState - 1, 0));
                  break;
               case "first":
                  setCurrentPage(0);
                  break;
            }
         },
         subscribeViewer: () => {
            socket.emit("subscribeCustomLeaderboardViewer", (response) => {
               // console.log(response);
            });

            socket.on("customLeaderboardViewerLoad", (id) => {
               handlers.get(id);
            });

            socket.on("customLeaderboardSetPage", (action) => {
               handlers.updatePage(action);
            });

            socket.on("customLeaderboardViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setRunTimestamp(Date.now());
                     setShow(true);
                     break;
                  case "hide":
                     setShow((prevState) => {
                        return false;
                     });
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      [socket]
   );

   return [
      notification,
      all,
      activeData,
      show,
      runTimestamp,
      allPlayers,
      currentPage,
      handlers,
   ];
}
