import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export default function PlayerDeckSelect({ selected, slot, callback }) {
   const handleChange = (e) => {
      if (e.target.value) {
         callback(slot, e.target.value);
      }
   };

   return (
      <>
         {selected && (
            <Stack
               sx={{
                  backgroundColor: selected !== "0" ? "#fff" : "#ffaaaa",
                  p: 3,
                  width: "100%",
               }}
               spacing={2}
            >
               <FormControl fullWidth>
                  <InputLabel id={"playerSelect"}>
                     Player {slot} Deck
                  </InputLabel>
                  <Select
                     value={selected.decklistId ? selected.decklistId : 0}
                     label={"Player " + slot + " Deck"}
                     onChange={handleChange}
                  >
                     <MenuItem value='0'>Hide Deck</MenuItem>
                     {selected &&
                        selected.Decklists &&
                        selected.Decklists.map((deck) => (
                           <MenuItem key={deck.ID} value={deck.ID}>
                              {deck.Name}
                           </MenuItem>
                        ))}
                  </Select>
               </FormControl>
            </Stack>
         )}
      </>
   );
}
