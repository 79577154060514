import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useTheme } from "@mui/material/styles";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PlayerFlag({ player, gameState }) {
   const theme = useTheme();

   return (
      <>
         {player && player.CountryFlag && (
            <Box
               key={"playerId" + player.id + "_" + player.Country}
               component={motion.div}
               initial={{ opacity: 0 }}
               animate={{
                  opacity: 1,
                  transition: { delay: 0.2 },
               }}
               exit={{ opacity: 0 }}
               sx={{
                  width: 61,
                  height: 35,
                  backgroundImage:
                     "url('" + SERVER_URL + "/images/ui/border-flag.png')",
                  pl: "3px",
                  pt: "3px",
               }}
            >
               <Box
                  sx={{
                     width: 55,
                     height: 29,
                     backgroundRepeat: "no-repeat",
                     backgroundSize: "cover",
                     backgroundPosition: "center",
                     backgroundImage:
                        "url('" +
                        SERVER_URL +
                        "/images/flags/" +
                        player.CountryFlag.code +
                        ".png')",
                  }}
               />
            </Box>
         )}
      </>
   );
}
