import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";
import DebugOverlay from "../../components/widgets/DebugOverlay";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function FeatureCardsPreview({ activeData, show, preview }) {
   return (
      <WrapperViewer>
         <AnimatePresence>
         
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img
               src={SERVER_URL + "/images/ui/logo-800.png"}
               width='125'
            />
         </Box>
         
            {show && activeData && (
               <Stack
                  sx={{
                     position: "absolute",
                     top: 0,
                     left: 100,
                     width: 1720,
                     height: 200,
                  }}
                  alignItems='center'
                  justifyContent='center'
                  key={activeData.title}
                  component={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{
                     opacity: 1,
                     transition: { delay: 0.5 },
                  }}
                  exit={{
                     opacity: 0,
                  }}
                  transition={{
                     easing: "easeOut",
                     duration: preview ? 0 : 0.5,
                  }}
               >
                  <Typography
                     variant='h1Viewer'
                     component='div'
                     sx={{ textTransform: "uppercase" }}
                  >
                     {activeData.title}
                  </Typography>
               </Stack>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {show &&
               activeData &&
               activeData.cards &&
               activeData.cards.length > 0 && (
                  <Stack
                     direction='row'
                     alignItems='center'
                     justifyContent='center'
                     spacing={3}
                     sx={{
                        position: "absolute",
                        width: 1720,
                        height: 750,
                        top: 220 ,
                        left: 100,
                     }}
                     key={
                        activeData.id +
                        "-" +
                        activeData.title +
                        "-" +
                        activeData.cards
                     }
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                        transition: { delay: 0.5 },
                     }}
                     exit={{
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: preview ? 0 : 0.5,
                     }}
                  >
                     {activeData.cards.map((card) => (
                        <Box
                           sx={{
                              width: activeData.cards.length < 3 ? 600 : "100%",
                              px: card.rotate
                                 ? 5 / activeData.cards.length != 5
                                    ? (5 / activeData.cards.length) * 7
                                    : 0
                                 : 2,
                              transform: card.rotate
                                 ? "rotate(" + card.rotate + "deg)"
                                 : "",
                           }}
                        >
                           <Box
                              sx={{
                                 overflow: "hidden",
                              }}
                           >
                              <img
                                 src={
                                    SERVER_URL +
                                    "/cardimages/png/" +
                                    encodeURIComponent(card.FileName) +
                                    ".png"
                                 }
                                 width='100%'
                                 style={{
                                    display: "block",
                                 }}
                              />
                           </Box>
                        </Box>
                     ))}
                  </Stack>
               )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
