import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

//HOOKS
import useStandingsHandler from "../../hooks/useStandingsHandler";

export default function StandingsSelect({ selected, callback }) {
   const [
      standings,
      setStandings,
      cachedStandings,
      tournamentRounds,
      notification,
      { getCachedStandings, getStandings, getTournamentRounds },
   ] = useStandingsHandler();

   useEffect(() => {
      getCachedStandings();
   }, []);

   const handleChange = (e) => {
      if (e.target.name && e.target.value) {
         callback({ id: e.target.name, value: e.target.value });
      }
   };

   return (
      <>
         {cachedStandings.length > 0 && (
            <Box sx={{ py: 2, px: 4, backgroundColor: "#fff", width: "100%" }}>
               <FormControl fullWidth sx={{ maxWidth: 400 }}>
                  <InputLabel id={"StandingsSelect"}>Standings</InputLabel>
                  <Select
                     value={selected !== 0 ? selected : 0}
                     label={"Standings"}
                     name={"StandingsId"}
                     onChange={handleChange}
                  >
                     <MenuItem value='0'>Most recent Standings</MenuItem>
                     {cachedStandings.map((standings) => (
                        <MenuItem
                           key={standings.RoundId}
                           value={standings.RoundId}
                        >
                           {standings.description}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Box>
         )}
      </>
   );
}
