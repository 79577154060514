import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export default function TableHistorySelect({
   tableHistory,
   table,
   selected,
   callback,
}) {
   const handleChange = (e) => {
      if (e.target.name && e.target.value) {
         callback({
            id: e.target.name,
            value: tableHistory.filter(
               (table) => table.id === e.target.value
            )[0],
         });
      }
   };

   const convertTableName = (table) => {
      return ["A", "B", "C", "D"][table - 1];
   };

   return (
      <>
         {tableHistory && (
            <>
               <Box
                  sx={{ py: 2, px: 4, backgroundColor: "#fff", width: "100%" }}
               >
                  <Typography
                     variant='h5'
                     component='div'
                     sx={{ color: "#333", mb: 2 }}
                  >
                     Table {convertTableName(table)}
                  </Typography>
                  <FormControl fullWidth sx={{ maxWidth: 400 }}>
                     <InputLabel id={"TableHistorySelect"}>
                        Select Data
                     </InputLabel>
                     <Select
                        value={selected !== 0 ? selected : 0}
                        label={"TableHistory"}
                        name={table}
                        onChange={handleChange}
                     >
                        <MenuItem value='0'>--</MenuItem>
                        {_.filter(tableHistory, {
                           table: "Table " + convertTableName(table),
                        }).map((data) => (
                           <MenuItem key={data.id} value={data.id}>
                              {data.title ? data.title + " | " : ""}
                              {data.player1
                                 ? data.player1.LastName
                                 : "None"}{" "}
                              vs.{" "}
                              {data.player2 ? data.player2.LastName : "None"}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Box>
            </>
         )}
      </>
   );
}
