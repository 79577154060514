import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

function SubscribeDraft() {
   const socket = useContext(SocketContext);

   const [player, setPlayer] = useState();
   const [allPlayers, setAllPlayers] = useState();
   const [allCardNames, setAllCardNames] = useState();
   const [gameState, setGameState] = useState();
   const [picks, setPicks] = useState();
   const [lastUpdated, setLastUpdated] = useState(0);

   const [data, setData] = useState();
   const [loaded, setLoaded] = useState(false);
   const [visible, setVisible] = useState(false);
   const [rotation, setRotation] = useState(0);

   useEffect(() => {
      // console.log(picks);
   }, [picks]);

   const update = (draftId, updates) => {
      socket.emit("updateDraft", draftId, updates);
   };

   const handlers = useMemo(
      () => ({
         resetDraft: (draftId) => {
            socket.emit("resetDraft", draftId);
            socket.emit("toggleDraftVisibility", false);
            socket.emit("draftOverlayDataLoad", null);
         },
         subscribeDraft: (draftId) => {
            socket.emit("subscribeDraft", draftId, (response) => {
               setGameState(response.gamestate);
               setPlayer(response.player);
               setPicks(response.draftPicks);
            });

            socket.on("refreshFMPhotos", (player) => {
               setPlayer(player);
               setLastUpdated(Date.now());
            });

            socket.on("draftPicks", (picks) => {
               setPicks(picks);
            });

            socket.on("draftOverlayDataLoad", (data) => {
               // console.log(data);
               setData(data);
            });

            socket.on("toggleDraftVisibility", (visibility) => {
               // console.log("Visibility: " + visibility);
               setVisible(visibility);
            });

            socket.on("toggleDraftRotation", (rotation) => {
               // console.log(rotation);
               setRotation(rotation);
            });

            socket.on("dataUpdate", (data) => {
               if (data.type === "gamestate" && data.json) {
                  setGameState(data.json);
               }

               if (data.type === "player" && data.json) {
                  setPlayer(data.json);
               }
            });
         },
         addPick: (draftId, card) => {
            socket.emit("addDraftPick", draftId, card);
         },
         replacePick: (draftId, replaceId, card) => {
            socket.emit("replaceDraftPick", draftId, replaceId, card);
         },
         removePick: (draftId, id) => {
            socket.emit("removeDraftPick", draftId, id);
         },
         getAllPlayers: () => {
            socket.emit("getAllPlayers", (response) => {
               setAllPlayers(response);
            });
         },
         getAllCardNames: () => {
            socket.emit("getAllCardNames", (response) => {
               setAllCardNames(response);
            });
         },
         getDraftData: (draftId) => {
            socket.emit("getDraftData", draftId, (response) => {
               setGameState(response);
            });
         },
         toggleDraftVisibility: (visibility) => {
            socket.emit("toggleDraftVisibility", visibility);
         },
         toggleDraftRotation: (rotation) => {
            socket.emit("toggleDraftRotation", rotation);
         },
         draftOverlayDataLoad: (data) => {
            handlers.toggleDraftRotation(0);

            if (typeof data !== "object") {
               data = JSON.parse(data);
            }
            socket.emit("draftOverlayDataLoad", data);
         },
      }),
      [socket]
   );

   return [
      player,
      allPlayers,
      allCardNames,
      gameState,
      picks,
      update,
      lastUpdated,
      data,
      loaded,
      setLoaded,
      visible,
      rotation,
      handlers,
   ];
}

export default SubscribeDraft;
