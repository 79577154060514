import React, { useState, useEffect } from "react";

import {
   Box,
   Button,
   FormControl,
   InputLabel,
   Stack,
   Select,
   MenuItem,
} from "@mui/material";
import { Edit, Save } from "@mui/icons-material";

export default function SelectLowerThirdName({
   size,
   direction = "column",
   label,
   placeholder,
   value,
   menuOptionKey,
   options,
   callback,
}) {
   const [currentValue, setCurrentValue] = useState(value);

   useEffect(() => {
      if (currentValue != value) {
         console.log("current value set to " + value);
         setCurrentValue(value);
      }
   }, [value]);

   const updateValue = (e) => {
      setCurrentValue(e.target.value);
   };

   useEffect(() => {
      if (value != currentValue) {
         callback(currentValue);
      }
   }, [currentValue]);

   return (
      <Stack
         spacing={2}
         direction={direction}
         alignItems='flex-start'
         sx={{ width: "100%" }}
      >
         {/* <Box
            sx={{
               fontWeight: "bold",
               fontSize: "1.2em",
               textTransform: "uppercase",
               width: 100,
            }}
         >
            {label}
         </Box> */}
         <Stack
            direction='row'
            spacing={1}
            sx={{ width: "100%" }}
            alignItems='center'
         >
            <FormControl variant='filled' fullWidth>
               <InputLabel>{label}</InputLabel>
               <Select
                  label={label}
                  value={currentValue}
                  onChange={updateValue}
                  size={size}
                  sx={{
                     backgroundColor: currentValue ? "#fff" : "rgba(0,0,0,.2)",
                  }}
                  fullWidth
               >
                  {options.map((option) => (
                     <MenuItem key={label + "-" + option.id} value={option.id}>
                        {menuOptionKey ? option[menuOptionKey] : option.title}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Stack>
      </Stack>
   );
}
