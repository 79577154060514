import React, { useState, useContext, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function AdjustDraftTitle({ curRound = 1, save, cancel }) {
   // VALUES
   const [roundNum, setRoundNum] = useState(curRound);
   const [roundTitle, setRoundTitle] = useState("");

   const updateRoundTitle = () => {
      save(roundTitle);
   };

   useEffect(() => {
      setRoundTitle("Day " + roundNum + " Draft");
   }, [roundNum]);

   const decrement = (amount) => {
      setRoundNum((prevState) => {
         let newState = prevState;
         newState = Math.max(newState - amount, 1);
         return newState;
      });
   };

   const increment = (amount) => {
      setRoundNum((prevState) => {
         let newState = prevState;
         newState = newState + amount;
         return newState;
      });
   };

   const reset = (amount) => {
      setRoundNum(1);
   };

   return (
      <Grid
         container
         alignItems='stretch'
         justifyContent='center'
         sx={{ textAlign: "center" }}
      >
         <Grid
            item
            xs={12}
            sx={{
               backgroundColor: "#222",
               color: "#fff",
               p: 2,
            }}
         >
            <Typography
               variant='h5'
               color='text.dark'
               component='div'
               sx={{ mb: 1 }}
            >
               TITLE
            </Typography>
            <Typography variant='h4' color='text.white' component='div'>
               Day {roundNum} Draft
            </Typography>
         </Grid>
         <Grid item xs={12} sx={{ p: 2, backgroundColor: "#444" }}>
            <Stack spacing={0} alignItems='center'>
               <Typography
                  variant='h5'
                  color='text.dark'
                  component='div'
                  sx={{ mb: 2 }}
               >
                  TITLE
               </Typography>
               <Stack direction='row' spacing={1}>
                  <Button
                     key={"decrement"}
                     variant='outlined'
                     onClick={() => decrement(1)}
                  >
                     -1
                  </Button>

                  <Typography
                     variant='h3'
                     color='text.primary'
                     component='div'
                     sx={{
                        mb: 2,
                        width: 100,
                        textAlign: "center",
                        color: "#fff",
                     }}
                  >
                     {roundNum}
                  </Typography>

                  <Button
                     key={"increment"}
                     variant='outlined'
                     onClick={() => increment(1)}
                  >
                     +1
                  </Button>
               </Stack>

               <Button
                  size='small'
                  variant='outlined'
                  color='alert'
                  onClick={() => reset()}
                  sx={{ mt: 1 }}
               >
                  RESET
               </Button>
            </Stack>
         </Grid>

         <Grid item xs={12} sx={{ pt: 4 }}>
            <Button
               onClick={updateRoundTitle}
               variant='outlined'
               color='secondary'
               size='large'
               sx={{ mr: 2 }}
               onClick={cancel}
            >
               CANCEL
            </Button>

            <Button
               onClick={updateRoundTitle}
               variant='outlined'
               color='error'
               size='large'
            >
               SAVE
            </Button>
         </Grid>
      </Grid>
   );
}
