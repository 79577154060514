import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ManaIcons from "../../components/decklists/ManaIcons";
import WrapperViewer from "../../components/layout/WrapperViewer";

import PlayerShield from "../../components/playerprofiles/PlayerShield";
import PlayerData from "../../components/playerprofiles/PlayerData";
import PlayerDecklists from "../../components/playerprofiles/PlayerDecklists";
import PlayerBulletPoints from "../../components/playerprofiles/PlayerBulletPoints";
import PlayerPhoto from "../../components/playerprofiles/PlayerPhoto";

// IMAGES
import playerShield from "../../images/frame-playershield.png";
import playerShieldFlagBkgnd from "../../images/frame-playershield-flag-bkgnd.jpg";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function ProfileViewer({ data, show }) {
   const theme = useTheme();

   useEffect(() => {
      // console.log(data);
   }, [data]);

   const forceLineBreak = (text) => {
      if (text) {
         let textArray = text.split(" ");
         let divider = textArray.length === 2 ? "<br />" : " ";
         let response = textArray.join(divider);
         return <div dangerouslySetInnerHTML={{ __html: response }} />;
      }
   };

   return (
      <WrapperViewer>
         {/* LOGO */}

         <Box
            key='logo'
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            component={motion.div}
            sx={{
               position: "absolute",
               bottom: 40,
               left: 100,
               zIndex: 100,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='140' />
         </Box>

         {/* PLAYER PROFILE */}
         <AnimatePresence>
            {data && show && (
               <React.Fragment key={"slide" + data.id}>
                  <Box
                     initial={{ opacity: 0, x: -100 }}
                     animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                           delay: 0.1,
                           easing: "easeOut",
                           duration: 0.6,
                        },
                     }}
                     exit={{
                        opacity: 0,
                        x: -100,
                        transition: {
                           delay: 0.1,
                           easing: "easeIn",
                           duration: 0.3,
                        },
                     }}
                     component={motion.div}
                     sx={{
                        position: "absolute",
                        top: 50,
                        left: 20,
                        zIndex: 100,
                     }}
                  >
                     <PlayerShield data={data.PlayerData} />
                     <PlayerPhoto photo={data.PlayerPhoto} />
                  </Box>

                  <Stack
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                        transition: {
                           delay: 0.5,
                           easing: "easeOut",
                           duration: 0.4,
                        },
                     }}
                     exit={{
                        opacity: 0,
                        transition: {
                           delay: 0,
                           easing: "easeOut",
                           duration: 0.2,
                        },
                     }}
                     component={motion.div}
                     sx={{
                        position: "absolute",
                        top: 150,
                        left: 415,
                        width: 1350,
                        height: 780,
                        background: theme.palette.background.light,
                        zIndex: 10,
                     }}
                  >
                     <Box
                        sx={{
                           background: theme.palette.background.gradient,
                           pt: 1.3,
                           pb: 0.9,
                           pl: 46,
                        }}
                     >
                        {/* TITLE */}
                        <Typography
                           variant='h3profile'
                           component='div'
                           sx={{ textTransform: "uppercase" }}
                        >
                           PLAYER PROFILE
                        </Typography>
                     </Box>

                     <Stack
                        spacing={0}
                        justifyContent='flex-start'
                        alignItems='center'
                        sx={{
                           position: "absolute",
                           top: 90,
                           right: 0,
                           width: 97,
                           height: 200,
                           zIndex: 50,
                           transform: "translateX(-50px)",
                           backgroundSize: "100% 100%",
                           backgroundPosition: "center",
                           p: "4px",
                        }}
                     >
                        {data &&
                           data.PlayerData &&
                           data.PlayerData.HOF &&
                           parseInt(data.PlayerData.HOF) === 1 && (
                              <Stack
                                 justifyContent='center'
                                 alignItems='center'
                                 sx={{ height: 80 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/icons/hof.png"}
                                    width='80'
                                 />
                              </Stack>
                           )}
                        {data &&
                           data.PlayerData &&
                           data.PlayerData.WORLDS &&
                           parseInt(data.PlayerData.WORLDS) === 1 && (
                              <Stack
                                 justifyContent='center'
                                 alignItems='center'
                                 sx={{ height: 80 }}
                              >
                                 <img
                                    src={SERVER_URL + "/images/icons/wc.png"}
                                    width='80'
                                 />
                              </Stack>
                           )}
                     </Stack>

                     <Box
                        sx={{
                           py: 5,
                           pl: 46,
                           pr: 7.2,
                        }}
                     >
                        {/* PLAYER NAME */}
                        <Typography
                           variant='h1profile'
                           component='div'
                           sx={{
                              textTransform: "uppercase",
                              width: "90%",
                           }}
                        >
                           {data.PlayerData &&
                              forceLineBreak(data.PlayerData.PlayerName)}
                        </Typography>

                        <Typography
                           variant='h2profile'
                           component='div'
                           sx={{ position: "absolute" }}
                        >
                           {data.PlayerData &&
                              data.PlayerData.TwitterHandle &&
                              data.PlayerData.TwitterHandle}
                        </Typography>

                        {/* DATA POINTS */}
                        <Stack
                           spacing={1}
                           justifyContent='center'
                           alignItems='center'
                           sx={{
                              mt: 3,
                              height: 250,
                              width: "100%",
                              textAlign: "center",
                           }}
                        >
                           <PlayerData
                              data={data.PlayerData}
                              dataFields={data.DataFields}
                           />
                        </Stack>
                     </Box>
                     <Box
                        sx={{
                           background: theme.palette.background.gradientDark,
                           py: 1.5,
                           pl: 46,
                           position: "absolute",
                           bottom: 0,
                           left: 0,
                           width: "100%",
                           height: 250,
                        }}
                     >
                        {!data.PlayerDecklist && (
                           <Stack
                              direction='row'
                              spacing={8}
                              justifyContent='center'
                              alignItems='center'
                              sx={{
                                 pr: 8,
                                 height: "100%",
                                 width: "100%",
                                 textAlign: "center",
                              }}
                           >
                              <PlayerBulletPoints data={data} />
                           </Stack>
                        )}

                        {/* PLAYER DECKLISTS */}
                        <Stack
                           direction='row'
                           spacing={8}
                           justifyContent='center'
                           alignItems='center'
                           sx={{
                              pr: 8,
                              height: "100%",
                              width: "100%",
                              textAlign: "center",
                           }}
                        >
                           <PlayerDecklists data={data.PlayerDecklist} />
                        </Stack>
                     </Box>
                  </Stack>
               </React.Fragment>
            )}
         </AnimatePresence>
      </WrapperViewer>

      // <Box
      //    id='background'
      //    sx={{
      //       position: "absolute",
      //       top: 0,
      //       left: 0,
      //       width: 1920,
      //       height: 1080,
      //       zIndex: -1,
      //    }}
      //    className={"transparentBG"}
      // />
   );
}
