import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import SortIcon from "@mui/icons-material/Sort";
import TextField from "@mui/material/TextField";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import TextInput from "../inputs/TextInput";
import SelectLowerThirdName from "../inputs/SelectLowerThirdName";
import SelectInput from "../inputs/SelectInput";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
   [`&.${tableCellClasses.head}`]: {
      color: "#fff",
      fontWeight: "bold",
   },
   [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
   },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
   "&:nth-of-type(odd)": {
      backgroundColor: "rgba(255,255,255,.1)",
   },
   // hide last border
   "&:last-child td, &:last-child th": {
      border: 0,
   },
}));

export default function Names({
   allCustomNames,
   addName,
   updateName,
   deleteName,
}) {
   const [activeEdit, setActiveEdit] = useState();
   const [order, setOrder] = useState();

   const handleEntryEditToggle = (entryId) => {
      setActiveEdit((prevState) => {
         if (prevState == entryId) {
            return null;
         }

         return entryId;
      });
   };

   return (
      <>
         <Stack
            direction='row'
            alignItems='center'
            spacing={4}
            sx={{
               width: "100%",
               mb: 2,
            }}
         >
            <Typography variant='h4'>NAMES</Typography>

            <Button onClick={addName} variant='contained'>
               + ADD NAME
            </Button>
         </Stack>

         <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
               <TableRow>
                  <StyledTableCell>
                     <Button
                        color='secondary'
                        onClick={() => setOrder("title")}
                     >
                        <Stack direction='row' spacing={2} alignItems='center'>
                           <Box>Title</Box>
                           {order == "title" && <SortIcon />}
                        </Stack>
                     </Button>
                  </StyledTableCell>
                  <StyledTableCell>
                     <Button
                        color='secondary'
                        onClick={() => setOrder("description")}
                     >
                        <Stack direction='row' spacing={2} alignItems='center'>
                           <Box>Description</Box>
                           {order == "description" && <SortIcon />}
                        </Stack>
                     </Button>
                  </StyledTableCell>
                  <StyledTableCell></StyledTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {allCustomNames &&
                  (order
                     ? _.orderBy(allCustomNames, [order], ["asc"])
                     : allCustomNames
                  ).map((name, i) => (
                     <StyledTableRow key={name.id}>
                        <StyledTableCell>
                           <TextInput
                              label=''
                              visible={true}
                              variant='filled'
                              value={name.title ? name.title : ""}
                              saveButton={false}
                              width={250}
                              callback={(value) =>
                                 updateName(name.id, { title: value })
                              }
                           />
                        </StyledTableCell>
                        <StyledTableCell>
                           <TextInput
                              label=''
                              visible={true}
                              variant='filled'
                              saveButton={false}
                              width={300}
                              value={name.description ? name.description : ""}
                              callback={(value) =>
                                 updateName(name.id, { description: value })
                              }
                           />
                        </StyledTableCell>
                        <StyledTableCell width='100%'>
                           <Button
                              onClick={() => deleteName(name.id)}
                              variant='contained'
                              size='small'
                              color='error'
                           >
                              <DeleteIcon />
                           </Button>
                        </StyledTableCell>
                     </StyledTableRow>
                  ))}
            </TableBody>
         </Table>
      </>
   );
}
