import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function useBracketHandler() {
   const socket = useContext(SocketContext);

   const [bracket, setBracket] = useState({
      qf: [null, null, null, null, null, null, null, null],
      sf: [null, null, null, null],
      f: [null, null],
   });

   const [standings, setStandings] = useState([]);
   const [show, setShow] = useState(true);
   const [runTimestamp, setRunTimestamp] = useState(Date.now());

   const handlers = useMemo(
      () => ({
         getTop8: () => {
            socket.emit("getTop8", (response) => {
               setStandings(response.standings.rows);
               if (response.bracket) {
                  setBracket(response.bracket);
               }
            });
         },
         ledBracketViewerLoad: () => {
            socket.emit("bracketViewerLoad");
         },
         showHide: (action) => {
            socket.emit("ledBracketViewerShowHide", action);
         },
         subscribeLEDBracketViewer: () => {
            socket.emit("subscribeLEDBracketViewer", (response) => {
               console.log(response);
            });

            socket.on("bracketViewerLoad", (bracket) => {
               setBracket(bracket);
            });

            socket.on("ledBracketViewerShowHide", (action) => {
               switch (action) {
                  case "show":
                     setRunTimestamp(Date.now());
                     setShow(true);
                     break;
                  case "hide":
                     setShow(false);
                     break;
                  case "toggle":
                     setShow((prevState) => {
                        return !prevState;
                     });
                     break;
               }
            });
         },
      }),
      []
   );

   return [bracket, show, standings, runTimestamp, handlers];
}
