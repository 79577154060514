import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export default function PlayerSelect({
   allPlayersList,
   selected,
   update,
   draftId,
   disabled = false,
}) {
   const handlePlayerChange = (e) => {
      update(draftId, { ["PlayerId"]: e.target.value });
   };

   const getPlayerName = (selected) => {
      const player = _.find(allPlayersList, function (player) {
         return player.id === selected;
      });

      return player.PlayerName;
   };

   return (
      <>
         {allPlayersList && !disabled && (
            <FormControl fullWidth sx={{ maxWidth: 300 }}>
               <InputLabel id={"playerSelect"}>Player</InputLabel>
               <Select
                  value={selected}
                  label={"Player"}
                  onChange={handlePlayerChange}
               >
                  <MenuItem value=''>Select a Player</MenuItem>
                  {allPlayersList.map((player) => (
                     <MenuItem key={player.id} value={player.id}>
                        {player.PlayerNameLastFirst}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         )}
         {allPlayersList && disabled && selected && (
            <Typography variant='h4' component='div' sx={{ color: "#ccc" }}>
               {getPlayerName(selected)}
            </Typography>
         )}
      </>
   );
}
