import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";

import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/cardoverlay/DataGrid";
import PlayerSelect from "../components/cardoverlay/PlayerSelect";
import Decklist from "../components/cardoverlay/Decklist";
import SideboardView from "../components/cardoverlay/SideboardView";

import CardImage from "../components/cardsinhand/CardImage";
import ManaIconsFormatted from "../components/decklists/ManaIconsFormatted";

import AdjustValue from "../components/cardoverlay/AdjustValue";

import Wrapper from "../components/layout/Wrapper";

//HOOKS
import useCardHandler from "../hooks/useCardHandler";
import useGraphicsOverlay from "../hooks/useGraphicsOverlay";
import useGlobals from "../hooks/useGlobals";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <Box
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box>
               <Typography>{children}</Typography>
            </Box>
         )}
      </Box>
   );
}

function a11yProps(tabId, index) {
   return {
      id: `${tabId}-${index}`,
      name: `${tabId}`,
   };
}

export default function OverlayManager() {
   const columnWidth = useRef();

   const theme = useTheme();
   const [tabs, setTabs] = useState({ parent: 0, deckTab1: 0, deckTab2: 0 });

   const [recent, setRecent] = useState([]);
   const [searchValue, setSearchValue] = useState("");
   const [searchResults, setSearchResults] = useState([]);
   const [filterSets, setFilterSets] = useState(false);
   const [draftFormats, setDraftFormats] = useState();

   const handleChange = (e, newValue) => {
      setTabs((prevState) => {
         let newState = { ...prevState };
         newState[e.target.name] = newValue;
         return newState;
      });
   };

   // USE HOOK
   const [allCards, notification, { getAllCards }] = useCardHandler();

   const [globals, { getGlobals }] = useGlobals();

   // USE HOOK
   const [
      loaded,
      setLoaded,
      data,
      visible,
      visibleOpeningHand,
      openingHand,
      rotation,
      playerId1,
      playerId2,
      player1Decklists,
      player2Decklists,
      {
         updateOpeningHand,
         getDecklistsByPlayerId,
         toggleVisibility,
         toggleOpeningHandVisibility,
         toggleRotation,
         overlayDataLoad,
         subscribeOverlay,
      },
   ] = useGraphicsOverlay();

   useEffect(() => {
      getAllCards();
      subscribeOverlay();
      getGlobals();
   }, []);

   useEffect(() => {
      const formats = _.find(globals, { id: "DraftSets" });
      if (formats) {
         setDraftFormats(_.sortBy(formats.json));
      }
   }, [globals]);

   useEffect(() => {
      // console.log(recent);
   }, [recent]);

   useEffect(() => {
      // console.log(visible);
   }, [visible]);
   const [downloadVisibility, setDownloadVisibility] = useState(false);

   // WINDOW LISTENER
   const [windowSize, setWindowSize] = useState({});

   useEffect(() => {
      function handleResize() {
         setWindowSize({
            height: window.innerHeight,
            width: window.innerWidth,
         });
      }

      window.addEventListener("resize", handleResize);

      return (_) => {
         window.removeEventListener("resize", handleResize);
      };
   });

   useEffect(() => {
      setWindowSize({
         height: window.innerHeight,
         width: window.innerWidth,
      });
   }, []);

   useEffect(() => {
      if (searchValue && searchValue.length >= 3) {
         const search = (searchValue) =>
            allCards.filter(
               ({ CardSearchString, Set }) =>
                  CardSearchString.toLowerCase().includes(
                     searchValue.toLowerCase().replace(/[^A-Za-z0-9]/g, "")
                  ) &&
                  (filterSets ? draftFormats.includes(Set.toLowerCase()) : true)
            );
         let result = search(searchValue);
         result = _.orderBy(result, ["CardName"], ["asc"]);

         setSearchResults(result.slice(0, 30));
      }
   }, [searchValue]);

   const handleUpdateOpeningHand = (value) => {
      updateOpeningHand(value);
   };

   const addToRecent = (card) => {
      if (card.type === "image") {
         setRecent((prevState) => {
            let oldState = [...prevState];
            let newState;

            const exists = _.find(oldState, function (row) {
               return row.cardName === card.cardName;
            });

            if (!exists) {
               newState = [card, ...oldState];
            } else {
               newState = oldState;
            }

            newState.map((row, i) => {
               row.id = i;
               return row;
            });
            return newState.slice(0, 15);
         });
      }
   };

   const showRecent = () => {
      let recents = recent.map((row) => {
         row = row.card;
         return row;
      });

      recents = _.orderBy(recents, ["CardName"], ["asc"]);
      setSearchResults(recents);
   };

   const clearSearch = () => {
      setSearchValue("");
   };

   const handleSearch = (e) => {
      setSearchValue(e.target.value);
   };

   const toggleFilterSets = () => {
      setFilterSets(!filterSets);
   };

   return (
      <Wrapper>
         {allCards && (
            <>
               <Typography
                  variant='h3'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  Card and Sideboard Overlay
               </Typography>

               <Grid container spacing={4}>
                  <Grid item xs={8}>
                     <Box sx={{ width: "100%", backgroundColor: "#444" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "#999" }}>
                           <Tabs
                              value={tabs.parent}
                              onChange={handleChange}
                              sx={{ px: 5, pt: 2 }}
                              TabIndicatorProps={{
                                 style: { background: "#fff" },
                              }}
                           >
                              <Tab
                                 label='Player Decklists'
                                 sx={{ color: "#999" }}
                                 {...a11yProps("parent", 0)}
                              />
                              <Tab
                                 label='All Cards'
                                 sx={{ color: "#999" }}
                                 {...a11yProps("parent", 1)}
                              />
                           </Tabs>
                        </Box>

                        {/* PARENT TABS */}
                        <TabPanel value={tabs.parent} index={0}>
                           <Grid container>
                              {/* PLAYER 1 */}
                              <Grid
                                 item
                                 xs={6}
                                 sx={{
                                    borderRight: "1px solid #999",
                                    p: 3,
                                    pt: 5,
                                    backgroundColor: "#eee",
                                 }}
                              >
                                 <Typography
                                    variant='h4'
                                    component='div'
                                    sx={{
                                       textTransform: "uppercase",
                                       mb: 2,
                                       color: "#000",
                                       textAlign: "center",
                                    }}
                                 >
                                    Player 1
                                 </Typography>
                                 <PlayerSelect
                                    playerPosition={1}
                                    selected={playerId1}
                                    callback={(playerId) =>
                                       getDecklistsByPlayerId(1, {
                                          playerId: playerId,
                                       })
                                    }
                                 />

                                 <Box
                                    sx={{
                                       borderBottom: 1,
                                       borderColor: "divider",
                                    }}
                                 >
                                    <Tabs
                                       value={tabs.deckTab1}
                                       onChange={handleChange}
                                       sx={{ px: 5, pt: 2 }}
                                       centered
                                    >
                                       {player1Decklists &&
                                          player1Decklists.map(
                                             (decklist, i) => (
                                                <Tab
                                                   key={"tabP1-" + i}
                                                   label={decklist.Format}
                                                   {...a11yProps("deckTab1", i)}
                                                />
                                             )
                                          )}
                                    </Tabs>
                                 </Box>
                                 {player1Decklists &&
                                    player1Decklists.map((decklist, i) => (
                                       <Decklist
                                          key={"decklistP1-" + i}
                                          decklist={decklist}
                                          tab={tabs.deckTab1}
                                          index={i}
                                          callback={(data) => {
                                             overlayDataLoad(data);
                                             addToRecent(data);
                                          }}
                                       />
                                    ))}
                              </Grid>

                              {/* PLAYER 2 */}
                              <Grid
                                 item
                                 xs={6}
                                 sx={{
                                    p: 3,
                                    pt: 5,
                                    backgroundColor: "#eee",
                                 }}
                              >
                                 <Typography
                                    variant='h4'
                                    component='div'
                                    sx={{
                                       textTransform: "uppercase",
                                       mb: 2,
                                       color: "#000",
                                       textAlign: "center",
                                    }}
                                 >
                                    Player 2
                                 </Typography>
                                 <PlayerSelect
                                    playerPosition={2}
                                    selected={playerId2}
                                    callback={(playerId) =>
                                       getDecklistsByPlayerId(2, {
                                          playerId: playerId,
                                       })
                                    }
                                 />

                                 <Box
                                    sx={{
                                       borderBottom: 1,
                                       borderColor: "divider",
                                    }}
                                 >
                                    <Tabs
                                       value={tabs.deckTab2}
                                       onChange={handleChange}
                                       sx={{ px: 5, pt: 2 }}
                                       centered
                                    >
                                       {player2Decklists &&
                                          player2Decklists.map(
                                             (decklist, i) => (
                                                <Tab
                                                   key={"tabP2-" + i}
                                                   label={decklist.Format}
                                                   {...a11yProps("deckTab2", i)}
                                                />
                                             )
                                          )}
                                    </Tabs>
                                 </Box>
                                 {player2Decklists &&
                                    player2Decklists.map((decklist, i) => (
                                       <Decklist
                                          key={"decklistP2-" + i}
                                          decklist={decklist}
                                          tab={tabs.deckTab2}
                                          index={i}
                                          callback={(data) => {
                                             overlayDataLoad(data);
                                             addToRecent(data);
                                          }}
                                       />
                                    ))}
                              </Grid>
                           </Grid>
                        </TabPanel>

                        {/* ALL CARDS */}
                        <TabPanel
                           value={tabs.parent}
                           index={1}
                           sx={{ p: 3, backgroundColor: "#222 !important" }}
                        >
                           <Box sx={{ mb: 2 }}>
                              <Typography
                                 variant='h4'
                                 component='div'
                                 sx={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                 }}
                              >
                                 Card Search
                              </Typography>
                              <Typography
                                 variant='h5'
                                 component='div'
                                 sx={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                 }}
                              >
                                 Max. Result 30 cards — type at least 3
                                 characters
                              </Typography>
                           </Box>

                           <Box>
                              <TextField
                                 fullWidth
                                 placeholder='Enter text here'
                                 value={searchValue}
                                 inputProps={{
                                    style: {
                                       fontSize: 24,
                                       textAlign: "center",
                                    },
                                 }}
                                 onClick={(event) => {
                                    event.target.select();
                                 }}
                                 onBlur={clearSearch}
                                 onChange={handleSearch}
                              />
                           </Box>

                           <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='center'
                              sx={{ color: "#fff", mt: 2 }}
                           >
                              <Button
                                 onClick={toggleFilterSets}
                                 sx={{ mr: 4 }}
                                 variant={filterSets ? "contained" : "outlined"}
                                 color={filterSets ? "error" : "secondary"}
                              >
                                 DRAFT MODE (
                                 {draftFormats && draftFormats.join(", ")})
                              </Button>
                              <Button
                                 onClick={showRecent}
                                 sx={{ mr: 4 }}
                                 variant='contained'
                                 color='success'
                              >
                                 SHOW RECENT CARDS
                              </Button>

                              <Typography
                                 variant='h5'
                                 component='div'
                                 sx={{
                                    textAlign: "center",
                                    textTransform: "uppercase",
                                    my: 2,
                                 }}
                              >
                                 {searchResults.length} Results
                              </Typography>

                              <Button
                                 onClick={() => setSearchResults([])}
                                 sx={{ ml: 4 }}
                                 variant='outlined'
                                 color='error'
                              >
                                 CLEAR
                              </Button>
                           </Stack>

                           <Stack spacing={1} sx={{ mt: 2 }}>
                              {searchResults &&
                                 searchResults.map((card, i) => (
                                    <Button
                                       variant='ipadButtonCIH'
                                       color='add'
                                       fullWidth
                                       id={card.CardSearchString}
                                       onClick={() => {
                                          overlayDataLoad({
                                             type: "image",
                                             value: card.FileName,
                                             cardName: card.CardName,
                                             card: card,
                                          });
                                          addToRecent({
                                             type: "image",
                                             cardName: card.CardName,
                                             value: card.FileName,
                                             card: card,
                                          });
                                       }}
                                       key={"cardRow" + i + "-" + card.CardName}
                                    >
                                       <Stack
                                          direction='row'
                                          sx={{ width: "100%" }}
                                          alignItems='center'
                                          justifyContent='flex-end'
                                          spacing={3}
                                       >
                                          <Box
                                             sx={{
                                                width: 100,
                                                height: 70,
                                                position: "relative",
                                                overflow: "hidden",
                                             }}
                                          >
                                             <CardImage
                                                key={"card" + i + "-" + card.ID}
                                                id={card.CardSearchString}
                                                name={card.CardName}
                                                borderRadius={0.5}
                                                showName={false}
                                                fileName={card.FileName}
                                             />
                                          </Box>
                                          <Stack
                                             spacing={0.5}
                                             sx={{
                                                width: "100%",
                                             }}
                                          >
                                             <Box
                                                sx={{
                                                   width: "100%",
                                                   textAlign: "left",
                                                   fontSize: ".5em",
                                                }}
                                             >
                                                {card.CardName}
                                             </Box>
                                             <ManaIconsFormatted
                                                iconWidth={12}
                                                mana={card.CardManaCost}
                                             />
                                          </Stack>
                                       </Stack>
                                    </Button>
                                 ))}
                           </Stack>
                        </TabPanel>
                     </Box>
                  </Grid>

                  {/* LOADED CONTENT */}
                  <Grid
                     item
                     xs={4}
                     sx={{ position: "relative" }}
                     ref={columnWidth}
                  >
                     <Stack
                        spacing={2}
                        sx={{
                           width:
                              columnWidth.current &&
                              parseInt(
                                 window.getComputedStyle(columnWidth.current)
                                    .width
                              ),
                           pr: 4,
                           position: "relative",
                        }}
                     >
                        <Typography
                           variant='h5'
                           component='div'
                           sx={{
                              textTransform: "uppercase",
                              mb: 2,
                           }}
                        >
                           Opening Hand
                        </Typography>
                        {visibleOpeningHand === true && (
                           <Button
                              sx={{ mb: 2 }}
                              variant='contained'
                              color='error'
                              onClick={() => toggleOpeningHandVisibility(false)}
                           >
                              Hide Opening Hand
                           </Button>
                        )}
                        {visibleOpeningHand !== true && (
                           <Button
                              sx={{ mb: 2 }}
                              variant='outlined'
                              color='success'
                              onClick={() => toggleOpeningHandVisibility(true)}
                           >
                              Show Opening Hand
                           </Button>
                        )}

                        <AdjustValue
                           label='Player 2 - Top'
                           property='p2'
                           amounts={[1]}
                           resetAmount={7}
                           callback={handleUpdateOpeningHand}
                        />

                        <AdjustValue
                           label='Player 1 - Bottom'
                           property='p1'
                           amounts={[1]}
                           resetAmount={7}
                           callback={handleUpdateOpeningHand}
                        />

                        <Typography
                           variant='h5'
                           component='div'
                           sx={{
                              textTransform: "uppercase",
                              mb: 2,
                              pt: 4,
                           }}
                        >
                           Loaded Content
                        </Typography>
                        {data && (
                           <>
                              {visible === true && (
                                 <>
                                    <Button
                                       sx={{ mb: 2 }}
                                       variant='contained'
                                       color='error'
                                       onClick={() => toggleVisibility("hide")}
                                    >
                                       Hide Content
                                    </Button>
                                    {data.type === "image" && (
                                       <Button
                                          sx={{ mb: 2 }}
                                          variant='outlined'
                                          color='success'
                                          onClick={() =>
                                             toggleRotation(
                                                rotation === 90 ? 0 : 90
                                             )
                                          }
                                       >
                                          Rotate
                                       </Button>
                                    )}
                                 </>
                              )}
                              {visible !== true && (
                                 <>
                                    <Button
                                       sx={{ mb: 2 }}
                                       variant='outlined'
                                       color='success'
                                       onClick={() => toggleVisibility("show")}
                                    >
                                       Show Content
                                    </Button>

                                    {data.type === "image" && (
                                       <Button
                                          sx={{ mb: 2 }}
                                          variant='outlined'
                                          color='success'
                                          onClick={() =>
                                             toggleRotation(
                                                rotation === 90 ? 0 : 90
                                             )
                                          }
                                       >
                                          Rotate
                                       </Button>
                                    )}
                                 </>
                              )}

                              <Box sx={{ position: "relative", width: "100%" }}>
                                 {!visible && (
                                    <Box
                                       sx={{
                                          position: "absolute",
                                          width: "100%",
                                          height: "100%",
                                          backgroundColor: "rgba(0,0,0,.6)",
                                          textAlign: "center",
                                          zIndex: "100",
                                       }}
                                    />
                                 )}
                                 {data.type === "image" && (
                                    <img
                                       src={
                                          SERVER_URL +
                                          "/cardimages/png/" +
                                          encodeURIComponent(data.value) +
                                          ".png"
                                       }
                                       width='60%'
                                       style={{
                                          margin: "0 auto",
                                          display: "block",
                                          transformOrigin: "center center",
                                          transform:
                                             "rotate(" +
                                             rotation +
                                             "deg) " +
                                             (rotation
                                                ? "scale(.67) translateX(-50%)"
                                                : ""),
                                       }}
                                    />
                                 )}
                                 {data.type === "sideboard" && (
                                    <Box
                                       sx={{
                                          backgroundColor: "#fff",
                                          width: "100%",
                                          p: 2,
                                       }}
                                    >
                                       <Typography
                                          variant='h3'
                                          component='div'
                                          sx={{
                                             textTransform: "uppercase",
                                             color: theme.palette.text.dark,
                                          }}
                                       >
                                          {data.title}
                                       </Typography>
                                       <Typography
                                          variant='h4'
                                          component='div'
                                          sx={{
                                             textTransform: "uppercase",
                                             mb: 2,
                                             color: theme.palette.text.dark,
                                          }}
                                       >
                                          Sideboard
                                       </Typography>

                                       <SideboardView
                                          key={"activeSideboard"}
                                          cards={data.value}
                                       />
                                    </Box>
                                 )}
                              </Box>
                           </>
                        )}
                     </Stack>
                  </Grid>
               </Grid>
            </>
         )}

         <NotificationWidget notification={notification} />
      </Wrapper>
   );
}
