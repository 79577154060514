import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

import DebugOverlay from "../components/widgets/DebugOverlay";
import NotificationWidget from "../components/widgets/NotificationWidget";
import DataGrid from "../components/cardlibrary/DataGrid";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import Wrapper from "../components/layout/Wrapper";

//HOOKS
import useCardHandler from "../hooks/useCardHandler";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const modalStyle = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "80%",
   bgcolor: "background.paper",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
   overflowY: "scroll",
   overflowX: "hidden",
};

function groupBy(objectArray, property) {
   return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
         acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
   }, {});
}

export default function CardLibrary() {
   const [open, setOpen] = React.useState(false);
   const [cardSets, setCardSets] = useState();
   const [activeSet, setActiveSet] = useState();

   const handleOpen = () => setOpen(true);
   const handleClose = () => {
      setPreviewCard();
      setOpen(false);
   };

   const [previewCard, setPreviewCard] = useState();

   // USE HOOK
   const [allCards, notification, { getAllCards }] = useCardHandler();

   useEffect(() => {
      getAllCards();
   }, []);

   useEffect(() => {
      if (allCards) {
         const setGroups = groupBy(
            _.orderBy(allCards, ["SetName"], ["asc"]),
            "Set"
         );

         setCardSets(setGroups);
      }
   }, [allCards]);

   useEffect(() => {
      if (cardSets) {
         // console.log(cardSets);
      }
   }, [cardSets]);

   useEffect(() => {
      if (previewCard) {
         handleOpen();
      }
   }, [previewCard]);

   const handlePreviewCard = (cardId) => {
      let cardInfo = _.find(allCards, { ID: parseInt(cardId) });
      setPreviewCard(cardInfo);
   };

   return (
      <Wrapper>
         <Typography
            variant='h3'
            component='div'
            sx={{ textTransform: "uppercase", mb: 2 }}
         >
            Card Library
         </Typography>

         {allCards && (
            <>
               <Typography
                  variant='h4'
                  component='div'
                  sx={{ textTransform: "uppercase", mb: 2 }}
               >
                  Active Cards in Evoke: {allCards.length}
               </Typography>

               <Stack
                  direction='row'
                  sx={{
                     color: "#ccc",
                     width: "100%",
                  }}
               >
                  <Stack
                     sx={{
                        width: 375,
                        fontSize: ".8em",
                     }}
                  >
                     {Object.entries(
                        _.filter(cardSets, function (set) {
                           if (set[0].Set == "list") {
                              return true;
                           }
                        })
                     ).map(([set, cards]) => (
                        <Button
                           onClick={() =>
                              activeSet == "list"
                                 ? setActiveSet("")
                                 : setActiveSet("list")
                           }
                           variant={activeSet == "list" ? "contained" : "text"}
                           color={activeSet == "list" ? "warning" : "primary"}
                           sx={{
                              borderBottom: "1px solid rgba(255,255,255,.2)",
                           }}
                        >
                           <Stack
                              direction='row'
                              justifyContent='space-between'
                              sx={{ width: "100%", textAlign: "left" }}
                           >
                              <span>The 'List'</span>
                              <span>{cards.length}</span>
                           </Stack>
                        </Button>
                     ))}
                     {cardSets &&
                        Object.entries(cardSets).map(([set, cards]) => (
                           <>
                              {set != "list" && (
                                 <Button
                                    onClick={() =>
                                       activeSet == set
                                          ? setActiveSet("")
                                          : setActiveSet(set)
                                    }
                                    variant={
                                       activeSet == set ? "contained" : "text"
                                    }
                                    color={
                                       activeSet == set ? "warning" : "primary"
                                    }
                                    sx={{
                                       borderBottom:
                                          "1px solid rgba(255,255,255,.2)",
                                    }}
                                 >
                                    <Stack
                                       direction='row'
                                       justifyContent='space-between'
                                       sx={{ width: "100%", textAlign: "left" }}
                                    >
                                       <span>{cards[0].SetName}</span>
                                       <span>{cards.length}</span>
                                    </Stack>
                                 </Button>
                              )}
                           </>
                        ))}
                  </Stack>

                  {cardSets && (
                     <Box sx={{ width: "100%" }}>
                        <Box
                           sx={{
                              position: "fixed",
                              width: "calc(100% - 640px)",
                              pl: 2,
                           }}
                        >
                           <DataGrid
                              key={"datagrid"}
                              height={"70vh"}
                              data={activeSet ? cardSets[activeSet] : allCards}
                              type='cardOverview'
                              preview={handlePreviewCard}
                           />
                        </Box>
                     </Box>
                  )}
               </Stack>
            </>
         )}

         <NotificationWidget notification={notification} />

         {previewCard && (
            <Modal open={open} onClose={handleClose}>
               <Box sx={modalStyle}>
                  <Typography
                     variant='h4'
                     component='h4'
                     sx={{ color: "#333" }}
                  >
                     Card Preview
                     <Button
                        onClick={handleClose}
                        variant='outlined'
                        sx={{ float: "right" }}
                     >
                        Close
                     </Button>
                  </Typography>
                  <Typography sx={{ mt: 6 }}>
                     <Stack direction='row' spacing={8} sx={{ mt: 3 }}>
                        <Box>
                           <img
                              src={
                                 SERVER_URL +
                                 "/cardimages/png/" +
                                 encodeURIComponent(previewCard.FileName) +
                                 ".png"
                              }
                              style={{ maxWidth: 400, pointerEvents: "all" }}
                           />
                        </Box>

                        <Table>
                           <TableBody>
                              {Object.entries(previewCard).map(
                                 ([key, val], i) => (
                                    <React.Fragment key={"data" + i}>
                                       {key !== "legalities" && (
                                          <TableRow key={"datarow-" + i}>
                                             <TableCell
                                                sx={{
                                                   fontWeight: "bold",
                                                   backgroundColor: "#ccc",
                                                   borderBottom:
                                                      "1px solid #fff",
                                                   width: 200,
                                                }}
                                             >
                                                {key}
                                             </TableCell>
                                             <TableCell>{val}</TableCell>
                                          </TableRow>
                                       )}
                                    </React.Fragment>
                                 )
                              )}
                           </TableBody>
                        </Table>
                     </Stack>
                  </Typography>
               </Box>
            </Modal>
         )}
      </Wrapper>
   );
}
