import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function usePlayerPhotos() {
   const socket = useContext(SocketContext);

   const [playerList, setPlayerList] = useState([]);
   const [fileList, setFileList] = useState([]);
   const [notification, setNotification] = useState({});

   const [replaceFilename, setReplaceFilename] = useState("");
   const [lastFileUpdate, setLastFileUpdate] = useState();

   const handlers = useMemo(
      () => ({
         refreshPlayerPhotos: () => {
            axios
               .get(SERVER_URL + "/api/refreshPlayerPhotos/")
               .then(function (response) {
                  setFileList(response.data);
               });
         },
         getPlayerList: () => {
            axios
               .get(SERVER_URL + "/api/getPlayers/")
               .then(function (response) {
                  setPlayerList(response.data);
               });
         },
         getFileList: () => {
            axios
               .get(SERVER_URL + "/api/getFileList/")
               .then(function (response) {
                  setFileList(response.data);
               });
         },
         updatePlayerPhoto: (playerId, fileName) => {
            axios
               .post(SERVER_URL + "/api/updatePlayerPhoto/", {
                  playerId: playerId,
                  fileName: fileName,
               })
               .then(function (response) {
                  setLastFileUpdate(Date.now());
               });
         },
         clearPlayerSelection: (fileName) => {
            axios
               .post(SERVER_URL + "/api/clearPlayerSelection/", {
                  fileName: fileName,
               })
               .then(function (response) {
                  setLastFileUpdate(Date.now());
               });
         },
         deleteFile: (fileName) => {
            axios
               .post(SERVER_URL + "/api/deleteFileName/", {
                  fileName: fileName,
               })
               .then(function (response) {
                  console.log(response);
                  setNotification({ message: response.data, date: Date.now() });
                  setLastFileUpdate(Date.now());
               });
         },
      }),
      [socket]
   );

   return [
      playerList,
      fileList,
      replaceFilename,
      setReplaceFilename,
      lastFileUpdate,
      setLastFileUpdate,
      notification,
      handlers,
   ];
}
