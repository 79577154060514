import React, { useState, useRef, useContext, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import WrapperViewer from "../../components/layout/WrapperViewer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function TimerBackground() {
   return (
      <Box
         sx={{
            width: 1280,
            height: 720,
            position: "relative",
            overflow: "hidden",
         }}
      >
         <Box
            sx={{
               position: "absolute",
               top: 40 * (2 / 3),
               right: 100 * (2 / 3),
               height: 200 * (2 / 3),
            }}
         >
            <img
               src={SERVER_URL + "/images/ui/logo-800.png"}
               width={125 * (2 / 3)}
            />
         </Box>
         <Box>
            <img
               src={SERVER_URL + "/images/bkgnd-blue.png"}
               style={{ width: "100%" }}
            />
         </Box>
      </Box>
   );
}
