import React, { useState, useContext, useEffect } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import _ from "lodash";

import useGlobals from "../../hooks/useGlobals";

export default function AdjustRoundTitle({ curRound = 1, save, cancel }) {
   const [globals, { getGlobals }] = useGlobals();

   // OPTIONS
   const [mode, setMode] = useState("swiss");
   const [formats, setFormats] = useState([]);
   const [finalRounds, setFinalRounds] = useState([
      "Quarterfinals",
      "Semifinals",
      "Finals",
   ]);

   // VALUES
   const [roundNum, setRoundNum] = useState(curRound);
   const [formatVal, setFormatVal] = useState("");
   const [finalRoundVal, setFinalRoundVal] = useState("Quarterfinals");
   const [roundTitle, setRoundTitle] = useState("");

   const handleModeChange = (event) => {
      setMode(event.target.value);
   };

   const handleFormatChange = (event) => {
      setFormatVal(event.target.value);
   };

   const handleFinalRoundChange = (event) => {
      setFinalRoundVal(event.target.value);
   };

   const updateRoundTitle = () => {
      save(roundTitle);
   };

   useEffect(() => {
      getGlobals();
   }, []);

   useEffect(() => {
      const formats = _.find(globals, { id: "RoundByRoundTitles" });
      if (formats) {
         setFormats(_.sortBy(formats.json));
      }
   }, [globals]);

   useEffect(() => {
      let formatString = "";
      if (formatVal) {
         formatString = " | " + formatVal;
      }

      if (mode === "swiss") {
         setRoundTitle("Round " + roundNum + formatString);
      } else if (mode === "finals") {
         setRoundTitle(finalRoundVal);
      }
   }, [roundNum, mode, formatVal, finalRoundVal]);

   const decrement = (amount) => {
      setRoundNum((prevState) => {
         let newState = prevState;
         newState = Math.max(newState - amount, 1);
         return newState;
      });
   };

   const increment = (amount) => {
      setRoundNum((prevState) => {
         let newState = prevState;
         newState = newState + amount;
         return newState;
      });
   };

   const reset = (amount) => {
      setRoundNum(1);
   };

   return (
      <Grid
         container
         alignItems='stretch'
         justifyContent='center'
         sx={{ textAlign: "center" }}
      >
         <Grid
            item
            xs={6}
            sx={{
               backgroundColor: "#222",
               color: "#fff",
               p: 2,
            }}
         >
            <Typography variant='h5' color='text.dark' component='div'>
               MODE
            </Typography>
            <FormControl>
               <RadioGroup
                  row
                  aria-labelledby='mode'
                  name='mode-button-group'
                  value={mode}
                  onChange={handleModeChange}
               >
                  <FormControlLabel
                     value='swiss'
                     control={
                        <Radio
                           sx={{
                              color: "#fff",
                              "&.Mui-checked": {
                                 color: "#f00",
                              },
                           }}
                        />
                     }
                     label='Swiss'
                  />
                  <FormControlLabel
                     value='finals'
                     control={
                        <Radio
                           sx={{
                              color: "#fff",
                              "&.Mui-checked": {
                                 color: "#f00",
                              },
                           }}
                        />
                     }
                     label='Finals'
                  />
               </RadioGroup>
            </FormControl>
         </Grid>
         <Grid
            item
            xs={6}
            sx={{
               backgroundColor: "#222",
               color: "#fff",
               p: 2,
            }}
         >
            <Typography
               variant='h5'
               color='text.dark'
               component='div'
               sx={{ mb: 1 }}
            >
               ROUND TITLE
            </Typography>
            <Typography variant='h4' color='text.white' component='div'>
               {roundTitle}
            </Typography>
         </Grid>
         {mode === "swiss" && (
            <>
               <Grid item xs={6} sx={{ p: 2, backgroundColor: "#444" }}>
                  <Stack spacing={0} alignItems='center'>
                     <Typography
                        variant='h5'
                        color='text.dark'
                        component='div'
                        sx={{ mb: 2 }}
                     >
                        SWISS ROUND
                     </Typography>
                     <Stack direction='row' spacing={1}>
                        <Button
                           key={"decrement"}
                           variant='outlined'
                           onClick={() => decrement(1)}
                        >
                           -1
                        </Button>

                        <Typography
                           variant='h3'
                           color='text.primary'
                           component='div'
                           sx={{
                              mb: 2,
                              width: 100,
                              textAlign: "center",
                              color: "#fff",
                           }}
                        >
                           {roundNum}
                        </Typography>

                        <Button
                           key={"increment"}
                           variant='outlined'
                           onClick={() => increment(1)}
                        >
                           +1
                        </Button>
                     </Stack>

                     <Button
                        size='small'
                        variant='outlined'
                        color='alert'
                        onClick={() => reset()}
                        sx={{ mt: 1 }}
                     >
                        RESET
                     </Button>
                  </Stack>
               </Grid>
               <Grid item xs={6} sx={{ p: 2, backgroundColor: "#444" }}>
                  <Typography
                     variant='h5'
                     color='text.dark'
                     component='div'
                     sx={{ mb: 2 }}
                  >
                     FORMAT
                  </Typography>

                  <FormControl fullWidth sx={{ maxWidth: 400 }}>
                     <Select
                        sx={{ backgroundColor: "#666", color: "#fff" }}
                        labelId='format'
                        id='format'
                        value={formatVal ? formatVal : ""}
                        onChange={handleFormatChange}
                     >
                        <MenuItem value=''>--</MenuItem>
                        {formats.map((option) => (
                           <MenuItem key={option} value={option}>
                              {option}
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>
               </Grid>
            </>
         )}

         {mode === "finals" && (
            <Grid
               item
               xs={12}
               sx={{ p: 2, backgroundColor: "#444", color: "#fff" }}
            >
               <Stack alignItems='center'>
                  <Typography
                     variant='h5'
                     color='text.dark'
                     component='div'
                     sx={{ mb: 2 }}
                  >
                     FINAL ROUNDS
                  </Typography>

                  <FormControl>
                     <RadioGroup
                        row
                        aria-labelledby='format'
                        name='format-button-group'
                        value={finalRoundVal}
                        onChange={handleFinalRoundChange}
                     >
                        {finalRounds.map((option) => (
                           <FormControlLabel
                              key={option}
                              value={option}
                              control={
                                 <Radio
                                    sx={{
                                       color: "#fff",
                                       "&.Mui-checked": {
                                          color: "#f00",
                                       },
                                    }}
                                 />
                              }
                              label={option}
                           />
                        ))}
                     </RadioGroup>
                  </FormControl>
               </Stack>
            </Grid>
         )}
         <Grid item xs={12} sx={{ pt: 4 }}>
            <Button
               onClick={updateRoundTitle}
               variant='outlined'
               color='secondary'
               size='large'
               sx={{ mr: 2 }}
               onClick={cancel}
            >
               CANCEL
            </Button>

            <Button
               onClick={updateRoundTitle}
               variant='outlined'
               color='error'
               size='large'
            >
               SAVE
            </Button>
         </Grid>
      </Grid>
   );
}
