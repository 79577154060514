import React, { useState, useRef, useContext, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import RotateRightIcon from "@mui/icons-material/RotateRight";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function CardImage({
   id,
   width,
   name,
   iframeScale,
   qty,
   fileName,
   show,
   parentContainer,
   setZoomCardPos,
   borderRadius = 2.8,
   showName = true,
   toggleCardRotation,
   preview,
   controls = false,
}) {
   const cardRef = useRef();

   function handleCardPosition(elem) {
      var position = {
         x: 0,
         y: 0,
         width: 0,
         height: 0,
      };

      let parentX = parentContainer.x;
      let parentY = parentContainer.y;

      position.x = elem.offsetLeft + parentX + 6;
      position.y = elem.offsetTop + parentY;
      position.width =
         (elem.getBoundingClientRect().width / iframeScale) * 0.95;
      position.height =
         (elem.getBoundingClientRect().height / iframeScale) * 0.95;

      setZoomCardPos(position);
   }

   return (
      <Grid
         item
         id={"card" + id}
         sx={{
            width: width,
            color: "#fff",
            textAlign: "center",
            position: "relative",
            pb: 2.3,
            px: 0.5,
         }}
         key={name}
         ref={(element) => {
            cardRef.current = element;
         }}
      >
         {!fileName && (
            <>
               {showName && (
                  <Typography
                     variant='h5DecklistNoImage'
                     component='div'
                     sx={{
                        fontSize: 12,
                        width: "80%",
                        zIndex: 100,
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                        position: "absolute",
                        color: "#fff",
                        fontWeight: "bold",
                     }}
                  >
                     {name}
                  </Typography>
               )}
               {qty && (
                  <Typography
                     variant='h5DecklistQty'
                     component='div'
                     sx={{
                        fontSize: 18,
                        lineHeight: "30px",
                        zIndex: 100,
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        position: "absolute",
                        color: "#fff",
                        backgroundColor: "#1f1e1e",
                        borderRadius: 100,
                        width: 30,
                        height: 30,
                     }}
                  >
                     {qty}
                  </Typography>
               )}
               <img
                  width='100%'
                  src={SERVER_URL + "/images/card-back.png"}
                  style={{ display: "block" }}
               />
            </>
         )}

         {fileName && (
            <>
               {qty && (
                  <Typography
                     variant='h5DecklistQty'
                     component='div'
                     sx={{
                        fontSize: 18,
                        lineHeight: "30px",
                        zIndex: 100,
                        bottom: 15,
                        left: "50%",
                        transform: "translateX(-50%)",
                        position: "absolute",
                        color: "#fff",
                        backgroundColor: "#1f1e1e",
                        borderRadius: 100,
                        width: 30,
                        height: 30,
                     }}
                  >
                     {qty}
                  </Typography>
               )}

               <Box
                  sx={{
                     borderRadius: borderRadius,
                     overflow: "hidden",
                  }}
               >
                  {preview && controls && (
                     <>
                        <Button
                           sx={{
                              position: "absolute",
                              bottom: 20,
                              left: 20,
                              border: "1px solid #fff",
                              zIndex: 100,
                              pointerEvents: "auto",
                           }}
                           variant='contained'
                           color='success'
                           onClick={(el) => {
                              show(fileName);
                              handleCardPosition(cardRef.current);
                           }}
                        >
                           <ZoomInIcon />
                        </Button>
                        <Button
                           sx={{
                              position: "absolute",
                              bottom: 20,
                              right: 20,
                              border: "1px solid #fff",
                              zIndex: 100,
                              pointerEvents: "auto",
                           }}
                           variant='contained'
                           color='error'
                           onClick={(el) => {
                              show(fileName);
                              handleCardPosition(cardRef.current);
                              toggleCardRotation();
                           }}
                        >
                           <RotateRightIcon />
                        </Button>
                     </>
                  )}
                  <img
                     src={
                        SERVER_URL +
                        "/cardimages/jpg/" +
                        encodeURIComponent(fileName) +
                        ".jpg"
                     }
                     width='100%'
                     style={{ display: "block" }}
                  />
               </Box>
            </>
         )}
      </Grid>
   );
}
