import React, { useState, useContext, useEffect } from "react";

import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

export default function DecklistSelect({ defaultSelect, decklists, callback }) {
   const handleCallback = (e) => {
      callback(e.target.value);
   };

   return (
      <>
         {decklists && (
            <FormControl fullWidth variant='filled'>
               <InputLabel id={"decklistSelect"}>Preset Decklists</InputLabel>
               <Select
                  label={"Preset Decklists"}
                  defaultValue={defaultSelect}
                  onChange={handleCallback}
                  sx={{ backgroundColor: "#fff" }}
               >
                  <MenuItem
                     value='search'
                     sx={{ fontWeight: "bold", color: "green" }}
                  >
                     Card Search
                  </MenuItem>
                  {_.orderBy(decklists, ["Format", "Name"], ["asc", "asc"]).map(
                     (decklist) => (
                        <MenuItem key={decklist.id} value={decklist.id}>
                           <Stack direction='row' spacing={2}>
                              <b>{decklist.PlayerName}</b>
                              <div>{decklist.Archetype}</div>
                              <i>({decklist.Format})</i>
                           </Stack>
                        </MenuItem>
                     )
                  )}
               </Select>
            </FormControl>
         )}
      </>
   );
}
