import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

function useCelebrityDraftHandler() {
   const socket = useContext(SocketContext);

   const [allCardNames, setAllCardNames] = useState();
   const [data, setData] = useState();
   const [visible, setVisible] = useState(false);
   const [rotation, setRotation] = useState(0);
   const [loaded, setLoaded] = useState(false);

   useEffect(() => {
      handlers.getAllCardNames();
   }, []);

   const update = (draftId, updates) => {
      socket.emit("updateDraft", draftId, updates);
   };

   const handlers = useMemo(
      () => ({
         subscribeDraft: () => {
            socket.emit("subscribeCelebrityDraft", (response) => {
               console.log(response);
            });

            socket.on("celebrityDraftOverlayDataLoad", (data) => {
               setData(data);
            });

            socket.on("toggleCelebrityDraftVisibility", (visibility) => {
               setVisible(visibility);
            });

            socket.on("toggleCelebrityDraftRotation", (rotation) => {
               setRotation(rotation);
            });
         },
         getAllCardNames: () => {
            socket.emit("getAllCardNames", (response) => {
               setAllCardNames(response);
            });
         },
         toggleCelebrityDraftVisibility: (visibility) => {
            socket.emit("toggleCelebrityDraftVisibility", visibility);
         },
         toggleCelebrityDraftRotation: (rotation) => {
            socket.emit("toggleCelebrityDraftRotation", rotation);
         },
         celebrityDraftOverlayDataLoad: (data) => {
            handlers.toggleCelebrityDraftRotation(0);

            if (typeof data !== "object") {
               data = JSON.parse(data);
            }
            socket.emit("celebrityDraftOverlayDataLoad", data);
         },
      }),
      [socket]
   );

   return [allCardNames, data, visible, rotation, loaded, setLoaded, handlers];
}

export default useCelebrityDraftHandler;
