import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import CheckActive from "./CheckActive";
import PlayerSelect from "./PlayerSelect";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function PlayerData({ data, dataFields }) {
   const theme = useTheme();

   const forceLineBreak = (text) => {
      if (text) {
         let textArray = text.split(" ");
         let divider = textArray.length === 2 ? "<br />" : " ";
         let response = textArray.join(divider);
         return <div dangerouslySetInnerHTML={{ __html: response }} />;
      }
   };

   const lang = (text) => {
      switch (text) {
         case "STARTED_IN":
            return "Started In";
            break;
         case "TOP_FINISHES":
            return "Top Finishes";
            break;
         case "PREFERRED_PRONOUNS":
            return "Pronouns";
            break;
         default:
            return text;
      }
   };

   return (
      <>
         {data && (
            <>
               {dataFields &&
                  dataFields.data.map((row, i) => (
                     <Box key={"field" + i} sx={{ height: 130 }}>
                        <Typography
                           variant='h2head2headtitle'
                           component='div'
                           sx={{ textTransform: "uppercase" }}
                        >
                           {forceLineBreak(lang(row.title))}
                        </Typography>
                        <Typography variant='h1head2headvalue' component='div'>
                           {data[row.field]}
                        </Typography>
                     </Box>
                  ))}
            </>
         )}
      </>
   );
}
