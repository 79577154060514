import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { Snackbar } from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function UrlToCopy({
   title,
   smallTitle = false,
   path,
   open = true,
}) {
   return (
      <>
         <Typography
            variant={smallTitle ? "h5" : "h4"}
            component='div'
            sx={{ pt: smallTitle ? 1 : 4 }}
         >
            {title}
         </Typography>

         <Stack sx={{ mt: 2 }} direction='row' spacing={2}>
            <TextField
               value={SERVER_URL + path}
               sx={{ width: 800 }}
               onFocus={(event) => {
                  event.target.select();
               }}
            />
            {open && (
               <Button
                  href={path}
                  variant='outlined'
                  color='warning'
                  target='_blank'
                  sx={{ ml: 2 }}
               >
                  OPEN
               </Button>
            )}
         </Stack>
      </>
   );
}
