import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

//HOOKS
import useFeatureMatchHandler from "../../hooks/useFeatureMatchHandler";

export default function AdjustValue({
   tableId,
   label = "RESET",
   property,
   value,
   handleClose,
   callback,
   resetValue = 0,
}) {
   const reset = (amount) => {
      callback(tableId, { [property]: resetValue });
   };

   return (
      <Button
         variant='outlined'
         color='error'
         sx={{ fontSize: "3vw" }}
         onClick={() => {
            reset();
         }}
      >
         {label}
      </Button>
   );
}
