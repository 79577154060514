import React, { useState, useRef, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import WrapperViewer from "../../components/layout/WrapperViewer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const TOURNAMENT_FORMAT_KEY = process.env.REACT_APP_TOURNAMENT_FORMAT_KEY;

export default function RoundByRoundPreview({ data, show }) {
   const theme = useTheme();
   const [roundByRound, setRoundByRound] = useState();

   useEffect(() => {
      if (data && data.Results && data.Results.length > 0) {
         const updatedResults = data.Results.map((row) => {
            if (row.OpponentMetadata) {
               const metaData = JSON.parse(row.OpponentMetadata);
               row.name = metaData.FirstName + " " + metaData.LastName;

               row.decklist = metaData[TOURNAMENT_FORMAT_KEY + " Deck"]
                  ? metaData[TOURNAMENT_FORMAT_KEY + " Deck"]
                  : row.OpponentDecklistName;
            } else {
               row.name = row.OpponentName;
               row.decklist = row.OpponentDecklistName;
            }

            return row;
         });

         setRoundByRound({
            ...data,
            data: {
               ...data.data,
               PlayerData: { ...data, Results: updatedResults },
            },
         });
      }
   }, [data]);

   useEffect(() => {
      // console.log(roundByRound);
   }, [roundByRound]);

   const getDeckName = (format) => {
      const decklist = _.find(roundByRound.Decklists, function (o) {
         return format === o.Format;
      });

      return decklist ? decklist.Name : format;
   };

   const deckType = (i) => {
      let letter = "";

      if (i < 3) {
         letter = "D";
      }

      if (i >= 3 && i < 9) {
         letter = "M";
      }

      if (i >= 9 && i < 12) {
         letter = "D";
      }

      if (i >= 12) {
         letter = "M";
      }

      return (
         <Stack
            sx={{
               backgroundColor: theme.palette.background.dark,
               color: theme.palette.text.white,
               borderRadius: 100,
               width: 35,
               height: 35,
               fontSize: 24,
               pl: 0.3,
               pt: 0.2,
               mr: 0,
               ml: "auto",
            }}
            width='100%'
            alignItems='center'
            justifyContent='center'
         >
            <Box>{letter}</Box>
         </Stack>
      );
   };

   return (
      <WrapperViewer>
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/ui/logo-800.png"} width='125' />
         </Box>

         <AnimatePresence>
            {roundByRound && roundByRound.data && show && (
               <>
                  <Stack
                     sx={{
                        position: "absolute",
                        top: 40,
                        left: 250,
                        height: 200,
                     }}
                     justifyContent='center'
                     key={roundByRound.PlayerId}
                     component={motion.div}
                     initial={{ opacity: 0 }}
                     animate={{
                        opacity: 1,
                     }}
                     exit={{
                        opacity: 0,
                     }}
                     transition={{
                        easing: "easeOut",
                        duration: 0.5,
                     }}
                  >
                     <Typography
                        variant='h1Viewer'
                        component='div'
                        sx={{ textTransform: "uppercase" }}
                     >
                        <Box
                           sx={{
                              p: 2,
                              top: -20,
                              left: -20,
                              position: "relative",
                           }}
                        >
                           {roundByRound &&
                              roundByRound.PlayerName.toUpperCase()}
                        </Box>
                     </Typography>
                     <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                           top: -30,
                           position: "relative",
                        }}
                     >
                        {_.sortBy(roundByRound.globals.json).map(
                           (global, i) => (
                              <React.Fragment key={"global-" + i}>
                                 <Stack
                                    sx={{
                                       backgroundColor:
                                          theme.palette.text.orange,
                                       color: theme.palette.text.primary,
                                       fontFamily: "GothamNarrow-Bold",
                                       borderRadius: 100,
                                       width: 40,
                                       height: 40,
                                       fontSize: 24,
                                       pl: 0.3,
                                       pt: 0.2,
                                       mr: 2,
                                    }}
                                    width='100%'
                                    alignItems='center'
                                    justifyContent='center'
                                 >
                                    {global[0]}
                                 </Stack>
                                 <Typography
                                    variant='h4Viewer'
                                    component='div'
                                    sx={{
                                       textTransform: "uppercase",
                                       pt: 0.3,
                                       mr: 6,
                                    }}
                                 >
                                    {global}
                                 </Typography>
                              </React.Fragment>
                           )
                        )}
                     </Stack>
                  </Stack>

                  {roundByRound &&
                     roundByRound.data &&
                     roundByRound.Results &&
                     roundByRound.Results.length > 0 && (
                        <Stack
                           spacing={3}
                           sx={{
                              position: "absolute",
                              width: 1620,
                              top: 230,
                              left: 100,
                           }}
                           key={roundByRound.id}
                           component={motion.div}
                           initial={{ opacity: 0 }}
                           animate={{
                              opacity: 1,
                           }}
                           exit={{
                              opacity: 0,
                           }}
                           transition={{
                              easing: "easeOut",
                              duration: 0.5,
                           }}
                        >
                           <Stack direction='row'>
                              <Typography
                                 variant='h3'
                                 component='div'
                                 sx={{
                                    textTransform: "uppercase",
                                    textAlign: "right",
                                    width: 160,
                                    pr: 4,
                                 }}
                              >
                                 DAY 1
                              </Typography>
                              <Table variant='roundbyround'>
                                 <TableHead>
                                    <TableRow>
                                       <TableCell width='70'></TableCell>
                                       <TableCell width='70'></TableCell>
                                       <TableCell width='35%'>
                                          OPPONENT
                                       </TableCell>
                                       <TableCell>ARCHETYPE</TableCell>
                                       <TableCell width='15%'>
                                          RESULTS
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>
                                 <TableBody>
                                    {[...Array(8).keys()].map((row, i) => (
                                       <TableRow key={"row" + i + 1}>
                                          <TableCell>{deckType(i)}</TableCell>
                                          <TableCell align='center'>
                                             {i + 1}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i] &&
                                                roundByRound.Results[i]
                                                   .Opponent && (
                                                   <>
                                                      {
                                                         roundByRound.Results[i]
                                                            .Opponent.FirstName
                                                      }{" "}
                                                      {
                                                         roundByRound.Results[i]
                                                            .Opponent.LastName
                                                      }
                                                   </>
                                                )}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i] &&
                                                roundByRound.Results[i]
                                                   .OpponentDeck && (
                                                   <>
                                                      {((i >= 3 && i < 9) ||
                                                         i >= 12) &&
                                                         roundByRound.Results[i]
                                                            .OpponentDeck
                                                            .Archetype}
                                                   </>
                                                )}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i] && (
                                                <>
                                                   {
                                                      roundByRound.Results[i]
                                                         .ShortResult
                                                   }
                                                </>
                                             )}
                                          </TableCell>
                                       </TableRow>
                                    ))}
                                 </TableBody>
                              </Table>
                           </Stack>

                           <Stack direction='row'>
                              <Typography
                                 variant='h3'
                                 component='div'
                                 sx={{
                                    textTransform: "uppercase",
                                    textAlign: "right",
                                    width: 160,
                                    pr: 4,
                                 }}
                              >
                                 DAY 2
                              </Typography>
                              <Table variant='roundbyround'>
                                 <TableHead>
                                    <TableRow>
                                       <TableCell width='70'></TableCell>
                                       <TableCell width='70'></TableCell>
                                       <TableCell width='35%'>
                                          OPPONENT
                                       </TableCell>
                                       <TableCell>ARCHETYPE</TableCell>
                                       <TableCell width='15%'>
                                          RESULTS
                                       </TableCell>
                                    </TableRow>
                                 </TableHead>

                                 <TableBody>
                                    {[...Array(8).keys()].map((row, i) => (
                                       <TableRow key={"row" + i + 9}>
                                          <TableCell>{deckType(i)}</TableCell>
                                          <TableCell align='center'>
                                             {i + 9}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i + 8] &&
                                                roundByRound.Results[i + 8]
                                                   .Opponent && (
                                                   <>
                                                      {
                                                         roundByRound.Results[
                                                            i + 8
                                                         ].Opponent.FirstName
                                                      }{" "}
                                                      {
                                                         roundByRound.Results[
                                                            i + 8
                                                         ].Opponent.LastName
                                                      }
                                                   </>
                                                )}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i + 8] &&
                                                roundByRound.Results[i + 8]
                                                   .OpponentDeck && (
                                                   <>
                                                      {((i >= 3 && i < 9) ||
                                                         i >= 12) &&
                                                         roundByRound.Results[
                                                            i + 8
                                                         ].OpponentDeck
                                                            .Archetype}
                                                   </>
                                                )}
                                          </TableCell>
                                          <TableCell>
                                             {roundByRound.Results[i + 8] && (
                                                <>
                                                   {
                                                      roundByRound.Results[
                                                         i + 8
                                                      ].ShortResult
                                                   }
                                                </>
                                             )}
                                          </TableCell>
                                       </TableRow>
                                    ))}
                                 </TableBody>
                              </Table>
                           </Stack>
                        </Stack>
                     )}
               </>
            )}
         </AnimatePresence>
      </WrapperViewer>
   );
}
