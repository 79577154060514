import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { saveAs } from "file-saver";

import DownloadIcon from "@mui/icons-material/Download";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function getBool(val) {
   if (val) {
      return !!JSON.parse(String(val).toLowerCase());
   }
}

export default function DataGridContainer({
   data,
   height,
   type,
   disableColumnFilter,
   load,
   callback,
   preview,
}) {
   let columns;
   let initialState;

   columns = [
      {
         field: "id",
         headerName: "Decklist Id",
         editable: false,
         width: 100,
         filterable: false,
      },
      {
         field: "LastName",
         headerName: "Last Name",
         editable: false,
         width: 200,
      },
      {
         field: "FirstName",
         headerName: "First Name",
         editable: false,
         width: 200,
      },
      {
         field: "Format",
         headerName: "Format",
         editable: false,
         width: 120,
      },
      {
         field: "PreviewDecklist",
         headerName: "Constructed",
         editable: false,
         width: 270,
         filterable: false,
         renderCell: (params) => (
            <>
               <Button
                  size='small'
                  onClick={() => load(1, params.row.id, "")}
                  sx={{ mr: 1 }}
               >
                  LOAD 1
               </Button>
               <Button
                  size='small'
                  onClick={() => load(2, params.row.id, "")}
                  sx={{ mr: 1 }}
                  color='warning'
               >
                  LOAD 2
               </Button>
               <Button
                  size='small'
                  onClick={() => load(3, params.row.id, "")}
                  sx={{ mr: 1 }}
                  color='error'
               >
                  LOAD 3
               </Button>
            </>
         ),
      },
      {
         field: "PreviewDecklist2",
         headerName: "Draft",
         editable: false,
         width: 270,
         filterable: false,
         renderCell: (params) => (
            <>
               <Button
                  size='small'
                  onClick={() => load(1, params.row.id, "Draft")}
               >
                  LOAD 1
               </Button>
               <Button
                  size='small'
                  onClick={() => load(2, params.row.id, "Draft")}
                  color='warning'
               >
                  LOAD 2
               </Button>
               <Button
                  size='small'
                  onClick={() => load(3, params.row.id, "Draft")}
                  color='error'
               >
                  LOAD 3
               </Button>
            </>
         ),
      },
      {
         field: "Archetype",
         headerName: "Archetype",
         editable: false,
         width: 400,
         renderCell: (params) => (
            <>
               <Stack direction='row' spacing={0.5}>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsWhite) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/white.png"}
                        width='100%'
                     />
                  </Box>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsBlue) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/blue.png"}
                        width='100%'
                     />
                  </Box>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsBlack) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/black.png"}
                        width='100%'
                     />
                  </Box>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsRed) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/red.png"}
                        width='100%'
                     />
                  </Box>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsGreen) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/green.png"}
                        width='100%'
                     />
                  </Box>
                  <Box
                     sx={{
                        width: 20,
                        height: 20,
                        opacity: getBool(params.row.IsColorless) ? 1 : 0.1,
                     }}
                  >
                     <img
                        src={SERVER_URL + "/images/mana/colorless.png"}
                        width='100%'
                     />
                  </Box>
                  {!params.value && <Box sx={{ pl: 2 }}>None</Box>}
                  {params.value && <Box sx={{ pl: 2 }}>{params.value}</Box>}
               </Stack>
            </>
         ),
      },
      {
         field: "CardsUnique",
         headerName: "Cards",
         editable: false,
         width: 80,
         filterable: false,
      },
      {
         field: "LandsUnique",
         headerName: "Lands",
         editable: false,
         width: 80,
         filterable: false,
      },
      {
         field: "SideboardUnique",
         headerName: "Sideboard",
         editable: false,
         width: 80,
         filterable: false,
      },
      {
         field: "CardsSum",
         headerName: "Cards",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "LandsSum",
         headerName: "Lands",
         editable: false,
         width: 50,
         filterable: false,
      },
      {
         field: "SideboardSum",
         headerName: "Sideboard Sum",
         editable: false,
         width: 50,
         filterable: false,
      },
   ];

   initialState = {
      sorting: {
         sortModel: [{ field: "LastName", sort: "asc" }],
      },
      columns: {
         columnVisibilityModel: {
            id: false,
            CardsSum: false,
            LandsSum: false,
            SideboardSum: false,
         },
      },
   };

   const handleCallback = (e) => {
      callback(e.target.id, e.target.value);
   };

   const handlePreview = (e) => {
      preview(e.target.id);
   };

   const handleDownload = (e) => {
      saveAs(SERVER_URL + "/cardimages/png/" + e.target.value, e.target.value);
   };

   return (
      <>
         <Box sx={{ height: height ? height : 400, width: "100%" }}>
            <DataGrid
               initialState={initialState}
               sx={{ backgroundColor: "#efefef" }}
               rows={data}
               rowHeight={
                  type === "cardOverview" || type === "cardOverlay" ? 120 : 40
               }
               disableColumnFilter={disableColumnFilter}
               columns={columns}
               rowsPerPageOptions={[100, 200, 300, 1000, 5000]}
               components={{ Toolbar: GridToolbar }}
               componentsProps={{
                  toolbar: {
                     printOptions: {
                        disableToolbarButton: true,
                     },
                  },
               }}
               disableSelectionOnClick
            />
         </Box>
      </>
   );
}
