import { useState, useMemo, useContext, useEffect } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";
import _ from "lodash";

export default function useDecklistHandler() {
   const socket = useContext(SocketContext);

   const [liveData, setLiveData] = useState();
   const [decklist, setDecklist] = useState();
   const [decklistCards, setDecklistCards] = useState();
   const [sideboardCards, setSideboardCards] = useState();
   const [allDecklists, setAllDecklists] = useState();

   const handlers = useMemo(
      () => ({
         getDecklist: (decklistId) => {
            socket.emit("getDecklist", decklistId, (response) => {
               if (response) {
                  setDecklist(response);
                  setDecklistCards(response.MainDeck);
                  setSideboardCards(response.Sideboard);
               }
            });
         },
         getAllDecklists: () => {
            socket.emit("getAllDecklists", (response) => {
               setAllDecklists(response);
            });
         },
         decklistViewerLoad: (decklistId) => {
            socket.emit("decklistInteractiveViewerLoad", decklistId);
         },
         getFeatureMatchDecklists: (tableId) => {
            socket.emit("getFeatureMatchDecklists", tableId, (response) => {
               setAllDecklists(response);
            });
         },
         decklistViewerUpdate: (data) => {
            socket.emit("decklistInteractiveViewerUpdate", data);
         },
         subscribeDecklistViewer: () => {
            socket.emit("subscribeDecklistInteractiveViewer", (response) => {
               console.log(response);
            });

            socket.on("decklistInteractiveViewerUpdate", (data) => {
               setLiveData(data);
            });

            socket.on("decklistInteractiveViewerLoad", (decklistId) => {
               socket.emit("getDecklist", decklistId, (response) => {
                  if (response) {
                     setDecklist(response);
                  }
               });
            });
         },
      }),
      [socket]
   );

   return [
      liveData,
      decklist,
      decklistCards,
      sideboardCards,
      allDecklists,
      handlers,
   ];
}
