import React from "react";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

import ManaIcons from "../../components/decklists/ManaIcons";

export default function PlayerPanel({ selectedPlayer }) {
   const deck = _.find(selectedPlayer.Decklists, function (deck) {
      if (deck.ID == selectedPlayer.decklistId) {
         return true;
      }
   });

   return (
      <Stack alignItems='center' spacing={6} sx={{ width: "100%" }}>
         <Typography
            variant='h3LEDViewer'
            sx={{
               textTransform: "uppercase",
               textAlign: "center",
               lineHeight: "1em",
               width: "100%",
               overflow: "hidden",
            }}
         >
            {selectedPlayer.FirstName}
            <br />
            {selectedPlayer.LastName}
         </Typography>

         {selectedPlayer.showResults && !selectedPlayer.playerStandings && (
            <Typography
               variant='h3LEDViewer'
               sx={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: "1em",
                  width: "100%",
                  overflow: "hidden",
               }}
            >
               0-0
            </Typography>
         )}

         {selectedPlayer.showResults && selectedPlayer.playerStandings && (
            <Typography
               variant='h3LEDViewer'
               sx={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: "1em",
                  width: "100%",
                  overflow: "hidden",
               }}
            >
               {selectedPlayer.playerStandings.MatchWins}-
               {selectedPlayer.playerStandings.MatchLosses}
               {selectedPlayer.playerStandings.MatchDraws > 0 && (
                  <>-{selectedPlayer.playerStandings.MatchDraws}</>
               )}
            </Typography>
         )}

         {deck && (
            <>
               <Typography
                  variant='h2vsViewer'
                  sx={{
                     textTransform: "uppercase",
                     textAlign: "center",
                     lineHeight: "1em",
                     width: "100%",
                     px: 8,
                  }}
               >
                  {deck.Name}
               </Typography>

               {deck.hasOwnProperty("IsWhite") && (
                  <ManaIcons
                     width={55}
                     mana={{
                        IsWhite: deck.IsWhite,
                        IsBlue: deck.IsBlue,
                        IsBlack: deck.IsBlack,
                        IsRed: deck.IsRed,
                        IsGreen: deck.IsGreen,
                        IsColorless: deck.IsColorless,
                     }}
                  />
               )}
            </>
         )}
      </Stack>
   );
}
