import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import _ from "lodash";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";

import StandingsSelect from "../components/xpression/StandingsSelect";
import CardSearch from "../components/xpression/CardSearch";
import CardImage from "../components/xpression/CardImage";

import Wrapper from "../components/layout/Wrapper";
import TableHistorySelect from "../components/xpression/TableHistorySelect";

//HOOKS
import useXpression from "../hooks/useXpression";
import useTableHistory from "../hooks/useTableHistory";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export default function Xpression() {
   let navigate = useNavigate();

   // USE HOOK
   const [
      xpressionGlobals,
      allStoryboards,
      allRounds,
      allPlayers,
      allCards,
      tickerData,
      featureCardGroups,
      fMPlayers,
      players,
      {
         updateXpressionGlobals,
         addXpressionCard,
         getAllStoryboards,
         deleteXpressionCard,
         setXpressionFeaturedCards,
         setXpressionTable,
         addXpressionFeaturedGroup,
         getXpressionFMPlayers,
         deleteXpressionFeaturedGroup,
         subscribeFMPlayers,
      },
   ] = useXpression();

   const [tableHistory, { getTableHistory }] = useTableHistory();

   useEffect(() => {
      // subscribeFMPlayers();
      getTableHistory();
      getXpressionFMPlayers();
   }, []);

   const refreshTableHistory = () => {
      getTableHistory();
      getXpressionFMPlayers();
   };

   useEffect(() => {
      console.log(allRounds);
   }, [allRounds]);

   useEffect(() => {
      // console.log(fMPlayers);
   }, [fMPlayers]);

   const [cardParent, setCardParent] = useState();

   const handleChangeStandings = (e) => {
      updateXpressionGlobals({ selectedStandings: e.value });
   };

   const addCard = (parentRowId, card) => {
      setCardParent();
      addXpressionCard(parentRowId, card);
   };

   const deleteCard = (parentRowId, index) => {
      deleteXpressionCard(parentRowId, index);
   };

   const setActiveFeaturedCard = (e) => {
      setXpressionFeaturedCards(e.target.id);
   };

   return (
      <Wrapper>
         <Stack direction='row' alignItems='center'>
            <Typography variant='h3' component='div' sx={{ width: "100%" }}>
               Xpression Dashboard
            </Typography>
         </Stack>

         <Stack
            direction='row'
            sx={{ color: "#fff", width: "100%" }}
            spacing={8}
         >
            <Stack sx={{ width: "30%" }}>
               <Box sx={{ width: "100%", mb: 4 }}>
                  <Typography
                     variant='h4'
                     component='div'
                     sx={{ mt: 4, mb: 2, textAlign: "center" }}
                  >
                     Ticker Data
                  </Typography>
                  {xpressionGlobals && (
                     <StandingsSelect
                        selected={
                           xpressionGlobals.selectedStandings
                              ? xpressionGlobals.selectedStandings
                              : 0
                        }
                        callback={handleChangeStandings}
                     />
                  )}
               </Box>

               <Typography
                  variant='h4'
                  component='div'
                  sx={{ textAlign: "center" }}
               >
                  Selected Table Data
               </Typography>
               <Button onClick={() => refreshTableHistory()}>
                  Refresh Table History Menus
               </Button>
               {tableHistory && (
                  <>
                     <TableHistorySelect
                        tableHistory={tableHistory}
                        table='1'
                        selected={
                           fMPlayers[0] ? fMPlayers[0].tableHistoryId : ""
                        }
                        callback={(data) => setXpressionTable(data)}
                     />
                     <TableHistorySelect
                        tableHistory={tableHistory}
                        table='2'
                        selected={
                           fMPlayers[1] ? fMPlayers[1].tableHistoryId : ""
                        }
                        callback={(data) => setXpressionTable(data)}
                     />
                     <TableHistorySelect
                        tableHistory={tableHistory}
                        table='3'
                        selected={
                           fMPlayers[2] ? fMPlayers[2].tableHistoryId : ""
                        }
                        callback={(data) => setXpressionTable(data)}
                     />
                     <TableHistorySelect
                        tableHistory={tableHistory}
                        table='4'
                        selected={
                           fMPlayers[3] ? fMPlayers[3].tableHistoryId : ""
                        }
                        callback={(data) => setXpressionTable(data)}
                     />
                  </>
               )}

               {players && (
                  <Box sx={{ width: "100%", boxSizing: "border-box", mb: 2 }}>
                     <Typography
                        variant='h5'
                        component='div'
                        sx={{ mt: 2, mb: 3 }}
                     >
                        Current Feature Matches
                     </Typography>

                     {players && (
                        <Grid container spacing={4}>
                           <Grid item xs={12}>
                              <Typography variant='h3' component='div'>
                                 FM Table A
                              </Typography>
                              {players["1"] &&
                                 players["1"].map((player, i) => (
                                    <Box key={"player1-" + i + "-" + player.id}>
                                       {player.CountryFlag &&
                                          player.CountryFlag.code && (
                                             <img
                                                src={
                                                   SERVER_URL +
                                                   "/images/flags/" +
                                                   player.CountryFlag.code +
                                                   ".png"
                                                }
                                                width='20'
                                                style={{ marginRight: 10 }}
                                             />
                                          )}
                                       {!player.CountryFlag && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/flags/NONE.png"
                                             }
                                             width='20'
                                             style={{ marginRight: 10 }}
                                          />
                                       )}
                                       {player.Name}
                                       {player.Standings &&
                                          player.Standings.Record && (
                                             <> ({player.Standings.Record})</>
                                          )}
                                    </Box>
                                 ))}
                           </Grid>
                           <Grid item xs={12}>
                              <Typography variant='h3' component='div'>
                                 FM Table B
                              </Typography>
                              {players["2"] &&
                                 players["2"].map((player, i) => (
                                    <Box key={"player2-" + i + "-" + player.id}>
                                       {player.CountryFlag &&
                                          player.CountryFlag.code && (
                                             <img
                                                src={
                                                   SERVER_URL +
                                                   "/images/flags/" +
                                                   player.CountryFlag.code +
                                                   ".png"
                                                }
                                                width='20'
                                                style={{ marginRight: 10 }}
                                             />
                                          )}
                                       {!player.CountryFlag && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/flags/NONE.png"
                                             }
                                             width='20'
                                             style={{ marginRight: 10 }}
                                          />
                                       )}
                                       {player.Name}
                                       {player.Standings &&
                                          player.Standings.Record && (
                                             <> ({player.Standings.Record})</>
                                          )}
                                    </Box>
                                 ))}
                           </Grid>
                           <Grid item xs={12}>
                              <Typography variant='h3' component='div'>
                                 FM Table C
                              </Typography>
                              {players["3"] &&
                                 players["3"].map((player, i) => (
                                    <Box key={"player3-" + i + "-" + player.id}>
                                       {player.CountryFlag &&
                                          player.CountryFlag.code && (
                                             <img
                                                src={
                                                   SERVER_URL +
                                                   "/images/flags/" +
                                                   player.CountryFlag.code +
                                                   ".png"
                                                }
                                                width='20'
                                                style={{ marginRight: 10 }}
                                             />
                                          )}
                                       {!player.CountryFlag && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/flags/NONE.png"
                                             }
                                             width='20'
                                             style={{ marginRight: 10 }}
                                          />
                                       )}
                                       {player.Name}
                                       {player.Standings &&
                                          player.Standings.Record && (
                                             <> ({player.Standings.Record})</>
                                          )}
                                    </Box>
                                 ))}
                           </Grid>
                           <Grid item xs={12}>
                              <Typography variant='h3' component='div'>
                                 FM Table D
                              </Typography>
                              {players["4"] &&
                                 players["4"].map((player, i) => (
                                    <Box key={"player4-" + i + "-" + player.id}>
                                       {player.CountryFlag &&
                                          player.CountryFlag.code && (
                                             <img
                                                src={
                                                   SERVER_URL +
                                                   "/images/flags/" +
                                                   player.CountryFlag.code +
                                                   ".png"
                                                }
                                                width='20'
                                                style={{ marginRight: 10 }}
                                             />
                                          )}
                                       {!player.CountryFlag && (
                                          <img
                                             src={
                                                SERVER_URL +
                                                "/images/flags/NONE.png"
                                             }
                                             width='20'
                                             style={{ marginRight: 10 }}
                                          />
                                       )}
                                       {player.Name}
                                       {player.Standings &&
                                          player.Standings.Record && (
                                             <> ({player.Standings.Record})</>
                                          )}
                                    </Box>
                                 ))}
                           </Grid>
                        </Grid>
                     )}
                  </Box>
               )}
            </Stack>

            <Box sx={{ width: "100%" }}>
               <Typography variant='h4' component='div' sx={{ mt: 4, mb: 2 }}>
                  Feature Card Groups
                  <Button
                     variant='outlined'
                     color='warning'
                     sx={{ ml: 4 }}
                     onClick={addXpressionFeaturedGroup}
                  >
                     + Add Group
                  </Button>
               </Typography>

               {featureCardGroups && xpressionGlobals && (
                  <Stack>
                     {featureCardGroups.map((row, r) => (
                        <Stack
                           key={"featuredCardGroup-" + r}
                           direction='row'
                           alignItems='center'
                           justifyContent='flex-start'
                           sx={{
                              borderTop: "1px solid #ccc",
                              backgroundColor:
                                 xpressionGlobals.selectedFeaturedCards ===
                                 row.id
                                    ? "rgba(255,0,0,.2)"
                                    : "none",
                              minHeight: 200,
                           }}
                        >
                           <Typography
                              variant='h4'
                              component='div'
                              sx={{ mx: 4 }}
                           >
                              {row.id}
                           </Typography>
                           <Button
                              variant={
                                 xpressionGlobals.selectedFeaturedCards ===
                                 row.id
                                    ? "disabled"
                                    : "outlined"
                              }
                              color='warning'
                              sx={{ mx: 2 }}
                              id={row.id}
                              onClick={setActiveFeaturedCard}
                           >
                              {xpressionGlobals.selectedFeaturedCards ===
                                 row.id && <>ACTIVE</>}
                              {xpressionGlobals.selectedFeaturedCards !=
                                 row.id && <>LOAD</>}
                           </Button>
                           {row.featuredCards.map((card, i) => (
                              <React.Fragment key={"card-" + i}>
                                 {card && (
                                    <Box
                                       sx={{
                                          color: "#fff",
                                          py: 2,
                                          px: 3,
                                       }}
                                    >
                                       <Box
                                          sx={{
                                             position: "relative",
                                             textAlign: "center",
                                          }}
                                       >
                                          <Button
                                             variant='contained'
                                             color='error'
                                             size='small'
                                             sx={{ mt: 2 }}
                                             onClick={() =>
                                                deleteCard(row.id, i)
                                             }
                                          >
                                             X
                                          </Button>
                                          <CardImage
                                             key={"card" + i + "-" + card.ID}
                                             id={card.CardSearchString}
                                             name={card.CardName}
                                             borderRadius={0}
                                             showName={false}
                                             fileName={card.FileName}
                                             width={100}
                                          />
                                       </Box>
                                    </Box>
                                 )}
                              </React.Fragment>
                           ))}
                           <Button
                              variant='contained'
                              color='success'
                              onClick={() => setCardParent(row.id)}
                           >
                              + ADD CARD
                           </Button>
                           <Button
                              variant='outlined'
                              color='error'
                              sx={{ ml: 4 }}
                              onClick={() =>
                                 deleteXpressionFeaturedGroup(row.id)
                              }
                           >
                              <DeleteIcon />
                           </Button>
                        </Stack>
                     ))}
                  </Stack>
               )}
            </Box>
         </Stack>

         <Box sx={{ my: 4 }}>
            <Stack
               direction='row'
               sx={{ width: "100%" }}
               alignItems='center'
               justifyContent='space-between'
            >
               <Typography variant='h4' component='div' sx={{ mt: 2 }}>
                  Storyboards
               </Typography>
               <Button color='warning' onClick={getAllStoryboards}>
                  REFRESH STORYBOARDS
               </Button>
            </Stack>

            <Typography variant='h6' component='div' sx={{ mb: 4 }}>
               Storyboard API URls shown here provide the Storyboard data for
               the "most recent" round. To get Storyboard data for a specific
               round just add the Round ID to the end of the Storyboard API URL.
            </Typography>

            {allRounds &&
               allStoryboards &&
               allRounds.length > 0 &&
               allStoryboards.length > 0 && (
                  <Grid
                     container
                     spacing={2}
                     direction='row'
                     justifyContent='center'
                     alignItems='center'
                     sx={{ mb: 2 }}
                  >
                     <Grid item xs={4} sm={3}>
                        <Typography
                           variant='h5'
                           component='div'
                           sx={{ color: "#999" }}
                        >
                           Example URL for {allRounds[0].Name}
                        </Typography>
                     </Grid>
                     <Grid item xs={8} sm={9}>
                        <TextField
                           sx={{
                              opacity: 0.7,
                           }}
                           value={
                              SERVER_URL +
                              "/api/xpression/storyboard/" +
                              allStoryboards[0].id +
                              "/" +
                              allRounds[0].ID
                           }
                           fullWidth
                        />
                     </Grid>
                  </Grid>
               )}

            {_.orderBy(allStoryboards, ["Title"], ["asc"]).map((storyboard) => (
               <Grid
                  container
                  spacing={2}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
               >
                  <Grid item xs={4} sm={3}>
                     <Typography variant='h5' component='div'>
                        {storyboard.Title}
                     </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                     <TextField
                        value={
                           SERVER_URL +
                           "/api/xpression/storyboard/" +
                           storyboard.id
                        }
                        fullWidth
                     />
                  </Grid>
               </Grid>
            ))}
         </Box>

         <Box sx={{ my: 4 }}>
            <Typography variant='h4' component='div' sx={{ mt: 2 }}>
               Storyboard Round ID
            </Typography>
            {allRounds.map((round) => (
               <Grid
                  container
                  spacing={2}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
               >
                  <Grid item xs={4} sm={3}>
                     <Typography variant='h5' component='div'>
                        {round.Name}
                     </Typography>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                     <TextField value={round.ID} />
                  </Grid>
               </Grid>
            ))}
         </Box>

         <Box sx={{ my: 4 }}>
            <Typography variant='h4' component='div' sx={{ mt: 2 }}>
               API Endpoints
            </Typography>

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               Storyboards
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/storyboard/:id/:roundId?"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               Featured Card Group (Loaded)
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/featuredcards"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               Featured Card Group by ID
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/featuredcardgroup/:id"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               All Players
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/allplayers"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               Ticker Data
            </Typography>
            <TextField value={SERVER_URL + "/api/xpression/ticker"} fullWidth />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               FM A Players
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/fmplayers/1"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               FM B Players
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/fmplayers/2"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               FM C Players
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/fmplayers/3"}
               fullWidth
            />

            <Typography variant='h5' component='div' sx={{ mt: 2 }}>
               FM D Players
            </Typography>
            <TextField
               value={SERVER_URL + "/api/xpression/fmplayers/4"}
               fullWidth
            />
         </Box>

         <Box
            sx={{
               width: "100%",
            }}
         >
            <Typography variant='h4' component='div' sx={{ mt: 4, mb: 2 }}>
               Ticker Data Preview
            </Typography>
            <Box
               sx={{
                  height: 200,
                  overflow: "scroll",
                  border: "1px solid #ccc",
                  color: "#fff",
               }}
            >
               <pre>{JSON.stringify(tickerData, null, 4)}</pre>
            </Box>
         </Box>

         <CardSearch
            allCards={allCards}
            open={cardParent}
            close={() => setCardParent()}
            callback={addCard}
         />
      </Wrapper>
   );
}
