import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";
import axios from "axios";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Wrapper from "../../components/layout/Wrapper";
import DataGrid from "../../components/widgets/TableHistoryDataGrid";

//HOOKS
import useTableHistory from "../../hooks/useTableHistory";

// MODAL
import Modal from "@mui/material/Modal";

const style = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: "90%",
   height: "90%",
   overflow: "scroll",
   bgcolor: "#222",
   border: "2px solid #000",
   boxShadow: 24,
   p: 4,
};

export default function TableHistoryModal({ callback }) {
   // USE TABLE HISTORY HOOK
   const [tableHistory, { getTableHistory, addTableGraphics }] =
      useTableHistory();

   const [modalOpen, setModalOpen] = useState(false);

   useEffect(() => {
      // console.log(tableHistory);
   }, [tableHistory]);

   const handleAddFromHistory = (p1id, p2id) => {
      addTableGraphics(p1id, p2id);
   };

   const handleOpen = () => {
      getTableHistory();
      setModalOpen(true);
   };

   const handleClose = () => {
      callback();
      setModalOpen(false);
   };

   return (
      <>
         <Button
            variant='contained'
            color='warning'
            onClick={handleOpen}
            sx={{ ml: 2 }}
         >
            + ADD FROM HISTORY
         </Button>

         <Modal open={modalOpen} onClose={handleClose}>
            <Stack sx={style} alignItems='flex-end'>
               <Button
                  variant='outlined'
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={handleClose}
               >
                  CLOSE
               </Button>
               <Box sx={{ width: "100%" }}>
                  <Typography
                     variant='h3'
                     component='div'
                     sx={{ textAlign: "center", textTransform: "uppercase" }}
                  >
                     Table History
                  </Typography>

                  <DataGrid
                     key={"history"}
                     height={700}
                     data={tableHistory}
                     preview={handleAddFromHistory}
                  />
                  {/* 
                  {tableHistory && tableHistory.length > 0 && (
                     <Table>
                        <TableHead sx={{ background: "#333" }}>
                           <TableCell
                              sx={{ color: "#fff", fontWeight: "bold" }}
                           >
                              Round / Table
                           </TableCell>
                           <TableCell
                              sx={{ color: "#fff", fontWeight: "bold" }}
                           >
                              Actions
                           </TableCell>
                           <TableCell
                              sx={{ color: "#fff", fontWeight: "bold" }}
                           >
                              Player 1
                           </TableCell>
                           <TableCell
                              sx={{ color: "#fff", fontWeight: "bold" }}
                           >
                              Player 2
                           </TableCell>
                           <TableCell
                              sx={{ color: "#fff", fontWeight: "bold" }}
                           >
                              Saved At
                           </TableCell>
                        </TableHead>
                        <TableBody>
                           {tableHistory.map((row, i) => (
                              <TableRow sx={{ background: "#ccc" }}>
                                 <TableCell>
                                    <b>
                                       {row.title}
                                       <br />
                                       {row.table}
                                    </b>
                                 </TableCell>
                                 <TableCell>
                                    <Button
                                       variant='contained'
                                       color='error'
                                       size='small'
                                       onClick={() =>
                                          handleAddFromHistory(
                                             row.player1.id,
                                             row.player2.id
                                          )
                                       }
                                    >
                                       Create H2H /<br />
                                       Player Profiles
                                    </Button>
                                 </TableCell>
                                 <TableCell>
                                    {row.player1
                                       ? row.player1.Name
                                       : "No Player"}
                                    <br />
                                    <i>
                                       {row.player1 && row.player1.decklist
                                          ? row.player1.decklist.Archetype +
                                            " | " +
                                            row.player1.decklist.Format
                                          : "No Decklist"}
                                    </i>
                                 </TableCell>
                                 <TableCell>
                                    {row.player2
                                       ? row.player2.Name
                                       : "No Player"}
                                    <br />
                                    <i>
                                       {row.player2 && row.player2.decklist
                                          ? row.player2.decklist.Archetype +
                                            " | " +
                                            row.player2.decklist.Format
                                          : "No Decklist"}
                                    </i>
                                 </TableCell>
                                 <TableCell>{row.createdAt}</TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  )} */}
               </Box>
            </Stack>
         </Modal>
      </>
   );
}
